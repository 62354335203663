import {ChartConfig} from '@/Chart';
import {EntityBreakdowns} from '~/types/types';

/**
 * Build for each entity a breakdown.
 *
 * The output needed:
 * - `config`: A configuration where we have {id}: { label: <categoryName>, color: <categoryLabel> }
 *
 * How it works:
 * - For each entity-breakdown, we extract all available categories (from the entity's Breakdowns->name) and their relevant colors (from the entity's Breakdowns->color).
 *
 * - Used to format in stacked bar charts.
 * @param breakdowns
 */
export const buildEntityBreakdownConfig = (
  breakdowns: EntityBreakdowns
): ChartConfig => {
  const config: ChartConfig = {};

  const categories = extractCategories(breakdowns);

  // Add categories to the config
  categories.forEach((category) => {
    config[category.identifier] = {
      label: category.label,
      color: category.color ?? '#334155',
    };
  });

  return config;
};

/**
 * Build the data for each entity breakdown.
 *
 * The output needed:
 * - `data`: A flat list, where each key-value (that is not the `xLabel`) is part of the breakdown.
 * @param breakdowns
 * @param xLabel
 */
export const buildEntityBreakdownData = (
  breakdowns: EntityBreakdowns,
  xLabel = 'identifier'
) => {
  const data: any = [];

  breakdowns.items.forEach((item) => {
    const entity = item.entity;
    const breakdownPerEntity = item.breakdown.items ?? [];

    if (entity && breakdownPerEntity.length > 0) {
      // Now break the breakdown-per entity into a "record" for each entity.
      const values = breakdownPerEntity.reduce((acc, breakdownItem) => {
        acc[breakdownItem.identifier] = breakdownItem.value;
        return acc;
      }, {} as any);

      data.push({
        ...values,
        [xLabel]: entity.label ?? entity.title ?? entity.name ?? entity.id,
        entity: entity,
      });
    }
  });

  return data;
};

/**
 * Extract all categories from the entity breakdowns, and return them as a list with unique items
 *
 * How it works:
 * - Each entityBreakdown has a `breakdown.items`, where each item is a category with an identifier, label, color, value.
 * - We extract all the (names, color) from each breakdown item, and return them as a list.
 * - We make sure that the list are unique by name.
 * - We also add the amount of issues per organization to the ariaLabel
 * @param entityBreakdowns
 */
export const extractCategories = (
  entityBreakdowns: EntityBreakdowns
): {
  label: string;
  identifier: string;
  color: string;
  ariaLabel: string;
}[] => {
  const categories =
    entityBreakdowns?.items?.reduce<
      {
        identifier: string;
        label: string;
        color: string;
        value: number;
        entityId: string;
      }[]
    >((acc, item) => {
      const breakdown = item.breakdown.items ?? [];
      const itemCategories = breakdown.map((breakdownItem) => ({
        identifier: breakdownItem.identifier,
        label: breakdownItem.label,
        color: breakdownItem.color ?? '#334155',
        value: breakdownItem.value,
        entityId: item.entity?.id,
      }));
      return [...acc, ...itemCategories];
    }, []) ?? [];

  const mappedCategories = categories.map(
    (cat): [string, typeof cat] => [cat.identifier, cat]);

  const amountOfIssuesPerOrganizationPerType: Record<string, [number, string][]> = {};

  // Process and store data in the result object
  mappedCategories.forEach(([identifier, details]) => {
    const entry: [number, string] = [details.value, details.entityId];

    if (!amountOfIssuesPerOrganizationPerType[identifier]) {
      amountOfIssuesPerOrganizationPerType[identifier] = [];
    }

    amountOfIssuesPerOrganizationPerType[identifier].push(entry);
  });

  // Use a Map to ensure uniqueness by name while keeping the last occurrence of each category
  const uniqueCategories = new Map(
    categories.map((cat) => [cat.identifier, cat])
  );

  const values = Array.from(uniqueCategories.values());

  // Add the amount of issues per organization to the ariaLabel
  return values.map((cat) => {
    const issuesPerOrganization = amountOfIssuesPerOrganizationPerType[cat.identifier];
    const ariaLabel = issuesPerOrganization.map(([value, entityId]) => `${value} ${cat.identifier} issues for ${entityId}`).join(', ');
    return {
      label: cat.label,
      identifier: cat.identifier,
      color: cat.color,
      ariaLabel: ariaLabel,
    };
  });
};
