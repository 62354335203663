import { motion } from 'framer-motion';
import { ChevronDown } from 'lucide-react';
import * as React from 'react';
import { HTMLAttributes, ReactNode, TdHTMLAttributes, ThHTMLAttributes, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { cn } from '~/utils/cn';

export const Table = React.forwardRef<
  HTMLTableElement,
  HTMLAttributes<HTMLTableElement>
>(({ className, ...props }, ref) => (
  <div className="relative w-full overflow-auto">
    <table
      ref={ref}
      className={cn('w-full caption-bottom text-sm', className)}
      {...props}
    />
  </div>
));
Table.displayName = 'Table';

export const TableHeader = React.forwardRef<
  HTMLTableSectionElement,
  HTMLAttributes<HTMLTableSectionElement>
>(({ className, ...props }, ref) => (
  <thead ref={ref} className={cn('[&_tr]:border-b', className)} {...props} />
));
TableHeader.displayName = 'TableHeader';

export const TableBody = React.forwardRef<
  HTMLTableSectionElement,
  HTMLAttributes<HTMLTableSectionElement>
>(({ className, ...props }, ref) => (
  <tbody
    ref={ref}
    className={cn('[&_tr:last-child]:border-0', className)}
    {...props}
  />
));
TableBody.displayName = 'TableBody';

export const TableFooter = React.forwardRef<
  HTMLTableSectionElement,
  HTMLAttributes<HTMLTableSectionElement>
>(({ className, ...props }, ref) => (
  <tfoot
    ref={ref}
    className={cn(
      'border-t bg-muted/50 font-medium [&>tr]:last:border-b-0',
      className
    )}
    {...props}
  />
));
TableFooter.displayName = 'TableFooter';

export const TableRow = React.forwardRef<
  HTMLTableRowElement,
  HTMLAttributes<HTMLTableRowElement>
>(({ className, ...props }, ref) => (
  <tr
    ref={ref}
    className={cn(
      'border-b transition-colors hover:bg-muted/50 data-[state=selected]:bg-muted',
      className
    )}
    {...props}
  />
));
TableRow.displayName = 'TableRow';

export const TableHead = React.forwardRef<
  HTMLTableCellElement,
  ThHTMLAttributes<HTMLTableCellElement>
>(({ className, ...props }, ref) => (
  <th
    ref={ref}
    className={cn(
      'h-10 px-2 text-left align-middle font-medium text-muted-foreground [&:has([role=checkbox])]:pr-0 [&>[role=checkbox]]:translate-y-[2px]',
      className
    )}
    {...props}
  />
));
TableHead.displayName = 'TableHead';

export const SimpleTableCellContent = ({ item }: { item: SimpleTableItem }) => {
  if (item.dtype === 'render') {
    return (
      <div className="flex items-center gap-3">
        {item.icon && (
          <div className="flex-shrink-0">
            {item.icon}
          </div>
        )}
        <div className="flex-1">
          {item?.render()}
        </div>
        {item.meta && (
          <span className="flex-shrink-0 text-sm text-zinc-500">
            {item.meta}
          </span>
        )}
      </div>
    );
  }

  return (
    <div className="flex items-center gap-3">
      {item.icon && (
        <div className="flex-shrink-0">
          {item.icon}
        </div>
      )}
      <div className="flex flex-col min-w-0 flex-1">
        {item.primary && (
          <span className="text-sm font-medium text-zinc-900 truncate">
            {item.primary}
          </span>
        )}
        {item.secondary && (
          <span className="text-sm text-zinc-500 truncate">
            {item.secondary}
          </span>
        )}
      </div>
      {item.meta && (
        <span className="flex-shrink-0 text-sm text-zinc-500">
          {item.meta}
        </span>
      )}
    </div>
  );
};

/**
 * This TableCell belongs to the `SimpleTable` component
 * @param items
 * @constructor
 */
export const SimpleTableCell = ({ items }: { items: SimpleTableItem[] }) => {
  if (items.length === 0) return null;

  return (
    <td className="p-4">
      <div className="flex flex-col gap-2">
        {items.map((item, index) => (
          <SimpleTableCellContent key={index} item={item}/>
        ))}
      </div>
    </td>
  );
};

export const TableCell = React.forwardRef<
  HTMLTableCellElement,
  TdHTMLAttributes<HTMLTableCellElement>
>(({ className, ...props }, ref) => (
  <td
    ref={ref}
    className={cn(
      'p-2 align-middle [&:has([role=checkbox])]:pr-0 [&>[role=checkbox]]:translate-y-[2px]',
      className
    )}
    {...props}
  />
));
TableCell.displayName = 'TableCell';

export const TableCaption = React.forwardRef<
  HTMLTableCaptionElement,
  HTMLAttributes<HTMLTableCaptionElement>
>(({ className, ...props }, ref) => (
  <caption
    ref={ref}
    className={cn('mt-4 text-sm text-muted-foreground', className)}
    {...props}
  />
));
TableCaption.displayName = 'TableCaption';

interface SimpleTableItem {
  dtype: 'single' | 'multi' | 'render';
  primary?: string;
  secondary?: string;
  render?: any;
  icon?: any;
  meta?: string;
}

export interface ColumnAccessor<T> {
  header: string;
  // Accessor can be either a string key of T or a function that returns TableItem[]
  accessor: keyof T | ((row: T) => SimpleTableItem[]);
  icon?: any;
  meta?: (row: T) => string;
}

interface SimpleTableProps<T> {
  columns: ColumnAccessor<T>[];
  data: T[];
  className?: string;
  paginateEvery?: number;
}

export const SimpleTable = <T extends object>({
  columns,
  data,
  className,
  paginateEvery,
}: SimpleTableProps<T>) => {
  const [visibleCount, setVisibleCount] = useState(paginateEvery || data.length);
  const { t } = useTranslation();
  const visibleData = data.slice(0, visibleCount);
  const hasMore = visibleCount < data.length;

  const getCellItems = (row: T, accessor: ColumnAccessor<T>['accessor']): TableItem[] => {
    if (typeof accessor === 'function') {
      return accessor(row);
    }

    // If accessor is a key, create a single item with that value
    const value = row[accessor];
    if (value === undefined || value === null) return [];

    return [{
      dtype: 'single',
      primary: String(value),
    }];
  };

  const handleLoadMore = () => {
    setVisibleCount(prev => Math.min(prev + (paginateEvery || data.length), data.length));
  };

  return (
    <div className={cn('relative w-full overflow-auto rounded border shadow-sm', className)}>
      <div className="overflow-auto">
        <table className="w-full">
          <thead className="bg-zinc-50">
            <tr className="border-b border-zinc-200">
              {columns.map((column, index) => (
                <th
                  key={index}
                  className="px-4 py-3 text-left text-xs font-medium text-zinc-500 uppercase tracking-wider"
                >
                  {column.header}
                </th>
              ))}
            </tr>
          </thead>
          <TableBody>
            {visibleData.map((row, rowIndex) => (
              <TableRow
                key={rowIndex}
                className="border-b border-zinc-200 hover:bg-zinc-50"
              >
                {columns.map((column, columnIndex) => (
                  <SimpleTableCell
                    key={columnIndex}
                    items={getCellItems(row, column.accessor)}
                  />
                ))}
              </TableRow>
            ))}
          </TableBody>
        </table>
      </div>

      {paginateEvery && hasMore && (
        <motion.div
          initial={false}
          animate={{ opacity: 1 }}
          className="border-t border-zinc-200"
        >
          <button
            onClick={handleLoadMore}
            className="flex w-full items-center justify-center gap-2 px-4 py-3 text-sm text-zinc-500 hover:bg-zinc-50 transition-colors"
          >
            <span>{t('load_more')}</span>
            <ChevronDown className="h-4 w-4"/>
          </button>
        </motion.div>
      )
      }
    </div>
  );
};
