import { useTranslation } from 'react-i18next';

import { Card, CardBody, CardHeader, CardPanel, CardPanels, CardRows, CardSidePanel } from '@/Card';
import { PlaceholderBox } from '@/Fallback';
import { DebugJSON } from '@/JSON';
import { Label } from '@/Label';
import { ColumnAccessor, SimpleTable } from '@/Table/SimpleTable/SimpleTable';
import { CardDescription, CardTitle, DescriptionText, MutedStrong, MutedText, Strong } from '@/Text';
import { OrderPreviewCard } from '~/Components/Orders/Representations/OrderPreviewCard';
import { UserNotificationBadge } from '~/Components/Users/UserNotificationBadge';
import { DateFormat, useDate } from '~/hooks/useDate';
import { NotificationManifest, User } from '~/types/types';

interface NotificationManifestCardProps {
  manifest: NotificationManifest;
}

export const NotificationManifestCard = ({ manifest }: NotificationManifestCardProps) => {
  const { t } = useTranslation();
  const { parseOrFormat } = useDate();

  const recipients = manifest.recipients;
  const payload = manifest.payload;

  const recipientsTableColumns: ColumnAccessor<User>[] = [
    {
      header: 'User',
      accessor: (row: User) => [
        {
          dtype: 'multi',
          primary: row.name,
          secondary: row.email,
        },
      ],
    },
    {
      header: 'notifications',
      accessor: (row: User) => [
        {
          dtype: 'render',
          render: () => (<div className="inline-block"><UserNotificationBadge user={row}/></div>),
        },
      ],
    },
  ];

  return (
    <Card>
      <CardHeader>
        <CardTitle>
          {t(manifest.type)}
        </CardTitle>
        <CardDescription>
          {t(`${manifest.type}_description`)}
        </CardDescription>

        {manifest.willSend && manifest.nextDelivery ? (
          <Label theme="emerald">
            Next send: {parseOrFormat(manifest.nextDelivery, DateFormat.HumanWeekDayTime)}
          </Label>
        ) : (
          <Label>
            Will not send
          </Label>
        )}
      </CardHeader>
      <CardRows>
        <CardPanels columns="1_3">
          <CardSidePanel flat="true">
            <MutedStrong className="block">
              Recipients
            </MutedStrong>
            <MutedText>
              Who receives this notification?
            </MutedText>
          </CardSidePanel>
          <CardPanel>
            {manifest.recipients && manifest.recipients.length ? (
              <SimpleTable paginateEvery={10} columns={recipientsTableColumns} data={recipients}/>
            ) : (
              <PlaceholderBox title="No recipients enabled"
                description="No recipients have been enabled for this organization"/>
            )}
            <div className="mt-4">
              <DescriptionText>
                All users who have enabled "email notifications" and "issue notifications" will receive this
                notification.
              </DescriptionText>
            </div>
          </CardPanel>
        </CardPanels>
        <CardPanels columns="1_3">
          <CardSidePanel flat="true">
            <MutedStrong className="block">
              Contains
            </MutedStrong>
            <MutedText>
              What is included in this notification?
            </MutedText>
          </CardSidePanel>
          <CardPanel>
            {manifest.willSend ? (
              <>
                {manifest.type === 'risk_assessment' && (
                  <div>
                    <div className="space-y-4 bg-gray-50 px-4 py-4 rounded border">
                      {manifest.payload && (
                        <div>
                          <div className="mb-4 pb-4 -mx-4 px-6">
                            <div>
                              <MutedStrong>
                                Notification preview
                              </MutedStrong>
                            </div>

                            <div>
                              <MutedText>
                                {manifest.payload.simpleOrderData.length} orders were assessed as high risk.
                              </MutedText>
                            </div>
                          </div>

                          {manifest.payload.simpleOrderData.map((order) => (
                            <OrderPreviewCard
                              key={order.id}
                              simpleOrder={order}
                              details={{
                                ragPrediction: { raw_payload: { ragScore: order.score } },
                                includeSellerName: true,
                                includeExpectedArrival: true,
                              }}
                            />
                          ))}
                        </div>
                      )}
                    </div>

                    <DescriptionText className="mt-4">
                      Risk assessment notifications are sent when an order is assessed as high risk.
                    </DescriptionText>
                  </div>
                )}
              </>
            ) : (
              <PlaceholderBox title="Nothing to send" description="No notification payload"/>
            )}
          </CardPanel>
        </CardPanels>
      </CardRows>
    </Card>
  );
};
