import { FormatRatioEntry } from '~/Components/Quality/QualityEntries/Ratio/FormatRatioEntry';
import { useFormat } from '~/hooks/useFormat';
import { FieldType } from '~/types/types';

interface QualityEntryUnitProps {
  qualityEntry: {
    data_type: string;
    number: number | null;
    number_a: number | null;
    number_b: number | null;
    individual_counts: number | null;
    boolean: boolean | null;
  };
}

export const formatEntry = (value: number | null, unit: string | null, type: FieldType | null = null) => {
  const baseValue = value !== null ? value : 0;

  if (unit) {
    return `${baseValue} ${unit}`;
  }

  if (type === 'percentage') {
    return `${baseValue * 100}%`;
  }

  return baseValue.toString();
};

/**
 * The QualityEntryUnit renders a single textual output of the quality entry.
 *
 * - If measurement, we render the number and the unit of the field.
 * - If percentage, we render either the individual counts or the percentage.
 * - If boolean, we render either yes or no.
 *
 * @precondition This is used as a pure textual component.
 * @param qualityEntry
 * @constructor
 */
export const QualityEntryUnit = ({ qualityEntry }: QualityEntryUnitProps) => {
  const { formatFractionPercentage } = useFormat();
  // Render just the count
  if (qualityEntry.data_type === 'percentage' && qualityEntry.individual_counts !== null) {
    return <>{qualityEntry.individual_counts} units</>;
  }

  // Render the percentage if count not available
  if (qualityEntry.data_type === 'percentage' && !qualityEntry.individual_counts && qualityEntry.number !== null) {
    return <>{formatFractionPercentage(qualityEntry.number)}</>;
  }

  // Render the boolean value
  if (qualityEntry.data_type === 'boolean') {
    return <>{qualityEntry.boolean ? 'Yes' : 'No'}</>;
  }

  // Render the ratio
  if (qualityEntry.data_type === 'ratio' && qualityEntry.number_a !== null && qualityEntry.number_b !== null) {
    return <FormatRatioEntry numberA={qualityEntry.number_a} numberB={qualityEntry.number_b}/>;
  }

  // Render the number and unit
  return <>{qualityEntry.number}</>;
};
