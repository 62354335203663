import { autoUpdate, offset, useFloating } from '@floating-ui/react';
import { ThermometerIcon } from 'lucide-react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Card, CardBody, CardGutter, CardHeaderContainer } from '@/Card';
import { Tabby, Tabs } from '@/Tabbar';
import { CardSubtitle, CardTitle, Helper, MutedText } from '@/Text';
import { OrderDataStream, OrderDataStreamType } from '~/types/types';

import { useOrder } from '../OrderProvider';
import { TransportChart } from './TransportChart';

interface LogisticsTemperatureSummaryCardProps {
  dataStreams: OrderDataStream<any>[];
}

export const LogisticsTemperatureSummarySection = ({
  dataStreams,
}: LogisticsTemperatureSummaryCardProps) => {
  const tempDataStreams = dataStreams.filter(
    (dataStream) =>
      dataStream.type === OrderDataStreamType.TRANSPORT_TEMPERATURE
  );

  return (
    <div className="space-y-4">
      <LogisticsTemperatureSummaryCard dataStreams={tempDataStreams}/>
    </div>
  );
};

export const LogisticsTemperatureSummaryCard = ({
  dataStreams,
}: LogisticsTemperatureSummaryCardProps) => {
  const { t } = useTranslation();
  const { orderProduces } = useOrder();
  const [activeIndex, setActiveIndex] = useState(0);

  const validDataStreams = dataStreams.filter(dataStream => {
    return (
      typeof dataStream?.data === 'object' &&
      !!dataStream.data &&
      'meta' in dataStream.data
    );
  }) ?? [];

  if (validDataStreams.length === 0) {
    return null;
  }

  const dataStream = validDataStreams[activeIndex];

  const tempCategory = dataStream.data?.eventGroups?.find(
    (eventGroup: any) => eventGroup.type === 'temperature'
  );
  const lightCategory = dataStream.data?.eventGroups?.find(
    (eventGroup: any) => eventGroup.type === 'light'
  );
  const tempEvents = tempCategory?.events || [];

  const { refs } = useFloating({
    placement: 'right-start',
    middleware: [offset(10)],
    whileElementsMounted: autoUpdate,
  });

  // Concatenate all issues from all data streams
  // const issues = dataStreams.reduce((acc, dataStream) => {
  //   return acc.concat(dataStream.issues || []);
  // }, [] as any[]);

  const logisticsTempConfig = orderProduces?.find(
    (orderProduce) =>
      orderProduce?.produce?.logistics_temp_config?.low !== undefined
  )?.produce?.logistics_temp_config;

  const getDatastreamIdentifier = (dataStream: OrderDataStream<any>) => {
    if (dataStream.data?.meta?.identifier) {
      return dataStream.data.meta.identifier;
    }

    return dataStream.id;
  };

  return (
    <div id={OrderDataStreamType.TRANSPORT_TEMPERATURE} ref={refs.setReference}>
      <Card>
        <div className="px-6 py-3 border-b">
          <div className="flex items-center gap-2">
            <div>
              <ThermometerIcon className="w-4 h-4 text-gray-400"/>
            </div>
            <MutedText className="!text-sm">Logistics</MutedText>
          </div>
        </div>
        <CardHeaderContainer className="py-6 pb-4">
          <div className="flex space-x-3">
            <div>
              <CardTitle className="flex items-center mb-1 leading-none">
                Transport Temperature
              </CardTitle>
              <div>
                <CardSubtitle>
                  {t('temperature_summaries.transport_temperature_description')}
                </CardSubtitle>
              </div>
            </div>
          </div>

          {validDataStreams.length > 1 && (
            <Tabs inCardHeader>
              {validDataStreams.map((dataStream, index) => (
                <Tabby
                  key={dataStream.id}
                  as="button"
                  tab={{
                    name: getDatastreamIdentifier(dataStream),
                    href: '#',
                    current: index === activeIndex,
                    count: dataStream?.issues?.filter(
                      (issue) => issue.status !== 'read'
                    ).length,
                  }}
                  onClick={() => setActiveIndex(index)}
                />
              ))}
            </Tabs>
          )}
        </CardHeaderContainer>
        <CardGutter>
          <Helper>
            {t('temperature')}
          </Helper>
        </CardGutter>

        <CardBody>
          <div>
            <div className="space-y-2">
              <TransportChart
                dataStream={dataStream}
                lowThresholdY={logisticsTempConfig?.low}
                upperThresholdY={logisticsTempConfig?.high}
                type="temperature"
                data={tempEvents}
              />
            </div>
          </div>
        </CardBody>

        {lightCategory && (
          <>
            <CardGutter>
              <Helper>
                {t('light')}
              </Helper>
            </CardGutter>

            <CardBody>
              <TransportChart
                dataStream={dataStream}
                type="light"
                data={lightCategory?.events}
              />
            </CardBody>
          </>
        )}
      </Card>
    </div>
  );
};
