// noinspection JSUnusedGlobalSymbols

import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@/Button';
import { Container } from '@/Container';
import { PlaceholderBox } from '@/Fallback';
import { ButtonIcon, FilterIcon, PlaceholderIcon, ReportIcon } from '@/Icon';
import { LayoutBody, LayoutGutter, LayoutHeader } from '@/Layout';
import { ParamsSetter } from '@/Pickers/ParamsSetter';
import { PageDescription, PageTitle } from '@/Text';
import { DateFilterPicker } from '~/Components/Dates/DateFilterPicker';

import { DashboardLayout } from '../DashboardLayout';
import { ModulesMap } from '../Reports/ReportsPage/ModulesMap';
import { ReportsFilterPane } from '../Reports/ReportsPage/ReportsFilter';
import { ReportsPageProps } from '../Reports/ReportsPage/types';

const AnalyticsPage = ({
  filter,
  costsModule,
  issuesModule,
  reportingAccuracyModule,
  filterOptions,
}: ReportsPageProps) => {
  const { t } = useTranslation();

  const modules = [
    costsModule, issuesModule, reportingAccuracyModule,
  ].filter((val) => !!val);
  const needsGranularity = !!costsModule  || !!reportingAccuracyModule;

  return (
    <div>
      <LayoutHeader isSticky fullWidth>
        <Container>
          <div className="flex items-center justify-between">
            <div>
              <PageTitle>
                {t('analytics.title')}
              </PageTitle>
              <PageDescription>
                {t('reports.reports_description')}
              </PageDescription>
            </div>
            <div>
              <ReportsFilterPane filterOptions={filterOptions} filter={filter}>
                <Button>
                  <ButtonIcon icon={FilterIcon}/>
                  {t('filters')}
                </Button>
              </ReportsFilterPane>
            </div>
          </div>
        </Container>
        <LayoutGutter>
          {filter?.dateFilter && (
            <ParamsSetter dataKey="dateFilter" dataPrefix="filter">
              {({ data, loading, onChange }) => (
                <DateFilterPicker
                  loading={loading}
                  requiresSubmit={true}
                  value={data}
                  onChange={onChange}
                  enableGranularity={needsGranularity}
                />
              )}
            </ParamsSetter>
          )}
        </LayoutGutter>
      </LayoutHeader>
      <LayoutBody>
        {filter?.isEmpty && (
          <PlaceholderBox
            title={t('reports.no_filter')}
            description={t('reports.no_filter_description')}
            icon={<PlaceholderIcon icon={ReportIcon}/>}
          />
        )}

        {!!modules && modules.length > 0 && (
          <div className="divide-y space-y-4 divide-gray-200">
            {modules.map((module, index) => (
              <div className="py-4" key={index}>
                <ModulesMap baseModule={module}/>
              </div>
            ))}
          </div>
        )}
      </LayoutBody>
    </div>
  );
};

AnalyticsPage.layout = (page: React.ReactNode) => (
  <DashboardLayout>
    {page}
  </DashboardLayout>
);
export default AnalyticsPage;
