import { cva } from 'class-variance-authority';
import { ReactNode } from 'react';

import { cn } from '~/utils/cn';

import { MutedText, Strong, Text } from './Text';

interface GenericFieldProps {
  label: string;
  subLabel?: string;
  value?: string | number | null;
  renderValue?: ReactNode;
  subValue?: string;
  useMutedLabel?: boolean;
  uniqueLabel?: string;
  id?: string;
  testId?: string;
  columns?: 'default' | 'wide';
  centerLabel?: boolean;
}

const fieldVariants = cva(
  'flex justify-center items-start flex-col gap-0.5 sm:grid sm:gap-4',
  {
    variants: {
      columns: {
        default: 'sm:grid-cols-[1fr,3fr]',
        wide: 'sm:grid-cols-[2fr,1fr]',
      },
    },
    defaultVariants: {
      columns: 'default',
    },
  }
);

export const SideLabeledField = ({
  label,
  subLabel,
  value,
  renderValue,
  subValue,
  useMutedLabel = false,
  id,
  testId,
  columns = 'default',
  uniqueLabel = undefined,
  centerLabel = false, // Default to false
}: GenericFieldProps) => {
  if (!value && !renderValue) return <></>;

  return (
    <div
      className={fieldVariants({ columns })}
      id={id}
      data-testid={testId}
    >
      <div className={cn(
        centerLabel && 'sm:self-center', // Center the label container vertically
        'flex flex-col gap-0.5'
      )}>
        <div>
          {uniqueLabel ? (
            <label
              htmlFor={uniqueLabel}
              className={cn(
                centerLabel && 'sm:self-center' // Center the label itself
              )}
            >
              <Strong>
                {label}
              </Strong>
            </label>
          ) : (
            <>
              {useMutedLabel ? (
                <Text className={cn(
                  centerLabel && 'sm:self-center' // Center the text
                )}>
                  {label}
                </Text>
              ) : (
                <Strong className={cn(
                  centerLabel && 'sm:self-center' // Center the strong text
                )}>
                  {label}
                </Strong>
              )}
            </>
          )}
        </div>
        <div>
          {subLabel && (
            <Text className={cn(
              '!text-xs !text-gray-500',
              centerLabel && 'sm:self-center' // Center the sublabel
            )}>
              {subLabel}
            </Text>
          )}
        </div>
      </div>
      <div className={cn(
        'flex flex-col gap-0.5',
        centerLabel && value && !renderValue && 'sm:self-center' // Center the value container if it's a simple value
      )}>
        {value && (
          <>
            <Text className={cn(
              'text-sm',
              centerLabel && 'sm:self-center' // Center the value text
            )}>
              {value}
            </Text>
            <div>
              {subValue && (
                <MutedText className={cn(
                  'block',
                  centerLabel && 'sm:self-center' // Center the subvalue
                )}>
                  {subValue}
                </MutedText>
              )}
            </div>
          </>
        )}
        {renderValue && (
          <>
            {renderValue}
          </>
        )}
      </div>
    </div>
  );
};

