import React from 'react';

import { GrowerIcon } from '@/Icon';
import { Text } from '@/Text';
import { GenericIssueBreakdown, IssueCard, IssueCardBreakdown, IssueCardItem } from '~/Components/Issues/IssueCard';
import { CompoundIssue } from '~/types/types';

interface InspectionIssuesCardProps {
  compoundIssue: CompoundIssue;
}

export const WeatherIssuesCard = ({ compoundIssue }: InspectionIssuesCardProps) => {
  return (
    <IssueCard title="Weather issues">
      <IssueCardItem type="explanation" icon={GrowerIcon}>
        <div>
          <Text>
            {compoundIssue.explanation.text}
          </Text>
        </div>
      </IssueCardItem>

      <div className="rounded-b">
        <IssueCardBreakdown hide type="breakdown">
          <GenericIssueBreakdown issues={compoundIssue.issues}/>
        </IssueCardBreakdown>
      </div>
    </IssueCard>
  );
};
