import { BellIcon, NewspaperIcon } from 'lucide-react';
import { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';

import { Card, CardContent, CardFooter, CardGutter, CardHeaderContainer } from '@/Card';
import { Input } from '@/Form';
import { SideRadioLayout } from '@/Form/FormLayouts';
import { Gutter } from '@/Gutter';
import { IconBadge, IssueIcon, OrderIcon } from '@/Icon';
import { Label } from '@/Label';
import { EmptyTableContainer, TableFooter } from '@/Table';
import { ReponsiveTableContainer } from '@/Table/TableContainer';
import { Helper, Strong, Text } from '@/Text';
import { Tooltipped } from '@/Tooltip';
import { BaseAction } from '~/Actions/BaseAction';
import { CommandActionModal } from '~/Components/Common/Command/CommandActionModal';
import { CommandAttributeInput } from '~/Components/Common/Command/CommandAttributeInput';
import { CommandAttributePicker } from '~/Components/Common/Command/CommandAttributePicker';
import { UserNotificationBadge } from '~/Components/Users/UserNotificationBadge';
import {
  Organization,
  PaginatedItems,
  User,
  UserGroup,
} from '~/types/types';

interface UsersTableActions {
  inviteUser: typeof BaseAction;
  editUser: typeof BaseAction;
}

interface UsersTableProps {
  organization: Organization;
  usersConnection: PaginatedItems<User>;
  availableGroups: UserGroup[];
  actions: UsersTableActions;
  readonly?: boolean;
}

interface UserRowProps {
  organization: Organization;
  user: User;
  availableGroups: UserGroup[];
  editUserAction: typeof BaseAction;
  readonly?: boolean;
}

export const UserRow = ({
  user,
  availableGroups,
  readonly,
  editUserAction,
}: UserRowProps) => {
  const { t } = useTranslation();
  const pickerItems = availableGroups.map((group) => ({
    label: group.name,
    value: group.id,
  }));

  return (
    <div aria-label="row" className="grid grid-cols-[1fr_1fr_1fr_1fr_1fr] gap-8 items-center justify-between py-4">
      <div aria-label={`name for ${user.name}`}>
        <Strong>
          <CommandAttributeInput disabled={readonly} action={editUserAction} target={user} attribute="name"/>
        </Strong>
      </div>

      <div aria-label={`email for ${user.email}`}>
        <Text>
          <CommandAttributeInput disabled={readonly} action={editUserAction} target={user} attribute="email"/>
        </Text>
      </div>

      <div>
        <CommandAttributePicker
          disabled={readonly}
          action={editUserAction}
          selected={user.user_groups?.[0]?.id ?? null}
          attribute="userGroupIds"
          options={pickerItems as any[]}
          target={user}
          title="Group"
          defaultEmptyValue={[]}
        />
      </div>

      <div className="flex items-center gap-2 flex-wrap">
        {user.active ? (
          <Tooltipped label={t('active_explanation')}>
            <Label theme="emerald" size="sm">
              {t('active')}
            </Label>
          </Tooltipped>
        ) : (
          <Tooltipped label={t('inactive_explanation')}>
            <Label theme="gray" size="sm">
              {t('inactive')}
            </Label>
          </Tooltipped>
        )}

        <div>
          <UserNotificationBadge user={user}/>
        </div>
      </div>

      <div className="flex justify-end">
        <CommandActionModal
          confirmation={{
            // If the user will be disabled, show a confirmation modal
            onConfirm: (newValues) => !newValues.active,
            title: t('disable_user_confirmation'),
            description: t('disable_user_confirmation_description'),
            confirmText: t('disable'),
            cancelText: t('cancel'),
          }}
          defaultValues={user}
          action={editUserAction}
          target={user}
          attributes={[
            {
              fieldKey: 'name',
              label: t('name'),
              group: 'default',
              component: <Input placeholder={t('enter_name')}/>,
              valueAccessor: (e: ChangeEvent<HTMLInputElement>) => e.target.value,
            },
            {
              fieldKey: 'email',
              label: t('email'),
              group: 'default',
              component: <Input placeholder={t('enter_email')}/>,
              valueAccessor: (e: ChangeEvent<HTMLInputElement>) => e.target.value,
            },
            {
              fieldKey: 'active',
              group: 'default',
              component: <SideRadioLayout
                title={t('active_user')}
                description={t('active_user_description')}
              />,
            },
            {
              fieldKey: 'active_mail_recipient',
              group: 'notifications_main',
              component: <SideRadioLayout
                title={t('enable_email_notifications')}
                description={t('enable_email_notifications_description')}
              />,
            },
            {
              fieldKey: 'active_hourly_pos_recipient',
              group: 'notifications',
              disabled: (values) => {
                return !values.active || !values.active_mail_recipient;
              },
              component: <SideRadioLayout
                title={t('enable_hourly_pos_notifications')}
                description={t('enable_hourly_pos_notifications_description')}
              />,
            },
            {
              fieldKey: 'active_daily_digest_mail_recipient',
              group: 'notifications',
              disabled: (values) => {
                return !values.active || !values.active_mail_recipient;
              },
              component: <SideRadioLayout
                title={t('enable_daily_digest_title')}
                description={t('enable_daily_digest_description')}
                labels={['Yes', 'No']}
              />,
            },
            {
              fieldKey: 'active_issues_mail_recipient',
              group: 'notifications',
              disabled: (values) => {
                return !values.active || !values.active_mail_recipient;
              },
              component: <SideRadioLayout
                title={t('enable_issues_notifications_title')}
                description={t('enable_issues_notifications_description')}
                labels={['Yes', 'No']}
              />,
            },
          ]}
        />
      </div>
    </div>
  );
};

export const UsersTable = ({
  organization,
  usersConnection,
  availableGroups,
  readonly,
  actions,
}: UsersTableProps) => {
  const { t } = useTranslation();

  return (
    <Card aria-label="table of users">
      <ReponsiveTableContainer>
        <CardGutter className="w-full rounded-t">
          <CardHeaderContainer className="!px-0 w-full">
            <div className="grid grid-cols-[1fr_1fr_1fr_1fr_1fr] w-full gap-8">
              <div>
                <Helper>Name</Helper>
              </div>
              <div>
                <Helper>Email</Helper>
              </div>
              <div>
                <Helper>Group</Helper>
              </div>
              <div>
                <Helper>Status</Helper>
              </div>
            </div>
          </CardHeaderContainer>
        </CardGutter>

        <div>
          <div className="divide-y divide-gray-200">
            <CardContent>
              {usersConnection.data.length > 0 ? usersConnection.data.map((user) => (
                <UserRow
                  editUserAction={actions.editUser}
                  readonly={readonly}
                  organization={organization}
                  user={user}
                  key={user.id}
                  availableGroups={availableGroups}
                />
              )) : (
                <EmptyTableContainer placeholder={t('no_users_found')}/>
              )}
            </CardContent>
          </div>
        </div>
      </ReponsiveTableContainer>
      <CardFooter>
        <TableFooter paginatedItems={usersConnection}/>
      </CardFooter>
    </Card>
  );
};
