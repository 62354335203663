import { LoaderIcon } from 'lucide-react';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, ButtonProps } from '@/Button';
import { SimpleIcon } from '@/Icon';
import { LoadingLabel } from '@/Label';
import { Spinner } from '@/Spinner';
import { BaseAction } from '~/Actions/BaseAction';
import { useConfirmationModal } from '~/Components/Common/Modals/useConfirmationModal';
import { useCommandAction } from '~/hooks/useCommandAction';

type LoadingStyle = 'spinner-only' | 'loading-label';

interface CommandActionButtonProps extends Omit<ButtonProps, 'onClick'> {
  action: typeof BaseAction;
  target: any;
  params?: Record<string, any>;
  onSuccess?: () => void;
  confirmation?: {
    onConfirm?: (params: Record<string, any>) => boolean;
    title: string;
    description: string;
    confirmText?: string;
    cancelText?: string;
    renderConfirmation?: (params: Record<string, any>) => ReactNode;
  };
  loadingStyle?: LoadingStyle
  children?: ReactNode;
}

export const CommandActionButton = ({
  action,
  target,
  params = {},
  onSuccess,
  confirmation,
  children,
  loadingStyle = 'spinner-only',
  ...buttonProps
}: CommandActionButtonProps) => {
  const { t } = useTranslation();
  const [commandInstance, { loading }] = useCommandAction(action, target);

  const { confirm, ConfirmationModal } = useConfirmationModal({
    title: confirmation?.title || 'Are you sure?',
    description: confirmation?.description || 'This action cannot be undone.',
    confirmText: confirmation?.confirmText || 'Confirm',
    cancelText: confirmation?.cancelText || 'Cancel',
    renderConfirmation: confirmation?.renderConfirmation,
  });

  const handleClick = async () => {
    if (confirmation?.onConfirm && confirmation.onConfirm(params) || !confirmation?.onConfirm && confirmation?.title) {
      confirm(async () => {
        await commandInstance.execute(params);
        onSuccess?.();
      }, params);
    } else {
      await commandInstance.execute(params);
      onSuccess?.();
    }
  };

  return (
    <>
      <ConfirmationModal/>
      <Button
        {...buttonProps}
        onClick={handleClick}
        disabled={commandInstance.disabled() || loading || buttonProps.disabled}
        variant={buttonProps.variant || 'white'}
        className="loading-ring relative"
      >
        <div className="flex items-center space-x-2">
          {action.icon && (
            <SimpleIcon icon={loading ? Spinner : action.icon} className="h-4 w-4"/>
          )}
          <span>
            {children || t(commandInstance.nameKey())}
          </span>
          {loading && loadingStyle === 'loading-label' && (
            <LoadingLabel isLoading={loading}>
              {t('loading')}
            </LoadingLabel>
          )}
        </div>
      </Button>
    </>
  );
};
