import { useTranslation } from 'react-i18next';

import { WithAwaitingFallback } from '@/Fallback';
import {
  QualityIcon,
} from '@/Icon';
import Section, { SectionLayout } from '~/Components/Common/Section';
import { InspectionIssuesCard } from '~/Components/Issues/Inspections/InspectionIssuesCard';
import { InspectionsCard } from '~/Components/Orders/InspectionsCard';
import { Attachment, OrderFieldSummary, Spec } from '~/types/types';

import { QualityLabel } from '../../QualityLabel';
import { AttachmentsCard } from '../Attachments/AttachmentsCard';
import { useOrder } from '../OrderProvider';

interface OrderInspectionsSectionProps {
  attachments: Attachment[];
  orderFieldSummaries: OrderFieldSummary[];
  activeSpec?: Spec;
  activeOrderFieldSummary?: OrderFieldSummary;
  isSeller?: boolean;
  activeFieldId?: string;
}

export const OrderInspectionsSection = ({
  attachments,
  orderFieldSummaries = [],
  activeSpec,
  activeFieldId,
  isSeller,
}: OrderInspectionsSectionProps) => {
  const { t } = useTranslation();
  const { orderProduces, order, inspectionIssues, inspectionIssue } = useOrder();

  if (!orderProduces.length) {
    return null;
  }

  const orderProduce = orderProduces[0];

  return (
    <div className="py-8" id="inspections">
      <Section icon={QualityIcon} nCollapsedItems={2} title={t('inspections')} showCollapsed enableToggle
        renderPreview={(
          <QualityLabel issues={inspectionIssues} label="Inspections"/>
        )}>
        <SectionLayout variant="3_1">
          <WithAwaitingFallback>
            <div className="space-y-8">
              <InspectionsCard
                order={order}
                orderProduce={orderProduce}
                orderFieldSummaries={orderFieldSummaries}
                activeSpec={activeSpec}
                activeFieldId={activeFieldId}
                isSeller={isSeller}
              />
              <AttachmentsCard attachments={attachments}/>

            </div>
          </WithAwaitingFallback>
          <div className="space-y-4">
            {inspectionIssue && (
              <div>
                <InspectionIssuesCard
                  compoundIssue={inspectionIssue}
                />
              </div>
            )}
          </div>
        </SectionLayout>
      </Section>
    </div>
  );
};
