import { ReactNode, useCallback, useMemo } from 'react';

import { useAction } from '~/hooks/useAction';
import { usePageProps } from '~/hooks/usePageProps';
import { PageProps } from '~/types/types';

// Helper type to get nested object type by path
export type PathImpl<T, K extends keyof T> = K extends string
  ? T[K] extends Record<string, any>
    ? T[K] extends ArrayLike<any>
      ? K | `${K}.${PathImpl<T[K], Exclude<keyof T[K], keyof any[]>>}`
      : K | `${K}.${PathImpl<T[K], keyof T[K]>}`
    : K
  : never;

export type Path<T> = PathImpl<T, keyof T> | keyof T;

// Get the type of a nested property by its path
export type PathValue<T, P extends Path<T>> = P extends `${infer K}.${infer Rest}`
  ? K extends keyof T
    ? Rest extends Path<T[K]>
      ? PathValue<T[K], Rest>
      : never
    : never
  : P extends keyof T
    ? T[P]
    : never;

// Helper function to safely access nested properties
const getNestedValue = (obj: any, path: string): any => {
  return path.split('.').reduce((acc, part) => acc && acc[part], obj);
};

interface LazyFetcherProps<TPageProps, TInputProps = void> {
  resultKey: Path<TPageProps>;
  reloadKey: keyof TPageProps;
  data?: Partial<TInputProps>;
  enableCache?: boolean;
  children: (props: {
    result: PathValue<TPageProps, Path<TPageProps>>;
    loading: boolean;
    isActive: boolean;
    onFetch: (handlerData?: Partial<TInputProps>) => Promise<void>;
    cached: boolean;
  }) => ReactNode;
}

/**
 * LazyFetcher
 *
 * A component that handles lazy loading of data based on URL parameters. It manages the relationship
 * between URL parameters and component-specific data, ensuring that data is only shown when the
 * component's data matches the URL parameters.
 *
 * The component:
 * 1. Checks if its `data` matches current URL parameters (isActive)
 * 2. Only shows results when active
 * 3. Provides loading states and fetch capabilities
 *
 * @example
 * // Will only show results when URL contains ?activeFieldId=123
 * <LazyFetcher<PageProps, InputProps>
 *   resultKey="fieldSummary"
 *   reloadKey="fieldSummary"
 *   data={{ activeFieldId: '123' }}
 * >
 *   {({ result, loading, onFetch, isActive }) => (
 *     // Render content
 *   )}
 * </LazyFetcher>
 *
 * @param resultKey - Path to the result in pageProps
 * @param reloadKey - Key to reload when fetching
 * @param children
 * @param data - Data object that should match URL parameters
 * @returns Render props with result, loading state, fetch function, and active state
 */
export function LazyFetcher<TPageProps extends PageProps, TInputProps = void>({
  resultKey,
  reloadKey,
  children,
  data = {},
}: LazyFetcherProps<TPageProps, TInputProps>) {
  const pageProps = usePageProps<TPageProps>();

  const { resetCurrentPage, loading } = useAction<TInputProps, TPageProps>('', {
    withParams: true,
    preserveState: true,
    only: [reloadKey as string],
  });

  // Check if this instance's data matches the current URL parameters
  const isActive = useMemo(() => {
    const params = new URL(document.location.toString()).searchParams;

    return Object.entries(data).every(([key, value]) => params.get(key) === value);
  }, [pageProps, data]);

  // Get the current result from page props
  const currentResult = useMemo(() => {
    return getNestedValue(pageProps, resultKey as string);
  }, [pageProps, resultKey]);

  const handleFetch = useCallback(async (handlerData?: Partial<TInputProps>) => {
    await resetCurrentPage(handlerData);
  }, [resetCurrentPage]);

  // Only return a result if this instance is active
  const result = isActive ? currentResult : null;

  return children({
    result,
    loading: loading && isActive,
    onFetch: handleFetch,
    isActive,
    cached: false,
  });
}
