import { ChevronRightIcon, ClockIcon } from '@heroicons/react/24/outline';
import { ReactNode } from 'react';

import { Icon } from './Icon';
import { Label } from './Label';

type Status = 'success' | 'pending' | 'error' | 'info' | 'default' | 'warning';

export const StatusLabel = ({
  status,
  children,
  ariaLabel,
  chevron = false,
}: {
  status: Status;
  children: ReactNode;
  ariaLabel?: string;
  chevron?: boolean;
}) => {
  const renderContent = () => (
    <>
      {status === 'pending' && (
        <Icon className="mr-1">
          <ClockIcon className="fill-gray-500 stroke-white"/>
        </Icon>
      )}
      {children}
      {chevron && (
        <Icon className="ml-1 text-inherit">
          <ChevronRightIcon className="h-4 stroke-current text-inherit w-4"/>
        </Icon>
      )}
    </>
  );

  switch (status) {
  case 'success':
    return (
      <Label theme="emerald" aria-label={ariaLabel}>
        {renderContent()}
      </Label>
    );

  case 'warning':
    return (
      <Label theme="orange" aria-label={ariaLabel}>
        {renderContent()}
      </Label>
    );

  case 'info':
    return (
      <Label theme="blue" aria-label={ariaLabel}>
        {renderContent()}
      </Label>
    );

  case 'pending':
    return (
      <Label theme="gray" aria-label={ariaLabel}>
        {renderContent()}
      </Label>
    );

  case 'error':
    return (
      <Label theme="red" aria-label={ariaLabel}>
        {renderContent()}
      </Label>
    );

  case 'default':
  default:
    return (
      <Label theme="gray" aria-label={ariaLabel}>
        {renderContent()}
      </Label>
    );
  }
};
