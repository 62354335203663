/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@/Button';
import { Card, CardBody } from '@/Card';
import { SideLabeledField } from '@/SideLabeledField';
import { StatusDot } from '@/StatusDot';
import { Text } from '@/Text';
import { IdentifierText, SectionTitle } from '@/Text';
import { AddCSVDataModal } from '~/Components/Orders/AddCSVDataModal';
import { OrderProduceCard } from '~/Components/Orders/OrderProduceCard';
import { DateFormat, useDate } from '~/hooks/useDate';
import { usePageProps } from '~/hooks/usePageProps';
import { Integration, Order, OrderStep } from '~/types/types';
import { FormatStringList } from '~/utils/FormatStringList';

import { useOrder } from '../OrderProvider';

interface OrderDetailsSectionProps {
  order: Order;
  activeStep: OrderStep;
  integration: Integration;
  isSeller?: boolean;
}

export const OrderDetailsSection = ({
  order,
  integration,
  isSeller,
}: OrderDetailsSectionProps) => {
  const { t } = useTranslation();

  const { user } = usePageProps();
  const { orderProduces } = useOrder();

  const { format, parseOrFormat } = useDate();

  const uniqueContainerExtRefs = Array.from(new Set(order.loads?.filter(
    (load) => load.container_ext_ref)
    .map((load) => load.container_ext_ref) ?? [])) as string[];

  return (
    <div className="space-y-4 pb-8 mt-4" id="order">
      <SectionTitle>{t('order')}</SectionTitle>

      <div>
        <Card>
          <CardBody>
            <div className="space-y-4">
              <SideLabeledField
                label={t('order_po') as string}
                renderValue={
                  <IdentifierText aria-label={t('order_po')}>{order.public_ref}</IdentifierText>
                }
              />

              {order.inco_term && (
                <SideLabeledField
                  label={t('delivery_terms')}
                  value={order.inco_term}
                />
              )}
              <SideLabeledField
                label={t('supplier')}
                id={`seller::${order.seller_id}`}
                testId="sellerId"
                value={order.seller?.title}
              />
              <SideLabeledField label={t('buyer')} value={order.buyer?.title}/>

              <hr/>

              {uniqueContainerExtRefs.length > 0 && (
                <SideLabeledField
                  label={t('container_ids')}
                  renderValue={<Text className="text-sm"><FormatStringList strings={uniqueContainerExtRefs}/></Text>}/>
              )}

              {order.inco_term && (
                <SideLabeledField
                  label={t('delivery_destination')}
                  value={order.delivery_location}
                />
              )}

              {order.inco_term && (
                <SideLabeledField
                  label={t('expected_arrival')}
                  value={parseOrFormat(order.delivery_date, DateFormat.DayFormat)}
                />
              )}

              <hr/>

              <SideLabeledField
                label={t('produce')}
                renderValue={
                  <div className="flex flex-wrap gap-2">
                    {orderProduces?.map((orderProduce, index) => (
                      <div className="inline-block" key={index}>
                        <OrderProduceCard
                          renderSpec
                          key={index}
                          orderProduce={orderProduce}
                        />
                      </div>
                    ))}
                  </div>
                }
              />

              {integration && !isSeller && (
                <>
                  <hr/>

                  <SideLabeledField
                    label="Integration"
                    renderValue={
                      <>
                        <div className="flex text-sm items-left">
                          <div className="hidden mt-1 mr-2 sm:block">
                            <StatusDot
                              success={integration.status === 'active'}
                            />
                          </div>

                          <div className="space-y-1">
                            <div className="flex items-center">
                              <span className="text-slate-700">
                                {integration.type}
                              </span>
                              <div className="ml-2 sm:hidden">
                                <StatusDot
                                  success={integration.status === 'active'}
                                />
                              </div>
                            </div>

                            <span>
                              {integration.last_synced_at && (
                                <div className="text-sm font-light text-slate-400">
                                  Last updated:{' '}
                                  {format(
                                    new Date(integration.last_synced_at),
                                    DateFormat.HumanMonthDateTime
                                  )}
                                </div>
                              )}
                            </span>
                            {user.superadmin && (
                              <div className="space-x-2">
                                <AddCSVDataModal
                                  integration={integration}
                                  order={order}
                                >
                                  {/* @ts-ignore */}
                                  <Button variant="white" size="sm" disabled>
                                    Sync
                                  </Button>
                                </AddCSVDataModal>
                              </div>
                            )}
                          </div>
                        </div>
                      </>
                    }
                  />
                </>
              )}
            </div>
          </CardBody>
        </Card>
      </div>
    </div>
  );
};
