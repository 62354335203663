import { CheckIcon, StopwatchIcon } from '@radix-ui/react-icons';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';

import { Button } from '@/Button';
import {
  Card,
  CardFooter,
  PromptCard,
} from '@/Card';
import { Container } from '@/Container';
import { PlaceholderBox } from '@/Fallback';
import { ProduceIcon } from '@/Icon';
import { LayoutHeader } from '@/Layout';
import { SearchBar } from '@/Searchbar';
import { SelectOptions } from '@/SelectOptions';
import { Spinner } from '@/Spinner';
import { TableFooter } from '@/Table';
import {
  CardSubtitle,
  CardTitle,
  PageDescription,
  PageTitle,
} from '@/Text';
import { Grouper } from '~/Components/Common/Grouper';
import { useAction } from '~/hooks/useAction';
import { useDate } from '~/hooks/useDate';
import { usePageProps } from '~/hooks/usePageProps';
import { useQueryParamsFromStorage } from '~/hooks/useQueryParamsFromStorage';
import { SellerRoutes, useRoutes } from '~/hooks/useRoutes';
import { Order, Organization, PageProps } from '~/types/types';
import { cn } from '~/utils/cn';

import { detailsFromSeller, OrderCard } from '../../Dashboard/Orders/OrderCard';
import { OrdersPageProps } from '../../Dashboard/Orders/OrdersPage';
import { SellerLayout } from '../SellerLayout';

interface PaginatedOrders {
  current_page: number;
  data: Order[];
  first_page_url: string;
  from: number;
  last_page: number;
  last_page_url: string;
  next_page_url: string;
  path: string;
  per_page: number;
  prev_page_url: string;
  to: number;
  total: number;
}

export interface SellerOrdersPageProps extends PageProps {
  orders: PaginatedOrders;
  parameters: {
    search?: string;
    organizationId?: string;
    produceId?: string;
    supplier_ids?: string[];
  };
  filterOptions: {
    suppliers: Organization[];
  };
}

const schema = z
  .object({
    search: z.string().optional(),
    stepType: z.string().optional(),
    produceId: z.string().optional(),
    supplier_ids: z.array(z.string()).optional(),
    orderStatus: z.string().optional(),
    region: z.string().optional(),
  })
  .strict();

type OrdersQueryParams = z.infer<typeof schema>;

const SellerOrdersPage = ({ organization, orders }: SellerOrdersPageProps) => {
  const { t } = useTranslation();
  const { isLoading, storageParams } =
    useQueryParamsFromStorage<OrdersQueryParams>('orders');

  return (
    <>
      <LayoutHeader>
        <div className="flex items-center justify-between">
          <div>
            <PageTitle>{t('orders')}</PageTitle>
            <PageDescription>{t('view_orders')}</PageDescription>
          </div>
        </div>
      </LayoutHeader>
      <Container className="w-full py-4">
        {isLoading ? (
          <div className="flex h-[400px] items-center justify-center">
            <Spinner className="w-12 h-12 animate-spin"/>
          </div>
        ) : (
          <OrderOverview
            storageParams={storageParams}
            organizationId={organization.id}
            paginatedOrders={orders}
          />
        )}
      </Container>
    </>
  );
};

interface OrderOverviewProps {
  paginatedOrders: PaginatedOrders;
  organizationId: string;
  storageParams: OrdersQueryParams;
}

const OrderOverview = ({
  paginatedOrders,
  storageParams,
}: OrderOverviewProps) => {
  const { reload, loading } = useAction('', {
    withParams: true,
    preserveState: true,
    storeLocalStorage: false,
  });
  const routes = useRoutes<SellerRoutes>();
  const { meta, organization, parameters } = usePageProps<OrdersPageProps>();
  const columns = '2fr 2fr 2fr 1fr';

  const { t } = useTranslation();

  const { parseOrFormat } = useDate();

  const hasOrdersRequiringAttention = meta.requiresInputCount > 0;

  const onStatusFilterChange = (orderStatus?: string) => {
    reload({
      orderStatus: orderStatus,
    });
  };

  /* eslint-disable @typescript-eslint/ban-ts-comment */
  return (
    <div>
      <div>
        {hasOrdersRequiringAttention && (
          <div>
            <PromptCard
              variant={'blue'}
              renderIcon={<ProduceIcon className={'fill-blue-500'}/>}
            >
              <CardTitle>{t('qa_input_prompt')}</CardTitle>
              <CardSubtitle>
                <div>
                  {t('qa_input_required_description', {
                    count: meta.requiresInputCount,
                  })}
                </div>
                <div className="mt-4">
                  <Button
                    size="sm"
                    onClick={() => onStatusFilterChange('input_required')}
                    // @ts-ignore
                    disabled={parameters.orderStatus === 'input_required'}
                  >
                    {t('filter_orders')}
                  </Button>
                </div>
              </CardSubtitle>
            </PromptCard>
          </div>
        )}
      </div>
      <div className="mt-4">
      </div>

      <div className="flex items-center mb-4">
        <div className="flex-grow">
          <SearchBar
            value={parameters.search}
            placeholder={t('seller_orders_page_search') as string}
            loading={loading}
            onChange={(search) => reload({ search })}
          />
        </div>
      </div>
      <div className="flex justify-end mt-4 mb-4 space-x-2">
        <div
          className="flex flex-col w-full space-y-2 md:flex-row md:w-revert md:items-center md:space-y-revert md:space-x-2">
          {Object.keys(storageParams || {}).length > 0 && (
            <div className="h-full">
              <Button
                className="w-full h-full"
                onClick={() => {
                  localStorage.removeItem('orders');
                  reload({}, undefined, false);
                }}
              >
                {t('clear_filters')}
              </Button>
            </div>
          )}
        </div>

        <div>
          <SelectOptions
            titleKey="change_orders_status"
            value={(parameters.singleStatusType as any) ?? 'all'}
            onChange={onStatusFilterChange}
            options={[
              {
                value: 'all',
                title_key: 'all',
                description_key: 'all_description',
              },
              {
                value: 'has_open_issues',
                title_key: 'issues_only',
                description_key: 'issues_only_description',
              },
              {
                value: 'input_required',
                title_key: 'input_required',
                description_key: 'input_required_description',
              },
            ]}
          />
        </div>
      </div>

      {paginatedOrders.data.length > 0 && (
        <Grouper
          data={paginatedOrders.data}
          config={{
            requires_seller_input: {
              icon: StopwatchIcon,
              labelKey: 'Requires input',
              priority: 100,
            },
            processed: {
              icon: CheckIcon,
              labelKey: 'Done orders',
              priority: 90,
            },
          }}
          groupBy={(order) => {
            if (order.requires_seller_input) {
              return 'requires_seller_input';
            }

            return 'processed';
          }}
        >
          {(order) => (
            <OrderCard
              key={order.id}
              order={order}
              details={detailsFromSeller(order)}
            />
          )}
        </Grouper>
      )}

      <Card>
        {paginatedOrders.data.length === 0 && (
          <PlaceholderBox
            icon={<ProduceIcon className="w-12 h-12 fill-gray-300"/>}
            title={t('placeholders.no_orders')}
            description={t('placeholders.no_orders_buyers_description')}
            loading={loading}
          />
        )}

        {paginatedOrders.data.length > 0 && (
          <div className={cn(loading && 'opacity-60')}>
            <CardFooter>
              <TableFooter paginatedItems={paginatedOrders}/>
            </CardFooter>
          </div>
        )}
      </Card>
    </div>
  );
};

SellerOrdersPage.layout = (page: React.ReactNode) => (
  <SellerLayout>{page}</SellerLayout>
);

export default SellerOrdersPage;
