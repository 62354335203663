import { ReactNode } from 'react';

import { SimpleIcon } from '@/Icon';
import { TableGroupHeader } from '@/Table/TableGroupHeader';
import { cn } from '~/utils/cn';

interface GroupConfig {
  icon?: ReactNode;
  labelKey?: string;
  priority?: number;
}

interface GrouperProps<T> {
  data: T[];
  groupBy: keyof T | ((item: T) => string);
  config?: Record<string, GroupConfig>;
  children: (item: T) => ReactNode;
  className?: string;
  headerClassName?: string;
}

const defaultConfig: GroupConfig = {
  priority: 0,
};

export function Grouper<T>({
  data,
  groupBy,
  config = {},
  children,
  className,
  headerClassName,
}: GrouperProps<T>) {
  // Group the data based on the groupBy parameter
  const groupedData = data.reduce((acc, item) => {
    const key = typeof groupBy === 'function'
      ? groupBy(item)
      : String(item[groupBy]);

    if (!acc[key]) {
      acc[key] = [];
    }

    acc[key].push(item);
    return acc;
  }, {} as Record<string, T[]>);

  // Sort groups based on priority if provided in config
  const sortedGroups = Object.keys(groupedData).sort((a, b) => {
    const priorityA = config[a]?.priority ?? defaultConfig.priority;
    const priorityB = config[b]?.priority ?? defaultConfig.priority;
    return (priorityB ?? 0) - (priorityA ?? 0);
  });

  return (
    <div className={className}>
      {sortedGroups.map((groupKey) => {
        const groupConfig = config[groupKey] ?? defaultConfig;
        const items = groupedData[groupKey];

        if (items.length === 0) return null;

        return (
          <div key={groupKey} className="space-y-2">
            <TableGroupHeader
              className={cn(
                'my-4 !bg-gray-100 border-x !border-x-gray-200 rounded-lg',
                headerClassName
              )}
            >
              {groupConfig.icon && (
                <SimpleIcon icon={groupConfig.icon} className="w-6 h-6 mr-2"/>
              )}
              {groupConfig.labelKey ?? groupKey}
            </TableGroupHeader>

            <div className="space-y-2">
              {items.map((item) => children(item))}
            </div>
          </div>
        );
      })}
    </div>
  );
}
