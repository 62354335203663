import React from 'react';

import { StandoutBorder } from '@/Borders';
import { Order } from '~/types/types';
import { cn } from '~/utils/cn';

interface OrderImageProps {
  order: Order;
  size?: 'xs' | 'sm' | 'md' | 'lg';
}

/**
 * Represents the order image appropriately
 *
 * @preconditions - Ensure that the order has preloaded order_produces.produce
 *                - Ensure that the parent is a flex container
 *
 */
export const OrderImage = ({ order, size = 'xs' }: OrderImageProps) => {
  const produce = order.order_produces?.[0].produce ?? order.order_produce?.[0]?.produce;

  return (
    <>
      {produce?.image_url && (
        <StandoutBorder className="mr-2">
          <div className={cn(
            size === 'xs' && 'w-4 h-4',
            size === 'sm' && 'w-6 h-6',
            size === 'md' && 'w-8 h-8',
            size === 'lg' && 'w-12 h-12'
          )}>
            <img
              src={produce.image_url}
              alt={produce.name}
              className={cn('object-cover rounded-full w-full h-full',
              )}
            />
          </div>
        </StandoutBorder>
      )}
    </>
  );
};
