import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@/Button';
import { CardFooter } from '@/Card';
import { Container } from '@/Container';
import * as DropdownMenu from '@/DropdownMenu';
import { PlaceholderBox } from '@/Fallback';
import { ButtonIcon, FilterIcon, ProduceIcon, SortIcon } from '@/Icon';
import { LayoutBody, LayoutHeader } from '@/Layout';
import {
  useSidebar,
} from '@/Nav/Sidebar';
import { WithNumericIndicator } from '@/NumericIndicator';
import { Spinner } from '@/Spinner';
import { TableFooter } from '@/Table';
import { TableGroupHeader } from '@/Table/TableGroupHeader';
import TabFilter from '@/Tabs/TabFilter';
import { MutedText, PageDescription, PageTitle } from '@/Text';
import { useAction } from '~/hooks/useAction';
import { useDate } from '~/hooks/useDate';
import { usePageProps } from '~/hooks/usePageProps';
import { OrdersPageProps } from '~/types/page-props';
import { Issue, Order } from '~/types/types';

import { DashboardLayout } from '../DashboardLayout';
import { detailsFromBuyer, OrderCard } from './OrderCard';
import { FilterParameters, OrderSidebar } from './OrdersFilter';
import { OrdersRiskMap } from './OrdersRiskMap';

interface GroupedRowsProps {
  groupedOrders: Record<string, Order[]>;
  label: string;
  sortType?: string;
  isLoading?: boolean;
  activeIssuesInfo?: Issue;
}

const GroupedRows = ({
  groupedOrders,
  label,
  sortType,
  isLoading,
}: GroupedRowsProps) => {
  const { t } = useTranslation();

  if (!(label in groupedOrders)) {
    return null;
  }

  const orders = groupedOrders[label];

  return (
    <>
      <TableGroupHeader className="my-4 !bg-gray-100 border-x !border-x-gray-200 rounded-lg">
        {t(label)}{' '}
        {sortType && (
          <MutedText>({t(`sort_by_describers.${sortType}`)})</MutedText>
        )}
      </TableGroupHeader>
      <div className="space-y-2">
        {orders.map((order) => (
          <OrderCard key={order.id} order={order} isLoading={isLoading} details={detailsFromBuyer(order)}/>
        ))}
      </div>
    </>
  );
};

const OrdersPage = ({
  data,
  ...props
}: OrdersPageProps) => {
  const { t } = useTranslation();
  const { reload, loading } = useAction<FilterParameters>('', {
    withParams: true,
    preserveState: true,
  });
  const { parse } = useDate();

  const { parameters, data: { groupedOrders } } = usePageProps<OrdersPageProps>();

  const defaultFilterValues: FilterParameters = {
    search: parameters.search,
    sellerRegions: parameters.sellerRegions?.[0],
    sellerIds: parameters.sellerIds,
    produceIds: parameters.produceIds,
    destinationSites: parameters.destinationSites,
    issueStatuses: parameters.issueStatuses,
    storeFilter: parameters.storeFilter,
    arrivalDate: {
      from: parameters.fromArrivalDate ? parse(parameters.fromArrivalDate) : undefined,
      to: parameters.toArrivalDate ? parse(parameters.toArrivalDate) : undefined,
    },
    shipDates: {
      from: parameters.fromShipDate,
      to: parameters.toShipDate,
    },
  };

  const handleSortChange = (sortType: string) => {
    reload({
      sortType: sortType,
    });
  };

  const reloadFilters = (values: FilterParameters) => {
    reload({
      ...values,
      arrival_from: values.arrivalDate?.from,
      arrival_to: values.arrivalDate?.to,
      ship_from: values.shipDates?.from,
      ship_to: values.shipDates?.to,
      storeFilter: values.storeFilter ? 1 : 0,
      resetPagination: 1,
    });
  };

  const tabFilterOptions = {
    items: [
      { label: 'All', number: data.order_count_per_status['all'] },
      { label: 'Open Issues', number: data.order_count_per_status['has_open_issues'], brand: 'blue' },
      { label: 'Accepted Issues', number: data.order_count_per_status['has_accepted_issues'], brand: 'orange' },
      { label: 'Escalated Issues', number: data.order_count_per_status['has_escalated_issues'], brand: 'red' },
    ],
    onChange: (index: number) => {
      reloadFilters({
        ...defaultFilterValues,
        singleStatusType: index === 0 ? 'all' :
          index === 1 ? 'has_open_issues' :
            index === 2 ? 'has_accepted_issues' :
              index === 3 ? 'has_escalated_issues' : 'all',
      });
    },
    activeIndex: parameters.singleStatusType === 'has_open_issues' ? 1 :
      parameters.singleStatusType === 'has_accepted_issues' ? 2 :
        parameters.singleStatusType === 'has_escalated_issues' ? 3 : 0,
  } as any;

  const { toggleSidebar } = useSidebar();

  return (
    <div>
      <LayoutHeader>
        <PageTitle>Orders</PageTitle>
        <PageDescription>Create & manage orders</PageDescription>

        <div className="flex flex-wrap items-center justify-between pt-6 mt-4 border-t gap-x-4 gap-y-2">
          <div className="flex items-center gap-4">
            <TabFilter
              onChange={tabFilterOptions.onChange}
              items={tabFilterOptions.items}
              initialIndex={tabFilterOptions.activeIndex}
            />
            <div>
              {loading && (
                <Spinner/>
              )}
            </div>
          </div>

          <div className="flex items-center">
            <div className="flex items-center gap-2">
              <div>
                <WithNumericIndicator label={`number active filters are ${parameters.active_filter_count}`}
                  value={parameters.active_filter_count ?? 0} brand="blue" size="xs">
                  <Button onClick={toggleSidebar} variant="gray">
                    <ButtonIcon icon={FilterIcon}/>
                    <span className="ml-2">
                      {t('filter')}
                    </span>
                  </Button>
                </WithNumericIndicator>
              </div>
              <div className="flex items-center">
                <DropdownMenu.DropdownRoot>
                  <DropdownMenu.DropdownTrigger className="h-full" asChild>
                    <Button variant="gray">
                      <ButtonIcon icon={SortIcon}/>
                      <span className="ml-2">
                        {t('sort_by')}
                      </span>
                    </Button>
                  </DropdownMenu.DropdownTrigger>
                  <DropdownMenu.DropdownContent>
                    <DropdownMenu.DropdownLabel>
                      {t('sort_by')}
                    </DropdownMenu.DropdownLabel>
                    <DropdownMenu.DropdownGroup>
                      <DropdownMenu.DropdownCheckboxItem onSelect={() => handleSortChange('last_updated')}
                        checked={parameters.sort_by === 'last_updated' || !parameters.sort_by}>
                        {t('latest_update')}
                      </DropdownMenu.DropdownCheckboxItem>
                      <DropdownMenu.DropdownCheckboxItem onSelect={() => handleSortChange('shipping_date')}
                        checked={parameters.sort_by === 'shipping_date'}>
                        {t('shipping_date')}
                      </DropdownMenu.DropdownCheckboxItem>
                      <DropdownMenu.DropdownCheckboxItem onSelect={() => handleSortChange('arrival_date')}
                        checked={parameters.sort_by === 'arrival_date'}
                      >
                        {t('arrival_date')}
                      </DropdownMenu.DropdownCheckboxItem>
                    </DropdownMenu.DropdownGroup>
                  </DropdownMenu.DropdownContent>
                </DropdownMenu.DropdownRoot>
              </div>
            </div>
          </div>
        </div>
      </LayoutHeader>

      <>
        <div>
          <div>
            <div>
              {data.order_count === 0 && (
                <Container>
                  <PlaceholderBox
                    icon={<ProduceIcon className="w-12 h-12 stroke-gray-500"/>}
                    title={t('placeholders.no_orders_available')}
                    description={t('placeholders.no_orders_buyers_description')}
                    loading={loading}
                  >
                  </PlaceholderBox>
                </Container>
              )}

              {data.order_count > 0 && (
                <Container size="xl">
                  <GroupedRows
                    isLoading={loading}
                    sortType={parameters.sortType}
                    groupedOrders={groupedOrders}
                    label="today"
                    activeIssuesInfo={props.active_orders_issue_data}
                  />
                  <GroupedRows
                    isLoading={loading}
                    sortType={parameters.sortType}
                    groupedOrders={groupedOrders}
                    label="tomorrow"
                    activeIssuesInfo={props.active_orders_issue_data}
                  />
                  <GroupedRows
                    isLoading={loading}
                    sortType={parameters.sortType}
                    groupedOrders={groupedOrders}
                    label="yesterday"
                    activeIssuesInfo={props.active_orders_issue_data}
                  />
                  <GroupedRows
                    isLoading={loading}
                    sortType={parameters.sortType}
                    groupedOrders={groupedOrders}
                    label="this_week"
                  />
                  <GroupedRows
                    isLoading={loading}
                    sortType={parameters.sortType}
                    groupedOrders={groupedOrders}
                    label="next_week"
                    activeIssuesInfo={props.active_orders_issue_data}
                  />
                  <GroupedRows
                    isLoading={loading}
                    sortType={parameters.sortType}
                    groupedOrders={groupedOrders}
                    label="this_month"
                    activeIssuesInfo={props.active_orders_issue_data}
                  />
                  <GroupedRows
                    isLoading={loading}
                    sortType={parameters.sortType}
                    groupedOrders={groupedOrders}
                    label="next_month"
                    activeIssuesInfo={props.active_orders_issue_data}
                  />
                  <GroupedRows
                    isLoading={loading}
                    sortType={parameters.sortType}
                    groupedOrders={groupedOrders}
                    label="future"
                    activeIssuesInfo={props.active_orders_issue_data}
                  />
                  <GroupedRows
                    isLoading={loading}
                    sortType={parameters.sortType}
                    groupedOrders={groupedOrders}
                    label="older"
                    activeIssuesInfo={props.active_orders_issue_data}
                  />
                  <CardFooter>
                    <TableFooter paginatedItems={data.orders}/>
                  </CardFooter>
                </Container>
              )}
            </div>
          </div>
        </div>
      </>
    </div>
  );
};

OrdersPage.layout = (page: React.ReactNode) => (
  <DashboardLayout sideBar={<OrderSidebar/>}>{page}</DashboardLayout>
);

export default OrdersPage;
