import { MousePointerClickIcon } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import {
  Bar,
  CartesianGrid,
  ComposedChart,
  Line,
  XAxis,
  YAxis,
} from 'recharts';

import {
  ChartContainer,
  ChartLegend,
  ChartLegendContent,
  ChartTooltip,
  ChartTooltipContent,
} from '@/Chart';
import { TooltipFooterIcon } from '@/Icon';
import {
  buildEntityBreakdownConfig,
  buildEntityBreakdownData,
  extractCategories,
} from '~/Components/Analytics/Charts/Breakdown/breakdown-utils';
import { EntityBreakdowns } from '~/types/types';

interface QualityIssuesBreakdownProps {
  breakdowns: EntityBreakdowns;
  /** Example: { "entityName": "Fresh Farms", "costs": 1000 } */
  costsPerEntity?: Record<string, any>;
  handleZoomInRegion?: (regionId: string) => void;
}

export const QualityIssuesBreakdown = ({
  breakdowns,
  costsPerEntity,
  handleZoomInRegion,
}: QualityIssuesBreakdownProps) => {
  const { t } = useTranslation();

  const xLabel = 'identifier';
  const config = buildEntityBreakdownConfig(breakdowns);
  let chartData = buildEntityBreakdownData(breakdowns, xLabel);
  const categories = extractCategories(breakdowns);

  // Add costs to chartData
  if (costsPerEntity) {
    chartData = chartData.map((item: any) => ({
      ...item,
      cost: costsPerEntity[item.identifier] || 0,
    }));
  }

  const maxCost = costsPerEntity
    ? Math.max(...Object.values(costsPerEntity))
    : 0;

  const handleBarClick = (data: any) => {
    console.log({ data });
    if (
      data.payload &&
      data.payload.entity &&
      data.payload.entity.type === 'country'
    ) {
      const id = data.payload.entity.id;
      handleZoomInRegion?.(id);
    }
  };

  return (
    <ChartContainer config={config}>
      <ComposedChart accessibilityLayer data={chartData}>
        <CartesianGrid vertical={false} />
        <XAxis
          dataKey="identifier"
          tickLine={false}
          tickMargin={10}
          axisLine={false}
          tickFormatter={(value) => value.slice(0, 6)}
        />
        <YAxis yAxisId="left" hide />

        <ChartTooltip
          content={
            <ChartTooltipContent
              renderFooter={
                <>
                  <TooltipFooterIcon icon={MousePointerClickIcon} />
                  {t('analytics.click_to_zoom_in')}
                </>
              }
            />
          }
        />
        <ChartLegend content={<ChartLegendContent />} />
        {categories.map((category) => {
          return (
            <Bar
              key={category.identifier}
              dataKey={category.identifier}
              onClick={handleBarClick}
              aria-label={category.ariaLabel}
              yAxisId="left"
              className="transition-all duration-200 ease-in-out hover:stroke-indigo-500 hover:stroke-[3px] cursor-pointer"
              stackId="a"
              fill={`var(--color-${category.identifier})`}
            />
          );
        })}

        {costsPerEntity && (
          <>
            <YAxis yAxisId="right" orientation="right" domain={[0, maxCost]} />
            <Line
              type="monotone"
              dataKey="cost"
              stroke="#E11D48"
              yAxisId="right"
              strokeDasharray="3 3"
              dot={false}
            />
          </>
        )}
      </ComposedChart>
    </ChartContainer>
  );
};
