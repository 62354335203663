import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@/Button';
import { RangeDatePicker } from '@/DatePicker/RangeDatePicker';
import { ArrivalSiteIcon, IssueIcon, ProduceIcon, QualityIcon, SellerIcon } from '@/Icon';
import { SidebarContent, SidebarFooter, SidebarGroup, SidebarHeader, SidebarSeparator } from '@/Nav/Sidebar';
import { GenericPicker } from '@/Pickers/GenericPicker';
import { RegionPicker } from '@/Pickers/RegionPicker';
import { SearchBar } from '@/Searchbar';
import { FilterColumn, FilterColumnRef } from '~/Components/Filters/FilterColumn';
import { useAction } from '~/hooks/useAction';
import { useDate } from '~/hooks/useDate';
import { usePageProps } from '~/hooks/usePageProps';
import { OrdersPageProps } from '~/types/page-props';
import { OrderStatusType } from '~/types/types';
import { counterToItems, formatPicker } from '~/utils/formatPicker';

export interface FilterParameters {
  search?: string;
  sellerRegions?: string;
  sellerIds?: string[] | null;
  produceIds?: string[] | null;
  singleStatusType?: OrderStatusType;
  issueStatuses?: string[];
  active_filter_count?: number;
  sortType?: string;
  sort_by?: string;
  inspectionStatuses?: string[];
  destinationSites?: string[];
  storeFilter?: number;
  resetPagination?: number;
  arrival_from?: Date | null | undefined;
  arrival_to?: Date | null | undefined;
  ship_from?: string | null | undefined;
  ship_to?: string | null | undefined;
  arrivalDate?: {
    from: Date | null | undefined;
    to: Date | null | undefined;
  };
  shipDates?: {
    from: string | null | undefined;
    to: string | null | undefined;
  };
}

const formatDateTuples = (dates: { date: string, count: number }[]) => {
  if (!dates) return [];

  return dates.map(({ date, count }) => {
    return [parse(date) as Date, count];
  });
};

export const OrderSidebar = () => {
  const [filterKey, setFilterKey] = useState(0);

  const { t } = useTranslation();
  const { reload, loading } = useAction<FilterParameters>('', {
    withParams: true,
    preserveState: true,
    onFinish: () => {
      setFilterKey(prev => prev + 1);
    },
  });
  const { parse } = useDate();

  const {
    filterOptions,
    parameters,
    data,
    orders_per_arrival_date,
    orders_per_ship_date,
  } = usePageProps<OrdersPageProps>();

  const { resetCurrentPage: refetchParameters, loading: fetchingParams } = useAction('', {
    preserveState: true,
    withParams: true,
    // only: ['orders_per_arrival_date', 'orders_per_ship_date'],
  });

  const arrivalDates = formatDateTuples(orders_per_arrival_date);
  const shipDates = formatDateTuples(orders_per_ship_date);

  const defaultFilterValues: FilterParameters = {
    search: parameters.search,
    sellerRegions: parameters.sellerRegions?.[0],
    sellerIds: parameters.sellerIds,
    produceIds: parameters.produceIds,
    destinationSites: parameters.destinationSites,
    issueStatuses: parameters.issueStatuses,
    inspectionStatuses: parameters.inspectionStatuses,
    storeFilter: parameters.storeFilter,
    arrivalDate: {
      from: parameters.fromArrivalDate ? parse(parameters.fromArrivalDate) : undefined,
      to: parameters.toArrivalDate ? parse(parameters.toArrivalDate) : undefined,
    },
    shipDates: {
      from: parameters.fromShipDate,
      to: parameters.toShipDate,
    },
  };

  const includeGroupToIssueTypes = (pickerItems: Record<string, any>[]) => {
    return pickerItems.map((item) => {
      let group = undefined;

      if (
        item.value === 'minor_major_defects' || item.value === 'out_of_measurement_spec'
      ) {
        group = 'supplier';
      }

      if (
        item.value === 'transport_delay' ||
        item.value === 'transport_temp_threshold' ||
        item.value === 'transport_light_threshold'
      ) {
        group = 'logistics';
      }

      return {
        ...item,
        title: item.title,
        group,
      };
    });
  };

  const orderStatuses = includeGroupToIssueTypes(formatPicker(counterToItems(data.order_count_per_issue_type ?? {}).map(item => {
    return {
      ...item,
      title: t(`issue_status.${item.title}`),
    };
  })));

  const filterColumnRef = useRef<FilterColumnRef>(null);

  const clearFilters = () => {
    reload({
      storeFilter: parameters.storeFilter ? 1 : 0,
      singleStatusType: parameters.singleStatusType,
    }, undefined, false);
    // setFilterKey(prev => prev + 1);

    // Reset FilterColumn state
    filterColumnRef.current?.reset();
  };

  const reloadFilters = (values: FilterParameters) => {
    console.log(values);
    reload({
      ...values,
      arrival_from: values.arrivalDate?.from,
      arrival_to: values.arrivalDate?.to,
      ship_from: values.shipDates?.from,
      ship_to: values.shipDates?.to,
      storeFilter: defaultFilterValues.storeFilter ? 1 : 0,
      resetPagination: 1,
    });
  };

  return (
    <>
      <SidebarHeader className="text-neutral-700">
        Filter orders
      </SidebarHeader>
      <SidebarContent>
        <SidebarGroup>
          <FilterColumn
            ref={filterColumnRef}
            onClear={clearFilters}
            hideGroups
            values={defaultFilterValues}
            setValues={() => {
            }}
            onChange={reloadFilters}
            filters={
              [
                {
                  label: 'General Search',
                  group: 'top_search',
                  fieldKey: 'search',
                  component: (
                    <SearchBar
                      className="w-full"
                      placeholder={t('orders_page_search') as string}
                      loading={loading}
                    />
                  ),
                },
                {
                  label: 'Inspections',
                  group: 'general',
                  fieldKey: 'inspectionStatuses',
                  component: (
                    <GenericPicker
                      icon={QualityIcon}
                      showClear
                      key={filterKey}
                      options={[
                        {
                          label: 'Seller intake',
                          value: 'has_seller_input',
                        },
                        {
                          label: 'Buyer intake',
                          value: 'has_buyer_input',
                        },
                      ]}
                      title="Inspections"
                      placeholder="Select inspection"
                      emptyMessage="No inspections found"
                      isMulti
                    />
                  ),
                },
                {
                  label: 'Issue status',
                  group: 'general',
                  fieldKey: 'issueStatuses',
                  component: (
                    <GenericPicker
                      icon={IssueIcon}
                      groups={{
                        supplier: { label: 'Supplier input', icon: ProduceIcon },
                        logistics: { label: 'Logistics', icon: ProduceIcon },
                      }}
                      key={filterKey}
                      options={orderStatuses as any}
                      title="Issue status"
                      isMulti
                      placeholder="Select issue status"
                      emptyMessage="No issue statuses found"
                    />
                  ),
                },
                {
                  label: 'Regions',
                  group: 'origin',
                  fieldKey: 'sellerRegions',
                  component: (
                    <RegionPicker
                      onChange={() => {
                        return;
                      }}
                      regions={filterOptions.regions}
                      titleKey="Region"
                    />
                  ),
                },
                {
                  label: 'Shipping date',
                  fieldKey: 'shipDates',
                  group: 'origin',
                  component: (
                    <RangeDatePicker
                      dateNumbers={shipDates as [Date, number][]}
                      dateNumbersLabel="Orders per ship date"
                      requireSubmit
                      loading={fetchingParams}
                      onOpen={refetchParameters}
                    />
                  ),
                },
                {
                  label: 'Sellers',
                  fieldKey: 'sellerIds',
                  group: 'origin',
                  component: (
                    <GenericPicker
                      icon={SellerIcon}
                      key={filterKey}
                      options={formatPicker(filterOptions.suppliers, 'value', 'label')}
                      isMulti
                      title="Seller"
                      placeholder="Select sellers"
                      emptyMessage="No sellers found"
                    />
                  ),
                },
                {
                  label: 'Produces',
                  fieldKey: 'produceIds',
                  group: 'produce',
                  component: (
                    <GenericPicker
                      icon={ProduceIcon}
                      options={formatPicker(filterOptions.produces, 'id', 'name')}
                      isMulti
                      title="Produce"
                      placeholder="Search produce..."
                      emptyMessage="No produce found"
                    />
                  ),
                },
                {
                  label: 'Arrival site',
                  description: 'Select site by where the produce is expected to arrive',
                  fieldKey: 'destinationSites',
                  group: 'arrival',
                  component: (
                    <GenericPicker
                      icon={ArrivalSiteIcon}
                      options={formatPicker(filterOptions.delivery_locations ?? [])}
                      isMulti
                      title="Arrival site"
                      placeholder="Search arrival sites..."
                      emptyMessage="No arrival sites found"
                    />
                  ),
                },
                {
                  label: 'Arrival date',
                  description: 'Select the arrival date of the produce',
                  fieldKey: 'arrivalDate',
                  group: 'arrival',
                  component: (
                    <RangeDatePicker
                      dateNumbers={arrivalDates as [Date, number][]}
                      dateNumbersLabel="Orders per arrival date"
                      loading={fetchingParams}
                      requireSubmit
                      enablePeriod
                      // onOpen={refetchParameters}
                    />
                  ),
                },
              ]
            }
          />
        </SidebarGroup>
      </SidebarContent>
      <SidebarSeparator/>
      <SidebarFooter>
        <div>
          <div>
            <label className="flex items-center space-x-2 mb-2">
              <div className="relative flex items-start">
                <div className="flex h-6 items-center">
                  <input
                    id="comments"
                    name="comments"
                    type="checkbox"
                    checked={defaultFilterValues['storeFilter'] || false}
                    onChange={() => reload({ storeFilter: defaultFilterValues['storeFilter'] ? 0 : 1 })}
                    aria-describedby="comments-description"
                    className="peer h-4 w-4 shrink-0 rounded-sm border border-primary ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 data-[state=checked]:bg-primary data-[state=checked]:text-primary-foreground"
                  />
                </div>
                <div className="ml-3 text-sm leading-6">
                  <label htmlFor="comments" className="font-medium text-gray-900">
                    {t('save_settings')}
                  </label>{' '}
                  <span id="comments-description" className="text-gray-500">
                    <span className="sr-only">{t('save_settings')}</span>{t('save_settings_description')}
                  </span>
                </div>
              </div>
            </label>
          </div>
          <Button onClick={clearFilters} variant="white">
            {t('clear_filters')}
          </Button>
        </div>
      </SidebarFooter>
    </>
  );
};
