import { ReactNode } from 'react';

import { ParseQualityEntryPrediction } from '~/Components/ML/Predictions/ParseQualityEntryPrediction';
import { PredictOrderRAG } from '~/Components/ML/Predictions/PredictOrderRAG';
import { Prediction, PredictionType, SupportedField } from '~/types/types';

interface PredictionCardProps {
  prediction: Prediction;
  supportedFields: SupportedField[];
}

/**
 * Map `prediction.type` to various components
 */
export const PredictionCard = (props: PredictionCardProps) => {
  const componentMap: Record<PredictionType, () => ReactNode> = {
    'predict_order_rag': () => (
      <PredictOrderRAG {...props} />
    ),
    'parse_quality_comment': () => (
      <ParseQualityEntryPrediction {...props} />
    ),
    'parse_text_inspection': () => (
      <div/>
    ),
  };

  const Component = componentMap[props.prediction.type];
  if (!Component) {
    return null;
  }

  return Component();
};
