import { ArrowRight } from 'lucide-react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ButtonGutter } from '@/Button';
import {
  Card,
  CardBody,
  CardFooter,
  CardPanel,
  CardPanels,
  CardSidePanel,
  ContextArea,
  PanelOverlayIcon,
  SimpleCardHeader,
} from '@/Card';
import { WithHoverCard } from '@/HoverCard';
import { HelpIcon, IssueIcon, LabelIcon, OrderIcon, SimpleCardHeaderIcon } from '@/Icon';
import { Label } from '@/Label';
import { CardSubtitle, CardTitle, Helper, HelperDescription, MutedStrong, SimpleCardTitle } from '@/Text';
import { AcceptPrediction } from '~/Actions/Predictions/AcceptPrediction';
import { IgnorePrediction } from '~/Actions/Predictions/IgnorePrediction';
import { RerunPrediction } from '~/Actions/Predictions/RerunPrediction';
import { CommandActionButton } from '~/Components/Common/Command/CommandActionButton';
import { PreviewNotificationManifest } from '~/Components/Notifications/PreviewNotificationManifest';
import { OrderDetailsList } from '~/Components/Orders/Representations/OrderDetailsList';
import { OrderPreviewCard } from '~/Components/Orders/Representations/OrderPreviewCard';
import { Order, Prediction } from '~/types/types';

interface PredictOrderRAGProps {
  prediction: Prediction;
}

export const PredictOrderRAG = ({ prediction }: PredictOrderRAGProps) => {
  const { t } = useTranslation();
  const [payload] = useState(prediction.raw_payload ?? {});
  const hasChanges = JSON.stringify(payload) !== JSON.stringify(prediction.raw_payload);

  const order = prediction.targetable as Order;
  const mlData = order?.ml_data?.data;

  console.log(order?.ml_data);

  return (
    <div>
      <CardPanels columns="1_3">
        <CardSidePanel>
          <MutedStrong>
            {t(prediction.type)}
          </MutedStrong>
        </CardSidePanel>
        <CardPanels columns="1_1">
          <CardPanel>
            <div>
              <CardTitle className="text-sm">
                Data
              </CardTitle>
              <CardSubtitle className="text-xs">
                Based on all data related to {order.public_ref}, containing the various data fields.
              </CardSubtitle>
            </div>

            <div className="mt-2">
              <Card>
                <SimpleCardHeader>
                  <SimpleCardHeaderIcon icon={OrderIcon}/>
                  <SimpleCardTitle>
                    {t('order_details')}
                  </SimpleCardTitle>
                </SimpleCardHeader>
                <CardBody size="md">
                  <OrderDetailsList order={order} details={mlData}/>
                </CardBody>
                <CardFooter>
                  <Helper>
                    {order.public_ref}
                  </Helper>
                </CardFooter>
              </Card>
            </div>
          </CardPanel>
          <CardPanel>
            <PanelOverlayIcon icon={ArrowRight}/>

            <div className="mb-4">
              <div>
                <CardTitle className="text-sm">
                  Result
                </CardTitle>
                <CardSubtitle className="text-xs">
                  Based on the incoming comment, we predict the following risk score.
                </CardSubtitle>
              </div>

              <div className="mt-2">
                <WithHoverCard flat renderContent={(
                  <div className="max-w-lg">
                    <ContextArea>
                      {prediction.explanation}
                    </ContextArea>
                  </div>
                )}>
                  <Label>
                    <LabelIcon icon={HelpIcon}/>
                    Explained
                  </Label>
                </WithHoverCard>
              </div>
            </div>

            <OrderPreviewCard enableNavCard order={order} details={{ ragPrediction: prediction }}/>

            <div>

              <div className="mt-2 border-t py-4">
                <HelperDescription className="">
                  If the prediction is accepted, a notification will be sent to all users with <Label
                    className="inline-block"
                    size="xs"><LabelIcon
                      icon={IssueIcon}/>daily
                  issues</Label> notification enabled.
                </HelperDescription>
              </div>

              {prediction.manifest && (
                <div>
                  <PreviewNotificationManifest manifest={prediction.manifest}/>
                </div>
              )}
            </div>
          </CardPanel>
        </CardPanels>
      </CardPanels>
      <CardFooter variant="darker">
        <div>
          <ButtonGutter variant="transparent">
            <div>
              <CommandActionButton
                action={AcceptPrediction}
                params={hasChanges ? payload : undefined}
                target={prediction}
                confirmation={{
                  onConfirm: () => hasChanges,
                  title: t('Accept prediction'),
                  description: t('Are you sure you want to accept this prediction?'),
                }}
              />
            </div>

            <div>
              <CommandActionButton
                action={RerunPrediction}
                target={prediction}
              />
            </div>
            <div>
              <CommandActionButton
                action={IgnorePrediction}
                target={prediction}
              />
            </div>
          </ButtonGutter>
        </div>
      </CardFooter>
    </div>
  );
};
