import { CalendarSearch, House, LayoutDashboard } from 'lucide-react';

import { AIIcon, AnalyticsIcon, OrderIcon, ScheduledNotificationIcon, SellerIcon, SettingsIcon } from '@/Icon';
import { Organization, User } from '~/types/types';

export interface NavLinkType {
  name: string;
  link: string;
  requiresFeature?: string;
  icon?: any;
  disabled?: boolean;
  external?: boolean;
  exact?: boolean;
}

export interface LinkContext {
  user: User;
  organization: Organization;
  features: Record<string, any>;
}

export const makeSellerTopLinks = (organizationId: string): NavLinkType[] => [
  { name: 'Orders', link: `/s/${organizationId}/orders`, icon: OrderIcon },
  { name: 'Settings', link: `/s/${organizationId}/settings`, icon: SettingsIcon },
];

export const makeTopLinks = (organizationId: string): NavLinkType[] => [
  {
    name: 'Dashboard',
    link: `/b/${organizationId}/dashboard`,
    icon: House,
    requiresFeature: 'access-dashboard',
  },
  { name: 'Orders', link: `/b/${organizationId}/orders`, icon: OrderIcon },
  { name: 'Analytics', link: `/b/${organizationId}/reports`, icon: AnalyticsIcon },
  { name: 'Settings', link: `/b/${organizationId}/settings`, icon: SettingsIcon },
];

export const getLinkStatus = (currentUrl: string, link: NavLinkType) => {
  if ('disabled' in link && link.disabled) {
    return 'disabled';
  }

  if (isActive(currentUrl, link)) {
    return 'active';
  }

  return 'default';
};

export const isActive = (currentUrl: string, link: NavLinkType) => {
  return link.exact ? currentUrl === link.link : currentUrl.includes(link.link);
};

export const makeSettingsSubLinks = (organizationId: string): NavLinkType[] => [
  { name: 'General', link: `/b/${organizationId}/settings/about`, icon: SettingsIcon },
  { name: 'Users', link: `/b/${organizationId}/settings/users` },
  { name: 'Produce', link: `/b/${organizationId}/settings/produces` },
];

export const buildAdminSubLinks = (context: LinkContext): NavLinkType[] => {
  const { organization } = context;

  return [
    { name: 'General', link: `/b/${organization.id}/dashboard`, exact: true },
    { name: 'Predictions', link: `/b/${organization.id}/dashboard/predictions`, icon: AIIcon },
    { name: 'Notifications', link: `/b/${organization.id}/dashboard/notifications`, icon: ScheduledNotificationIcon },
  ];
};

export const makeSellerSettingsSubLinks = (organizationId: string): NavLinkType[] => [
  { name: 'Users', link: `/s/${organizationId}/settings/users` },
];
