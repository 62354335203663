import { Link } from '@inertiajs/react';
import { cva } from 'class-variance-authority';
import { ArrowRight } from 'lucide-react';
import React, { HTMLAttributes, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { w } from 'windstitch';

import { cn } from '~/utils/cn';

import { IconBox, IconBoxIcon } from './Icon';
import { CardSubtitle, CardTitle, Helper } from './Text';

interface CardProps extends HTMLAttributes<HTMLDivElement> {
  children: ReactNode;
  hasHover?: boolean;
  className?: string;
  isLoading?: boolean;
}

export const Card = ({ children, hasHover, className, isLoading, ...props }: CardProps) => {
  return (
    <div
      className={
        cn(
          'bg-white ring-1 ring-gray-900/5 h-full lg:h-revert rounded shadow-sm transition-all',
          className,
          hasHover ? 'hover:shadow-md cursor-pointer' : '',
          isLoading ? 'animate-pulse' : ''
        )}
      {...props}
    >
      {children}
    </div>
  );
};

interface CardPanelProps extends HTMLAttributes<HTMLDivElement> {
  size?: 'sm' | 'md' | 'lg';
}

export const CardPanel = ({ children, className, size }: CardPanelProps) => {
  return (
    <Card className={cn('!h-full rounded-none !shadow-none relative', className)}>
      <CardBody size={size}>
        {children}
      </CardBody>
    </Card>
  );
};

interface NavCardProps {
  children: ReactNode;
  href: string;
  className?: string;
}

export const NavCard = ({ children, href, className }: NavCardProps) => {
  return (
    <Card className={`relative ${className}`} hasHover>
      <Link href={href} className="absolute inset-0"/>
      {children}
    </Card>
  );
};

export const CardBody = w.div('', {
  variants: {
    size: {
      xs: 'px-2.5 py-1.5',
      sm: 'px-3 py-4',
      md: 'px-3 py-4 sm:py-4 sm:px-8',
      lg: 'pya-6 px-12',
    },
    variant: {
      default: 'bg-none',
      muted: 'bg-gray-50 border-gray-100',
      dashed: 'border-dashed border-gray-100 bg-gray-50 border-style-dashed',
    },
    bordered: {
      default: 'border-none',
      y: 'border-y',
      all: 'border',
      left: 'border-l',
      right: 'border-r',
      top: 'border-t',
      bottom: 'border-b',
    },
  },
  defaultVariants: {
    bordered: 'default',
    variant: 'default',
    size: 'md',
  },
});

export const SimpleCardHeader = w.div('flex items-center gap-2 py-1 px-4 border-b rounded-t', {
  variants: {
    variant: {
      white: 'bg-white text-gray-500',
      red: 'bg-rose-600 text-rose-50',
      green: 'bg-emerald-600 text-emerald-50',
    },
  },
  defaultVariants: {
    variant: 'white',
  },
});

export const CardHeaderContainer = w.div(
  'bg-gray-white rounded-t border-b border-gray-100',
  {
    variants: {
      size: {
        sm: 'py-1 px-4',
        md: 'py-2 px-3 sm:px-8',
        lg: 'py-6 px-12',
      },
    },
    defaultVariants: {
      size: 'md',
    },
  }
);

export const CardContent = w.div(
  '',
  {
    variants: {
      size: {
        sm: 'px-4',
        md: 'px-3 sm:px-8',
        lg: 'px-12',
      },
    },
    defaultVariants: {
      size: 'md',
    },
  }
);

interface CardHeaderProps {
  children: ReactNode;
  renderIcon?: ReactNode;
  title?: string | null;
  description?: string | null;
}

export const CardGutter = w.div('border-y px-8 py-2 flex',
  {
    variants: {
      align: {
        start: '',
        end: 'flex justify-end',
      },
      variant: {
        default: 'bg-gray-100',
        darker: 'bg-gray-200 border-gray-300',
      },
    },
    defaultVariants: {
      variant: 'default',
      align: 'start',
    },
  }
);

export const CardHeader = ({
  renderIcon,
  children,
  title,
  description,
}: CardHeaderProps) => {
  return (
    <CardHeaderContainer className="py-4">
      <div className="flex gap-x-3">
        {renderIcon && (
          <div className="hidden sm:revert">
            <IconBox>{renderIcon}</IconBox>
          </div>
        )}

        <div className="flex-grow">
          {title && (
            <CardTitle className="flex items-center mb-1 leading-none">
              {title}
            </CardTitle>
          )}
          {description && (
            <div>
              <CardSubtitle>{description}</CardSubtitle>
            </div>
          )}
          {children}
        </div>
      </div>
    </CardHeaderContainer>
  );
};

// Has inline box shadow from above
export const CardFooter = w.div(
  'rounded-b border-t ',
  {
    variants: {
      size: {
        sm: 'py-1 px-4',
        md: 'py-2 px-3 sm:px-8',
        lg: 'py-6 px-12',
      },
      variant: {
        default: 'shadow-none bg-gray-50 border-gray-200 text-gray-900',
        shadowed: 'shadow-inner',
        darker: 'bg-gray-100 border-gray-200 border',
      },
    },
    defaultVariants: {
      size: 'md',
      variant: 'default',
    },
  }
);

interface PromptCardProps {
  variant: 'gray' | 'emerald' | 'red' | 'blue' | 'yellow';
  renderIcon?: ReactNode;
  children: ReactNode;
}

const promptCardBodyVariants = cva('sm:!py-8 rounded bg-gradient-to-b border', {
  variants: {
    variant: {
      gray: 'from-gray-50 to-slate-50 border-slate-100',
      emerald: 'from-emerald-50 to-slate-50 border-slate-100',
      red: 'from-red-50 to-slate-50 border-slate-100',
      blue: 'from-blue-50 to-slate-50 border-slate-100',
      yellow: 'from-yellow-50 to-slate-50 border-slate-100',
    },
  },
  defaultVariants: {
    variant: 'emerald',
  },
});

export const promptCardIconVariants = cva(
  'inline-block p-1 mt-1 border-gray-100 rounded shadow-sm ring-1 ',
  {
    variants: {
      variant: {
        gray: 'from-gray-50 to-slate-50 ring-gray-200 !border-red-100',
        emerald:
          'text-emerald-500 bg-emerald-50 ring-emerald-200 border-gray-100',
        red: 'text-red-500 bg-red-50 ring-red-200 border-gray-100',
        blue: 'text-blue-500 bg-blue-50 ring-blue-200 border-gray-100',
        yellow: 'text-yellow-500 bg-yellow-50 ring-yellow-200 border-gray-100',
      },
    },
    defaultVariants: {
      variant: 'emerald',
    },
  }
);

/**
 * A card that is used to prompt the user to take action.
 * It has a colored border and a gradient background.
 *
 * @param variant - The color of the border and background.
 * @param renderIcon - An icon to render on the left side of the card.
 * @param children - The content of the card.
 *
 * @example
 * <PromptCard variant="emerald" renderIcon={<Icon />} >
 *   <CardTitle>Card Title</CardTitle>
 *   <CardSubtitle>Card Subtitle</CardSubtitle>
 *   <p>Card Content</p>
 * </PromptCard>
 */
export const PromptCard = ({
  variant,
  renderIcon,
  children,
}: PromptCardProps) => {
  return (
    <Card className="p-3 bg-white">
      <CardBody className={promptCardBodyVariants({ variant })}>
        <div className="flex gap-4">
          <div className="hidden sm:revert">
            {!!renderIcon && (
              <div
                className={promptCardIconVariants({
                  variant,
                })}
              >
                {renderIcon}
              </div>
            )}
          </div>
          <div>{children}</div>
        </div>
      </CardBody>
    </Card>
  );
};

export const CardSection = w.div('py-1 bg-gray-50 px-4');
export const CardSectionTitle = Helper;

// TODO: Give the last child a border-radius on the bottom right
export const CardPanels = w.div('grid [&>*:last-child]:rounded-br', {
  variants: {
    columns: {
      '1_1': 'lg:grid-cols-[1fr_1fr] sm:grid-cols-1',
      '1_2': 'lg:grid-cols-[1fr_2fr] sm:grid-cols-1',
      '2_1': 'lg:grid-cols-[2fr_1fr] sm:grid-cols-2',
      '1_3': 'md:grid-cols-[1fr_2fr] lg:grid-cols-[1fr_3fr] sm:grid-cols-1',
      '1_4': 'grid-cols-1_4 sm:grid-cols-4',
      '1_2_2': 'lg:grid-cols-[1fr_2fr_2fr] sm:grid-cols-2',
    },
  },
  defaultVariants: {
    columns: '1_1',
  },
});

interface ContextAreaProps {
  children: ReactNode;
  titleKey?: string;
}

export const CardRows = w.div('grid [&>*]:border-b');

export const ContextArea = ({ children, titleKey = 'context' }: ContextAreaProps) => {
  const { t } = useTranslation();
  return (
    <div className="bg-gray-50 border-2 px-2 py-2 my-2 rounded">
      <div className="mb-1">
        <Helper className="text-xs flex !text-slate-800">
          {t(titleKey)}
        </Helper>
      </div>

      <div>
        <small>
          {children}
        </small>
      </div>
    </div>
  );
};

/**
 * This icon is used to overlay on the left side of its parent.
 *
 * @precondition - The parent must have `relative` positioning.
 *              - The component must be used in the "right" sibling of the overlay.
 * @param icon
 * @constructor
 */
export const PanelOverlayIcon = ({ icon }: { icon: any }) => {
  return (
    <div className="absolute sm:top-0 left-0 lg:top-1/2 transform -translate-y-1/2 -translate-x-1/2">
      <IconBox className="w-7 h-7 flex items-center">
        <IconBoxIcon icon={icon}/>
      </IconBox>
    </div>
  );
};

/**
 * A panel that is used to show a "muted panel" on the side of a card.
 *
 * @precondition Use it inside of a `CardPanels` component.
 */
export const CardSidePanel = w.div(
  'border border-gray-200 border-b-0 border-r bg-gray-100',
  {
    variants: {
      flat: {
        true: '!rounded-none !rounded-br-none',
        false: 'rounded-tl border-l-none',
      },
      size: {
        xs: 'px-2.5 py-1.5',
        sm: 'px-3 py-4',
        md: 'px-3 py-4 sm:py-4 sm:px-8',
        lg: 'pya-6 px-12',
      },
    },
    defaultVariants: {
      flat: false,
      size: 'md',
    },
  }
);
