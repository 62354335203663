import { type VariantProps, cva } from 'class-variance-authority';
import { AlertTriangleIcon, CheckIcon, HelpCircleIcon, XIcon } from 'lucide-react';
import { forwardRef } from 'react';

import { Tooltipped } from '@/Tooltip';
import { cn } from '~/utils/cn';

const getConfidenceData = (confidence: number | null) => {
  if (!confidence) {
    return {
      color: 'bg-gray-500',
      label: 'No confidence data',
      icon: HelpCircleIcon,
    };
  }

  if (confidence >= 0.9) return {
    color: 'bg-green-500',
    label: 'High confidence',
    icon: CheckIcon,
  };
  if (confidence >= 0.7) return {
    color: 'bg-blue-500',
    label: 'Medium confidence',
    icon: CheckIcon,
  };
  if (confidence >= 0.4) return {
    color: 'bg-orange-500',
    label: 'Low confidence',
    icon: AlertTriangleIcon,
  };

  return {
    color: 'bg-red-500',
    label: 'Very low confidence',
    icon: XIcon,
  };
};

const predictionIndicatorVariants = cva(
  'absolute rounded-full ring-2 ring-white transition-colors flex items-center justify-center', // base styles
  {
    variants: {
      size: {
        sm: 'h-3 w-3',
        md: 'h-4 w-4',
        lg: 'h-5 w-5',
        xl: 'h-6 w-6',
      },
      position: {
        'top-right': '-top-1.5 -right-1.5',
        'top-left': '-top-1.5 -left-1.5',
        'bottom-right': '-bottom-1.5 -right-1.5',
        'bottom-left': '-bottom-1.5 -left-1.5',
      },
    },
    defaultVariants: {
      size: 'md',
      position: 'top-left',
    },
  }
);

// Icon size variants mapping
const iconSizeVariants = {
  sm: 'h-1.5 w-1.5',
  md: 'h-2 w-2',
  lg: 'h-3 w-3',
  xl: 'h-4 w-4',
};

interface PredictionIndicatorProps extends VariantProps<typeof predictionIndicatorVariants> {
  className?: string;
  confidence: number | null;
  showTooltip?: boolean;
}

export const PredictionIndicator = forwardRef<HTMLDivElement, PredictionIndicatorProps>(({
  className,
  size = 'md',
  position,
  confidence,
  showTooltip = true,
  ...props
}, ref) => {
  const confidenceData = getConfidenceData(confidence);
  const IconComponent = confidenceData.icon;

  const indicator = (
    <div
      ref={ref}
      className={cn(
        predictionIndicatorVariants({ size, position }),
        confidenceData.color,
        className
      )}
      aria-label={`Confidence level: ${confidence ? `${(confidence * 100).toFixed(0)}%` : 'unknown'}`}
      {...props}
    >
      <IconComponent
        className={cn(
          'text-white stroke-[3]',
          iconSizeVariants[size]
        )}
      />
    </div>
  );

  if (!showTooltip) {
    return indicator;
  }

  return (
    <Tooltipped label={confidenceData.label}>
      {indicator}
    </Tooltipped>
  );
});

PredictionIndicator.displayName = 'PredictionIndicator';
