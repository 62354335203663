import { Link } from '@inertiajs/react';
import React from 'react';

import { Card, CardBody, CardFooter } from '@/Card';
import { ListDetail } from '@/Details';
import { DateGranularityIcon, ExternalIcon, MutedIcon, SellerIcon } from '@/Icon';
import { getStatusFromMap, StatusBadge } from '@/Labels/Status';
import { MutedText } from '@/Text';
import { OrderImage } from '~/Components/Orders/Representations/OrderImage';
import { orderPredictionStatusMap } from '~/Components/Orders/Representations/OrderPredictionStatusMap';
import { OrderTitle } from '~/Components/Orders/Representations/OrderTitle';
import { DateFormat, useDate } from '~/hooks/useDate';
import { useRoutes } from '~/hooks/useRoutes';
import { Order, Prediction, SimpleOrderData } from '~/types/types';

export const defaultOrderIncludedDetails: OrderIncludedDetails = {
  ragPrediction: true,
};

interface OrderIncludedDetails {
  ragPrediction?: Prediction;
  includeSellerName?: boolean;
  includeExpectedArrival?: boolean;
}

interface OrderPreviewCardProps {
  order?: Order;
  simpleOrder?: SimpleOrderData;
  compact?: boolean;
  enableNavCard?: boolean;
  details?: OrderIncludedDetails;
}

/**
 * The OrderPreviewCard is a card that represents a single order in a compact or expanded view.
 */
export const OrderPreviewCard = ({
  order: baseOrder,
  simpleOrder,
  enableNavCard,
  details,
}: OrderPreviewCardProps) => {
  const routes = useRoutes();
  const { parseOrFormat } = useDate();

  let order: Order;

  if (baseOrder) {
    order = baseOrder;
  } else if (simpleOrder) {
    order = {
      id: simpleOrder.id,
      public_ref: simpleOrder.extRef,
      order_produces: [{
        produce: { name: simpleOrder.produceName, image_url: simpleOrder.image },
        produce_variety: { name: simpleOrder.produceVarietyName },
      }],
      expected_arrival_at: simpleOrder.expectedArrival,
      seller: { title: simpleOrder.sellerName },
    };
  } else {
    return null;
  }

  const predictionScore = details?.ragPrediction?.raw_payload.ragScore;

  const sellerName = details?.includeSellerName ? order.seller?.title : null;
  const expectedArrival = details?.includeExpectedArrival ? order.expected_arrival_at : null;
  const includeSubDetails = sellerName || expectedArrival;

  return (
    <Card className="relative" hasHover={enableNavCard}>
      {enableNavCard && (
        <Link href={routes.order(order.id)} className="absolute inset-0"/>
      )}
      <CardBody>
        <div className="flex space-x-2 items-center">
          <div className="w-full">

            <div className="flex justify-between items-center">
              <div className="flex items-start space-x-2">
                <OrderImage order={order}/>
                <OrderTitle order={order}/>
              </div>

              {/* Slot: for RAG Prediction */}
              <div>
                {details?.ragPrediction && (
                  <StatusBadge config={getStatusFromMap(predictionScore, orderPredictionStatusMap)}/>
                )}
              </div>
            </div>
          </div>
        </div>

        {includeSubDetails && (
          <>
            <div className="py-3">
              <hr/>
            </div>

            <div className="ml-7">
              <ListDetail icon={SellerIcon} label="Seller" value={order.seller?.title}/>
              <ListDetail icon={DateGranularityIcon} label="Expected arrival"
                value={parseOrFormat(order.expected_arrival_at, DateFormat.HumanDateTime)}/>
            </div>
          </>
        )}
      </CardBody>
      <CardFooter>
        <div className="flex space-2 items-center">
          <MutedIcon className="!w-3 !h-3 mr-1" icon={ExternalIcon}/>
          <MutedText>
            {order.public_ref}
          </MutedText>
        </div>
      </CardFooter>
    </Card>
  );
};
