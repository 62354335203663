import { autoUpdate, offset, useFloating } from '@floating-ui/react';
import { TruckIcon } from '@heroicons/react/20/solid';
import { useTranslation } from 'react-i18next';

import { Button } from '@/Button';
import { Card, CardBody, CardFooter, CardHeaderContainer } from '@/Card';
import { WithHoverCard } from '@/HoverCard';
import { IconBox, IconedField } from '@/Icon';
import { Label, MultiLabel } from '@/Label';
import { NumericIndicator, WithNumericIndicator } from '@/NumericIndicator';
import { StatusDot } from '@/StatusDot';
import { CardSubtitle, CardTitle, Helper, MutedText } from '@/Text';
import { CommentsPane } from '~/Components/Panes/CommentsPane';
import { DateFormat, useDate } from '~/hooks/useDate';
import { usePageProps } from '~/hooks/usePageProps';
import {
  LocationDataPayload,
  OrderDataStream,
  OrderDataStreamType,
} from '~/types/types';
import { formatLocation } from '~/utils/formatLocation';

import { useOrder } from '../OrderProvider';
import { TransportMap } from './Embeds/TransportMap';
import { LogisticsRouteIssues } from './LogisticsRouteIssues';
import { TransportSummaryText } from './TransportSummaryText';

interface TransportSummaryCardProps {
  dataStream: OrderDataStream<LocationDataPayload>;
}

export const TransportRouteCard = ({
  dataStream,
}: TransportSummaryCardProps) => {
  const { order } = useOrder();
  const { organization } = usePageProps();
  const { t } = useTranslation();
  const { format, parse } = useDate();

  const data = dataStream.data;

  const validDataStream = typeof dataStream?.data === 'object' &&
      !!dataStream.data &&
      'meta' in dataStream.data &&
      'events' in dataStream.data;

  if (!validDataStream) return null;

  const latestEvent = data.events.length > 0 ? data.events[data.events.length - 1] : null;

  const { refs, floatingStyles } = useFloating({
    placement: 'right-start',
    middleware: [offset(10)],
    whileElementsMounted: autoUpdate,
  });

  const issues = dataStream?.issues?.filter(issue => issue.status !== 'read') || [];

  const loads = order.loads ?? [];

  return (
    <div id={OrderDataStreamType.LOCATION} ref={refs.setReference}>
      <Card>
        <CardHeaderContainer className="py-6">
          <div className="flex align-top space-x-3">
            <div>
              <WithNumericIndicator value={issues.length ?? 0}>
                <IconBox>
                  <TruckIcon className="w-6 h-6"/>
                </IconBox>
              </WithNumericIndicator>
            </div>
            <div>
              <CardTitle className="flex items-center mb-1 leading-none">
                {t('transport_route')}
              </CardTitle>
              <div>
                <CardSubtitle>{t('transport_route_description')}</CardSubtitle>
              </div>
              <div className="flex space-x-2 mt-2">
                {loads.map((load, index) => (
                  <div key={index} className="flex space-x-2">
                    {load.ext_ref && (
                      <MultiLabel theme="gray" label={'Load ID'}>
                        {load.ext_ref}
                      </MultiLabel>
                    )}
                    {load.vessel_ext_ref && (
                      <MultiLabel theme="gray" label={'Vessel Identifier'}>
                        {load.vessel_ext_ref}
                      </MultiLabel>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </CardHeaderContainer>
        <div className="!bg-gray-50">
          <CardBody>
            <div className="space-y-4">
              <div className="space-y-2">
                <div>
                  {issues.length > 0 && (
                    <LogisticsRouteIssues
                      issues={issues}
                      refs={refs}
                      floatingStyles={floatingStyles}
                    />
                  )}
                  <Helper>{t('summary')}</Helper>
                  <div className="flex items-center">
                    <MutedText>
                      <TransportSummaryText data={{
                        ...data,
                        meta: {
                          ...data.meta,
                          eta: data.meta.eta ? data.meta.eta : order.delivery_date,
                        },
                      }}/>
                    </MutedText>
                  </div>
                </div>
                {latestEvent && latestEvent.label && (
                  <div>
                    <Helper>{t('estimated_location')}</Helper>
                    <div className="flex items-center">
                      <MutedText>
                        <span className="inline-flex items-center">
                          <IconedField
                            icon={TruckIcon}
                          >
                            {formatLocation(latestEvent)}
                          </IconedField>
                        </span>
                      </MutedText>
                    </div>
                  </div>
                )}
                <div>
                  <Helper>{t('live_update')}</Helper>
                  <div className="flex items-center">
                    <div className="mr-1">
                      <WithHoverCard renderContent={<>div</>}>
                        <StatusDot success={true}/>
                      </WithHoverCard>
                    </div>
                    <MutedText>
                      <span>
                        {t('transports_summaries.last_synced_at', {
                          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                          // @ts-ignore
                          lastSyncedAt: format(
                            parse(latestEvent?.date),
                            DateFormat.HumanDateTime
                          ),
                          source: 'Sensitech',
                        })}
                      </span>
                    </MutedText>
                  </div>
                </div>
                <div className="-mx-3 sm:-mx-8">
                  <TransportMap data={data}/>
                </div>
              </div>
            </div>
          </CardBody>
        </div>
        <CardFooter>
          <div className="flex space-x-2">
            <CommentsPane
              commentable={{
                id: dataStream.id,
                type: 'OrderDataStream',
              }}
              comments={dataStream.comments || []}
              path={`/b/${organization.id}/comments/add`}
              Trigger={
                <Button className="flex items-center" size="sm" variant="white">
                  <span>Comment</span>
                  {dataStream.comments && dataStream.comments.length > 0 && (
                    <span className="ml-1">
                      <NumericIndicator brand="red" size="xs">
                        {dataStream.comments?.length}
                      </NumericIndicator>
                    </span>
                  )}
                </Button>
              }
            />
          </div>
        </CardFooter>
      </Card>
    </div>
  );
};
