import { z } from 'zod';

import { Field, Inspection, Order } from '~/types/types';

/**
 * Define the schema for the quality input form, along with the associated types.
 */
export const qualityInputSchema = z.object({
  produceVarietyId: z.string().min(1, 'Please select a produce variety'),
  produceId: z.string(),
  palletId: z.string().optional(),
  specId: z.string().optional(),
  filteredVarietyIds: z.array(
    z.object({ label: z.string(), value: z.string() })
  ),
  fields: z
    .array(
      z.object({
        id: z.string(),
        fieldId: z.string().min(1, 'Please select a field'),
        value: z.union([z.string(), z.number(), z.boolean()]).optional(),
        number_a: z.union([z.string(), z.number()]).optional().nullable(),
        number_b: z.union([z.string(), z.number()]).optional().nullable(),
        samples: z.union([z.string(), z.number()]).optional().nullable(),
        samples_with_defect: z.union([z.number(), z.string()]).optional().nullable(),
        percentage: z.union([z.string(), z.number()]).optional().nullable(),
        note: z.string().optional(),
        by_percentage: z.boolean().optional(),
      })
    )
    .min(1, 'Please add at least one field'),
});
export type FormQualityInput = z.infer<typeof qualityInputSchema>;
export type FormField = FormQualityInput['fields'][0];

/**
 * Define a mapper which helps mapping and converting the form fields to the model fields.
 */
export const QualityInputMapper = {
  editFormToInput: (formValues: FormQualityInput, order: Order, inspection: Inspection): Record<string, any> => {
    const payload = {
      ...formValues,
      produceVarietyId: formValues.produceVarietyId,
      inspectionId: inspection?.id,
      palletId: inspection?.quality_entries?.[0].pallet_id,
      orderId: order.id,
      produceId: formValues.produceId,
    };

    payload['fields'] = formValues.fields.map(field => {
      const fieldPayload = {
        id: field.id,
        fieldId: field.fieldId,
        value: field.value,
        number_a: field.number_a,
        number_b: field.number_b,
        samples: field.samples,
        samples_with_defect: field.samples_with_defect !== null ? parseInt(field.samples_with_defect) : null,
        input_unit: null,
      };

      if (field.by_percentage) {
        fieldPayload['percentage'] = field.value;
      }

      return fieldPayload;
    });

    return payload;
  },
  modelByForm: (formField: FormField, fieldMap: Record<string, Field>): Field | null => {
    if (!formField.fieldId) return null;

    if (!fieldMap[formField.fieldId]) {
      throw new Error(`Field not found: ${formField.fieldId}`);
    }

    return fieldMap[formField.fieldId];
  },
};
