import { CircleAlert } from 'lucide-react';

import { CheckIcon } from '@/Icon';
import { StatusMap } from '@/Labels/Status';
import { RAGScoreType } from '~/types/types';

export const orderPredictionStatusMap: StatusMap<RAGScoreType> = {
  'green': {
    icon: CheckIcon,
    textKey: 'green_order',
    theme: 'green',
  },
  'amber': {
    textKey: 'amber_order',
    theme: 'orange',
  },
  'red': {
    textKey: 'red_order',
    theme: 'red',
    icon: CircleAlert,
  },
  'n_a': {
    textKey: 'unknown_order',
    theme: 'gray',
  },
};
