/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Bars3Icon } from '@heroicons/react/20/solid';
import { zodResolver } from '@hookform/resolvers/zod';
import { ChevronUpIcon, Cross1Icon } from '@radix-ui/react-icons';
import React, { useMemo, useState } from 'react';
import { Controller, useFieldArray, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Button } from '@/Button';
import { Card, CardBody } from '@/Card';
import { Checkbox } from '@/Checkbox';
import { Form, FormField, FormItem, FormMessage } from '@/Form';
import { ChevronDownIcon, Icon, InspectionsIcon } from '@/Icon';
import { Input, InputGroup, LeftAddon, RightAddon } from '@/Input';
import { Label } from '@/Label';
import { LabeledField } from '@/LabeledField';
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalDescription,
  ModalFooter,
  ModalHeader,
  ModalIcon,
  ModalTitle,
  ModalTrigger,
} from '@/Modal';
import { FieldPicker } from '@/Pickers/FieldPicker';
import { ProducePicker } from '@/Pickers/ProducePicker';
import { SideLabeledField } from '@/SideLabeledField';
import Switch from '@/Switch';
import { Helper, MutedText, ValidationMessage } from '@/Text';
import { TooltipHelper } from '@/Tooltip';
import { FormQualityInput, QualityInputMapper, qualityInputSchema } from '~/Components/Orders/QualityInput/formatter';
import { useServerErrors } from '~/hooks/ServerErrorProvider';
import { useAction } from '~/hooks/useAction';
import { useInspection } from '~/hooks/useInspection';
import { usePageProps } from '~/hooks/usePageProps';
import { useRoutes } from '~/hooks/useRoutes';
import { useToast } from '~/hooks/useToast';
import { useTracking } from '~/hooks/useTracking';
import {
  Field,
  Inspection,
  Order,
  QualityEntry,
  Spec,
} from '~/types/types';
import { makeMap } from '~/utils/collections';

import { useOrder } from '../../../../pages/Dashboard/Order/OrderProvider';
import { AddFieldNote } from '../AddFieldNote';
import {
  getProduceVarieties,
  getSpecId,
  ProduceVarietyItem,
  qualityEntryToFormField,
  specFieldsToFormFields,
  useProduceItems,
  useProduceVarietyItems,
} from '../AddQualityInputModal.helpers';

interface AddQualityInputModalProps {
  children: React.ReactNode;
  spec: Spec;
  isEditing?: boolean;
  qualityEntry?: QualityEntry;
  inspectionId?: string;
}

// Helper function to calculate ratio (add this outside the component)
const calculateRatio = (a: number, b: number) => {
  if (!a || !b || b === 0) return '-';
  return (a / b).toFixed(2);
};

const getEditDefaultValues = (
  produceVarietyItems: ProduceVarietyItem[],
  inspection: Inspection | null
): FormQualityInput => {
  return {
    specId:
      getSpecId(
        inspection?.quality_entries?.[0]?.produce_variety_id ?? '',
        produceVarietyItems
      ) ?? '',
    filteredVarietyIds:
      getProduceVarieties(
        inspection?.quality_entries?.[0]?.produce_id ?? '',
        produceVarietyItems
      ) ?? [],
    produceVarietyId:
      inspection?.quality_entries?.[0]?.produce_variety_id ?? '',
    palletId: inspection?.quality_entries?.[0]?.pallet_ref ?? '',
    produceId: inspection?.quality_entries?.[0]?.produce_id ?? '',
    fields: inspection?.quality_entries?.map(qualityEntryToFormField) ?? [],
  };
};

const getCreateDefaultValues = (
  produceVarietyItems: ProduceVarietyItem[],
  relevantSpec: Spec | null
): FormQualityInput => {
  return {
    produceId: produceVarietyItems[0].produceId,
    produceVarietyId: produceVarietyItems[0].value,
    filteredVarietyIds:
      getProduceVarieties(
        produceVarietyItems[0].produceId,
        produceVarietyItems
      ) ?? [],
    specId: produceVarietyItems[0].specId || undefined,
    palletId: '',
    fields: specFieldsToFormFields((relevantSpec?.linked_fields as Field[]) || []),
  };
};

interface QualityFieldProps {
  index: number;
  control: any;
  register: any;
  remove: (index: number) => void;
  fieldMap: Record<string, Field>;
  isEditing: boolean;
  activeSpec?: Spec | null;
}

const parseRatioFieldName = (fieldName: string) => {
  const [numerator, denominator] = fieldName.split(':');
  return {
    numerator: numerator?.trim() ?? 'A',
    denominator: denominator?.trim() ?? 'B',
  };
};

const QualityField: React.FC<QualityFieldProps> = ({
  index,
  control,
  register,
  remove,
  fieldMap,
  isEditing,
  activeSpec,
}) => {
  const { t } = useTranslation();
  const fieldId = useWatch({ control, name: `fields.${index}.fieldId` });
  const byPercentage = useWatch({ control, name: `fields.${index}.by_percentage` });
  const field = fieldMap[fieldId];
  const numberA = useWatch({ control, name: `fields.${index}.number_a` });
  const numberB = useWatch({ control, name: `fields.${index}.number_b` });
  // debugger;

  const fieldModel = field;

  const isMeasurementType = field?.type === 'measurement';
  const isPercentageType = field?.type === 'percentage';
  const isBooleanType = field?.type === 'boolean';
  const isRatioType = field?.type === 'ratio';

  const { numerator, denominator } = parseRatioFieldName(fieldModel.name);

  return (
    <div className="border-t" aria-label={`row for ${fieldModel.name}`}>
      <input type="hidden" {...register(`fields.${index}.id`)} />
      <div className="grid md:grid-cols-[1fr_3fr] grid-cols-1">
        <div className="bg-gray-50 sm:border-b md:border-b-0 md:border-x min-w-[240px] p-4 flex flex-col gap-4">
          <Controller
            name={`fields.${index}.fieldId`}
            control={control}
            render={({ field }) => (
              <div>
                {fieldModel.required && (
                  <div className="relative inline-block">
                    <Helper>
                      Required
                    </Helper>
                    <span className="absolute top-0 right-[-10px] text-red-500">*</span>
                  </div>
                )}
                <div>
                  <FieldPicker
                    spec={activeSpec || undefined}
                    options={Object.values(fieldMap)}
                    selected={field.value}
                    onChange={(id) => field.onChange(id)}
                    title={t('select_field') as string}
                    showConstraintInSelected
                  />
                </div>
              </div>
            )}
          />

          {fieldModel.description && (
            <div className="border px-2 py-2 rounded">
              <MutedText className="italic">
                {fieldModel.description}
              </MutedText>
            </div>
          )}

          {isPercentageType && (
            <div className="flex items-center gap-2">
              <Controller
                name={`fields.${index}.by_percentage`}
                control={control}
                render={({ field }) => (
                  <div className="flex items-center">
                    <label htmlFor={`switch-${index}`} className="flex items-center gap-2">
                      <Switch
                        id={`switch-${index}`}
                        size="xs"
                        onCheckedChange={(value) => {
                          field.onChange(value);
                        }}
                        checked={field.value}
                      />
                      <MutedText>{t('report_by_percentage')}</MutedText>
                    </label>
                    <TooltipHelper>{t('report_by_percentage_tooltip')}</TooltipHelper>
                  </div>
                )}
              />
            </div>
          )}

          <div className="flex flex-wrap gap-2 mb-3 md:flex-nowrap md:items-center gap-y-2">
            {!isEditing && (
              <Controller
                name={`fields.${index}.note`}
                control={control}
                render={({ field }) => (
                  <AddFieldNote
                    ariaLabel={`note field for ${fieldModel?.name}`}
                    value={field.value || ''}
                    onChange={(value) => field.onChange(value)}
                  />
                )}
              />
            )}
            <Button type="button" className="items-center justify-center hidden md:inline-flex"
              onClick={() => remove(index)} variant="white" size="xs">
              <Icon className="flex items-center justify-center max-w-full max-h-full">
                <Cross1Icon width={12} height={4}/>
              </Icon>
            </Button>
          </div>
        </div>
        <div className="px-4 py-4 space-y-1">
          {isRatioType && (
            <div className="space-y-4">
              {/* Ratio inputs */}
              <div className="flex gap-4 items-end grid-cols-2">
                <FormField
                  name={`fields.${index}.number_a`}
                  render={({ field: controlledField }) => (
                    <LabeledField
                      label={numerator}
                      uniqueLabel={`ratio first value for ${numerator}`}
                      renderValue={
                        <FormItem>
                          <Input
                            {...controlledField}
                            placeholder="0.5"
                            type="string"
                            className="w-20 bg-white"
                            id={`ratio first value for ${numerator}`}
                            onChange={(e) => {
                              // Allow typing either comma or period by not immediately converting to number
                              if (e.target.value.endsWith(',') || e.target.value.endsWith('.')) {
                                controlledField.onChange(e.target.value);
                                return;
                              }

                              // Convert comma to period before parsing
                              const cleanValue = e.target.value.replace(',', '.');
                              const numericValue = parseFloat(cleanValue);

                              // Pass the number to the form if it's valid
                              if (!Number.isNaN(numericValue)) {
                                controlledField.onChange(numericValue);
                              }

                            }}
                          />
                          <FormMessage/>
                        </FormItem>
                      }
                    />
                  )}
                />

                <div>
                  <Label>
                    <span className="text-gray-500 font-medium text-lg">:</span>
                  </Label>
                </div>

                <FormField
                  name={`fields.${index}.number_b`}
                  render={({ field: controlledField }) => (
                    <LabeledField
                      label={denominator}
                      uniqueLabel={`ratio second value for ${denominator}`}
                      renderValue={
                        <FormItem>
                          <Input
                            {...controlledField}
                            placeholder="0.5"
                            type="string"
                            className="w-20 bg-white"
                            id={`ratio second value for ${denominator}`}
                            onChange={(e) => {
                              // Allow typing either comma or period by not immediately converting to number
                              if (e.target.value.endsWith(',') || e.target.value.endsWith('.')) {
                                controlledField.onChange(e.target.value);
                                return;
                              }

                              // Convert comma to period before parsing
                              const cleanValue = e.target.value.replace(',', '.');
                              const numericValue = parseFloat(cleanValue);

                              // Pass the number to the form if it's valid
                              if (!Number.isNaN(numericValue)) {
                                controlledField.onChange(numericValue);
                              }

                            }}
                          />
                          <FormMessage/>
                        </FormItem>
                      }
                    />
                  )}
                />
              </div>

              {/* Total samples inspected */}
              <Controller
                name={`fields.${index}.samples`}
                control={control}
                render={({ field: inputField }) => (
                  <LabeledField
                    label={t('total_samples_inspected')}
                    subLabel={t('total_samples_inspected_description') as string}
                    uniqueLabel={`ratio samples for ${fieldModel?.name}`}
                    renderValue={
                      <InputGroup className="flex-1 md:flex-initial">
                        <Input
                          rounded="left"
                          {...inputField}
                          placeholder="22"
                          type="number"
                          className="w-20 bg-white"
                          id={`ratio samples for ${fieldModel?.name}`}
                        />
                        <RightAddon>units</RightAddon>
                      </InputGroup>
                    }
                  />
                )}
              />

              {/* Optional: Display calculated ratio */}

              {numberA && numberB && (
                <div className="mt-2">
                  <Label size="xs" className="text-gray-500">
                    Calculated Ratio:
                    <span className="ml-2 font-medium">
                      {calculateRatio(numberA, numberB)}
                    </span>
                  </Label>
                </div>
              )}
            </div>
          )}

          {isPercentageType && (
            <div>
              {/* If percentage-based rendering is enabled */}
              {byPercentage && (
                <Controller
                  name={`fields.${index}.value`}
                  control={control}
                  render={({ field }) => (
                    <LabeledField
                      label={t('percentage_defect')}
                      subLabel={t('percentage_defect_description') as string}
                      uniqueLabel={`percentage value field for ${fieldModel?.name}`}
                      renderValue={
                        <InputGroup className="flex-1 md:flex-initial">
                          <Input
                            rounded="left"
                            {...field}
                            placeholder="22"
                            type="number"
                            className="w-20 bg-white"
                            id={`percentage value field for ${fieldModel?.name}`}
                          />
                          <RightAddon>%</RightAddon>
                        </InputGroup>
                      }
                    />
                  )}
                />
              )}

              {/* If not percentage-based rendering is enabled, we ask for samples and cases */}
              {!byPercentage && (
                <div className="space-y-4">
                  <Controller
                    name={`fields.${index}.samples_with_defect`}
                    control={control}
                    render={({ field }) => (
                      <LabeledField
                        label={t('count_defects')}
                        uniqueLabel={`samples with defect for ${fieldModel?.name}`}
                        subLabel={t('count_defects_description') as string}
                        renderValue={
                          <InputGroup className="flex-1 md:flex-initial">
                            <Input
                              rounded="left"
                              onChange={(e: any) => {
                                field.onChange(e.target.value);
                              }}
                              value={field.value}
                              defaultValue={field.value}
                              placeholder="22"
                              type="number"
                              className="w-20 bg-white"
                              id={`samples with defect for ${fieldModel?.name}`}
                            />
                            <RightAddon>units</RightAddon>
                          </InputGroup>
                        }
                      />
                    )}
                  />

                  <Controller
                    name={`fields.${index}.samples`}
                    control={control}
                    render={({ field }) => (
                      <LabeledField
                        label={t('total_inspected')}
                        uniqueLabel={`total percentage samples inspected for ${fieldModel?.name}`}
                        subLabel={t('total_inspected_description') as string}
                        renderValue={
                          <InputGroup className="flex-1 md:flex-initial">
                            <Input
                              rounded="left"
                              {...field}
                              placeholder="22"
                              type="number"
                              className="w-20 bg-white"
                              id={`total percentage samples inspected for ${fieldModel?.name}`}
                            />
                            <RightAddon>units</RightAddon>
                          </InputGroup>
                        }
                      />
                    )}
                  />
                </div>
              )}
            </div>
          )}

          {isMeasurementType && (
            <div className="space-y-4">
              {/* Top-side: we care about the value */}
              <div>
                <Controller
                  name={`fields.${index}.value`}
                  control={control}
                  render={({ field }) => (
                    <LabeledField
                      label={t('average_measurement')}
                      subLabel={t('average_measurement_description') as string}
                      uniqueLabel={`measurement value field for ${fieldModel?.name}`}
                      renderValue={
                        <InputGroup className="flex-1 md:flex-initial">
                          <Input
                            rounded="left"
                            {...field}
                            placeholder="22"
                            type="number"
                            className="w-20 bg-white"
                            id={`measurement value field for ${fieldModel?.name}`}
                          />
                          <RightAddon>{fieldModel?.unit}</RightAddon>
                        </InputGroup>
                      }
                    />
                  )}
                />
              </div>

              {/* Bottom-side: we care about the number of cases for which this was detected, and the total inspected */}
              <div>
                <div className="space-y-4">
                  <Controller
                    name={`fields.${index}.samples`}
                    control={control}
                    render={({ field }) => (
                      <LabeledField
                        label={t('total_samples_inspected')}
                        subLabel={t('total_samples_inspected_description') as string}
                        uniqueLabel={`measurement samples for ${fieldModel?.name}`}
                        renderValue={
                          <InputGroup className="flex-1 md:flex-initial">
                            <Input
                              rounded="left"
                              {...field}
                              placeholder="22"
                              type="number"
                              className="w-20 bg-white"
                              id={`measurement samples for ${fieldModel?.name}`}
                            />
                            <RightAddon>units</RightAddon>
                          </InputGroup>
                        }
                      />
                    )}
                  />
                </div>
              </div>
            </div>
          )}

          {isBooleanType && (
            <Controller
              name={`fields.${index}.value`}
              control={control}
              render={({ field }) => (
                <SideLabeledField
                  label="Confirmed"
                  renderValue={
                    <div className="flex items-center justify-end space-x-1">
                      <MutedText>{t('yes')}</MutedText>
                      <Checkbox
                        defaultChecked={field.value as boolean}
                        {...field}
                      />
                    </div>
                  }
                />
              )}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export const SaveQualityForm = ({
  isEditing,
  inspectionId,
  spec,
  qualityEntry,
  onClose,
}: any) => {
  const [showOptionalFields, setShowOptionalFields] = useState(false);
  // Handler to submit the form
  const onSubmit = (data: FormValues) => {
    if (isEditing) {
      const payload = QualityInputMapper.editFormToInput(data, order, inspection);
      return editQualityInput(payload);
    } else {
      return post({
        ...data,
        fields: data.fields.map(field => ({
          ...field,
          input_unit: field.by_percentage ? 'percentage' : null,
        })),
        palletId: data.palletId,
        produceId: data.produceId,
        produceVarietyId: data.produceVarietyId,
        orderId: order.id,
      });
    }
  };

  const handleProduceVarietyChange = (produceVarietyId: string) => {
    const specId = getSpecId(produceVarietyId, produceVarietyItems) || '';
    const spec = findSpec(specId);
    const fields = (spec?.linked_fields as Field[]) || [];

    setValue('specId', specId);
    setValue(
      'fields',
      fields.map((field) => ({
        id: '',
        value: '',
        fieldId: field.id,
        samples: '',
        note: '',
      }))
    );
  };

  const { errors, clearAllErrors } = useServerErrors();

  const handleCloseModal = () => {
    clearAllErrors();
    onClose();
  };

  const { track } = useTracking();
  const pageProps = usePageProps();
  const order = pageProps.order as Order;
  const {
    addQualityInput,
    editQualityInput: editQualityInputRoute,
    deleteQualityInput: deleteQualityInputRoute,
  } = useRoutes('seller');
  const { t } = useTranslation();
  const { inspection } = useInspection(inspectionId);

  const { findSpec } = useOrder();

  const { toast } = useToast();

  const produceItems = useProduceItems();
  const produceVarietyItems = useProduceVarietyItems();

  const relevantSpec = findSpec(spec.id);
  const {
    register,
    control,
    handleSubmit,
    reset,
    watch,
    setValue,
    ...props
  } = useForm<FormQualityInput>({
    resolver: zodResolver(qualityInputSchema),
    defaultValues:
      inspectionId && isEditing
        ? getEditDefaultValues(produceVarietyItems, inspection)
        : getCreateDefaultValues(produceVarietyItems, relevantSpec),
  });

  const form = {
    register, control, handleSubmit, reset, watch, setValue, ...props,
  };

  const { post } = useAction(addQualityInput(order.id), {
    onSuccess: () => {
      track({
        event: 'add_quality_input',
        order_id: order.id,
      });
      reset();
      toast({
        title: t('toasts.success') as string,
        description: t('toasts.quality_input_added') as string,
      });
      handleCloseModal();
    },
    onError: (errors) => {
      toast({
        title: t('toasts.quality_input_error_title') as string,
        description: t('toasts.quality_input_error_message') as string,
        variant: 'destructive',
        duration: 5000,
        error: errors,
      });
    },
  });

  const { post: editQualityInput } = useAction(
    editQualityInputRoute(order.id),
    {
      onSuccess: () => {
        track({
          event: 'edit_quality_input',
          id: inspectionId as string,
          order_id: order.id,
        });
        reset();
        toast({
          title: t('toasts.success') as string,
          description: t('toasts.quality_input_edited') as string,
        });
        handleCloseModal();
      },
      onError: (errors) => {
        toast({
          title: t('toasts.error') as string,
          description: t('toasts.error_message') as string,
          variant: 'destructive',
          error: errors,
        });
      },
    }
  );

  const { fields: defaultFields, remove } = useFieldArray({
    control,
    name: 'fields',
  });

  const { post: deleteQualityInput } = useAction(
    deleteQualityInputRoute(order.id),
    {
      onSuccess: () => {
        toast({
          title: 'Inspection has been successfully removed',
        });
      },
    }
  );

  const handleDeleteQualityEntry = (inspectionId: string) => {
    deleteQualityInput({ inspectionId: inspectionId }).then(() => {
      return;
    });
    track({
      event: 'delete_quality_input',
      id: inspectionId,
      order_id: order.id ?? '',
    });
  };

  const activeSpec = findSpec(watch('specId') || '');
  const fieldMap: Record<string, Field> = useMemo(() => makeMap(activeSpec?.linked_fields ?? []), [activeSpec?.linked_fields]);
  const filteredOrderVarieties = watch('filteredVarietyIds');

  const hasRequiredFields = (defaultFields || []).some(
    defaultField => QualityInputMapper.modelByForm(defaultField, fieldMap)?.required
  );

  /**
   * To prevent an overload for our dear users, we can hide non-required fields if there are required fields.
   * This is the case if the user has not selected to show optional fields, and there are required fields.
   * Otherwise, we show all fields.
   */
  const visibleFields = useMemo(() => {
    if (!showOptionalFields && hasRequiredFields) {
      return defaultFields.filter(field => QualityInputMapper.modelByForm(field, fieldMap)?.required);
    }

    return defaultFields;
  }, [showOptionalFields, defaultFields]);
  const optionalFields = defaultFields.filter(field => !QualityInputMapper.modelByForm(field, fieldMap)?.required);

  return (
    <Form {...form}>
      <form
        className="flex flex-col flex-1 overflow-auto"
        onSubmit={handleSubmit(onSubmit)}
      >
        <ModalBody className="overflow-auto ">
          <Card>
            <CardBody size="sm" className="h-full px-2 md:py-4 md:px-8">
              <div className="space-y-4">
                <div>
                  <input type="hidden" {...register('specId')} />
                  <SideLabeledField
                    label={t('details')}
                    renderValue={
                      <div className="space-y-2">
                        <LabeledField
                          label={t('produce')}
                          renderValue={
                            <div className="inline-block min-w-[200px]">
                              <Controller
                                name="produceId"
                                control={control}
                                render={({ field }) => (
                                  <ProducePicker
                                    disabled={isEditing}
                                    onChange={(value) => {
                                      const filteredVarieties =
                                        getProduceVarieties(
                                          value ?? '',
                                          produceVarietyItems
                                        ) ?? [];

                                      const firstVariety =
                                        filteredVarieties?.[0]?.value;
                                      setValue(
                                        'filteredVarietyIds',
                                        produceVarietyItems.filter(
                                          (item) => item.produceId === value
                                        )
                                      );
                                      setValue(
                                        'produceVarietyId',
                                        firstVariety || ''
                                      );
                                      handleProduceVarietyChange(
                                        firstVariety
                                      );
                                      field.onChange(value);
                                    }}
                                    selectedProduce={field.value}
                                    produces={produceItems ?? []}
                                  />
                                )}
                              />
                            </div>
                          }
                        />
                        <LabeledField
                          label={t('variety')}
                          renderValue={
                            <div className="inline-block min-w-[200px]">
                              <Controller
                                name="produceVarietyId"
                                control={control}
                                render={({ field }) => (
                                  <ProducePicker
                                    disabled={isEditing}
                                    onChange={(produceVarietyId) => {
                                      handleProduceVarietyChange(
                                        produceVarietyId ?? ''
                                      );
                                      field.onChange(produceVarietyId);
                                    }}
                                    selectedProduce={field.value}
                                    produces={filteredOrderVarieties ?? []}
                                  />
                                )}
                              />
                            </div>
                          }
                        />
                        <LabeledField
                          label={t('pallet')}
                          subLabel={t('pallet_ref_description') as string}
                          renderValue={
                            <div>
                              <InputGroup>
                                <LeftAddon className="flex items-center justify-center">
                                  <Icon className="flex items-center justify-center">
                                    <Bars3Icon/>
                                  </Icon>
                                </LeftAddon>
                                <Input
                                  autoFocus
                                  error={errors.palletId ? 'true' : 'false'}
                                  disabled={isEditing}
                                  placeholder={t('placeholders.pallet_id')}
                                  rounded="right"
                                  type="text"
                                  {...register('palletId')}
                                />
                              </InputGroup>
                              <ValidationMessage error={errors.palletId}/>
                            </div>
                          }
                        />
                      </div>
                    }
                  />
                </div>

                <hr/>

                <div>
                  <div className="space-y-2">
                    <LabeledField
                      label={t('quality_fields')}
                      subLabel={t('quality_fields_description') as string}
                    />

                    <div className="overflow-auto sm:max-h-[370px]">
                      {visibleFields.map((defaultField, index) => (
                        <QualityField
                          key={defaultField.id}
                          index={index}
                          control={control}
                          register={register}
                          remove={remove}
                          fieldMap={fieldMap as any}
                          isEditing={isEditing}
                          activeSpec={activeSpec}
                        />
                      ))}
                    </div>

                    {/* Add the optional fields button here */}
                    {hasRequiredFields && optionalFields.length > 0 && (
                      <div className="mt-4 flex justify-center">
                        <Button
                          type="button"
                          variant="ghost"
                          onClick={() => setShowOptionalFields(!showOptionalFields)}
                          className="w-full max-w-sm flex items-center justify-center gap-2"
                        >
                          <Icon>
                            {showOptionalFields ? (
                              <ChevronUpIcon className="h-4 w-4"/>
                            ) : (
                              <ChevronDownIcon className="h-4 w-4"/>
                            )}
                          </Icon>
                          {showOptionalFields
                            ? t('hide_optional_fields')
                            : t('show_n_optional_fields', {
                              count: optionalFields.length,
                            })
                          }
                        </Button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
        </ModalBody>
        <ModalFooter>
          {isEditing && (
            <Button
              type="button"
              className="flex items-center justify-center w-full gap-1 mr-auto md:w-auto md:justify-start"
              variant="error-ghost"
              onClick={() =>
                handleDeleteQualityEntry(qualityEntry?.inspection_id ?? '')
              }
            >
              {t('delete')}
            </Button>
          )}
          <Button
            type="button"
            variant="ghost"
            onClick={() => handleCloseModal()}
            className="flex items-center justify-center w-full gap-1 md:w-auto md:justify-start"
          >
            {t('cancel')}
          </Button>
          <div className="flex items-center w-full md:space-x-4 md:w-auto">
            {/* @ts-ignore */}
            <Button
              type="submit"
              // @ts-ignore
              // disabled={!isValid}
              className="flex items-center justify-center w-full gap-1 md:w-auto md:justify-start"
            >
              {/* {isLoading && (
                <Icon className="text-white">
                  <ArrowPathIcon
                    className={`${isLoading && 'animate-spin'}`}
                  />
                </Icon>
              )} */}
              {isEditing ? t('save') : t('create')}
            </Button>
          </div>
        </ModalFooter>
      </form>
    </Form>
  );
};

export const AddQualityInputModal = ({
  children,
  spec,
  isEditing,
  qualityEntry,
  inspectionId,
}: AddQualityInputModalProps) => {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <Modal
      defaultOpen={false}
      open={isModalOpen}
      onOpenChange={(open) => setIsModalOpen(open)}
    >
      <ModalTrigger asChild>{children}</ModalTrigger>
      <ModalContent className="flex flex-col max-w-5xl" onInteractOutside={(event) => event.preventDefault()}>
        <ModalHeader>
          <div className="flex items-center space-x-3">
            <ModalIcon icon={<InspectionsIcon className="h-7 w-7"/>}/>
            <div>
              <ModalTitle>
                {isEditing ? t('edit_quality_input') : t('add_quality_input')}
              </ModalTitle>
              <ModalDescription>
                {isEditing
                  ? t('edit_quality_input_description')
                  : t('add_quality_input_description')}
              </ModalDescription>
            </div>
          </div>
        </ModalHeader>

        {isModalOpen && (
          <SaveQualityForm
            inspectionId={inspectionId}
            spec={spec}
            isEditing={isEditing}
            onClose={() => setIsModalOpen(false)}
            qualityEntry={qualityEntry}
          />
        )}
      </ModalContent>
    </Modal>
  );
};
