import { PageProps as BasePageProps } from '@inertiajs/core';

import { DatePeriod } from '~/types/global-types';
import SpecInput = App.Domain.Specs.SpecInput;
import ConstraintType = App.Domain.Specs.ConstraintType;
import LogisticsProduceTempConfig = App.Domain.Produce.LogisticsProduceTempConfig;
import WeatherProduceTempConfig = App.Domain.Produce.WeatherProduceTempConfig;
import ProduceTempConfig = App.Domain.Produce.ProduceTempConfig;
import SupportedFieldType = App.Domain.Fields.SupportedFields.SupportedFieldType;
import UnitType = App.Domain.Units.UnitType;

export type PredictionType = App.Domain.ML.TargetPredictions.PredictionType;

export type CompoundIssue = App.Domain.Issues.CompoundIssue.CompoundIssue;
export type QualityEntry = App.Models.QualityEntry;
export type ModelIssue = App.Models.Issue;
export type Event = App.Models.Event;
export type SellerSummary = App.Models.SellerSummary;
export type OrderIssueSummary = App.Models.OrderIssueSummary;
export type Comment = App.Models.Comment;

export type UserGroup = App.Models.UserGroup;
export type Produce = App.Models.Produce & {
  meta_type?: string,
  logistics_temp_config?: LogisticsProduceTempConfig,
  weather_temp_config?: WeatherProduceTempConfig
};
export type ProduceVariety = App.Models.ProduceVariety & { public_name: string };
export type Attachment = App.Models.Attachment;
export type OrderProduce = App.Models.OrderProduce & { active_spec?: Spec };
export type ModelFieldConstraint = App.Models.FieldConstraint;
export type QCQualityInput = App.Domain.QualityInputs.QCQualityInput;
export type IssueType = App.Domain.Issues.IssueType;
export type ForecastAccuracySummary = App.Domain.Reports.ForecastAccuracy.ForecastAccuracySummary;
export type FieldSummary = App.Models.FieldSummary;
export type UnitFieldSummary = App.Models.UnitFieldSummary;
export type PercentageFieldSummary = App.Models.PercentageFieldSummary;
export type RatioFieldSummary = App.Models.RatioFieldSummary;
export type MeasurementFieldSummary = App.Models.MeasurementFieldSummary;
export type BooleanFieldSummary = App.Models.BooleanFieldSummary;
export type Range = App.Models.Range;
export type Field = App.Models.Field & { type: FieldType, required: boolean, assigned: boolean };
export type Spec = App.Models.Spec;
export type Pallet = App.Models.Pallet;
export type Ranking = App.Domain.Analytics.Ranking.Ranking;

export type FieldConstraint = ModelFieldConstraint & {
  type: ConstraintType;
  produce_varieties?: ProduceVariety[],
  active?: boolean
  is_future?: boolean;
};

export type OrderStatusType = App.Domain.Order.OrderStatusType;

export type NotificationType =
  | 'NewIssuesUpdate'
  | 'NewAdminDailyRollup'
  | 'NewOrdersFound'
  | 'risk_assessment';

export type Notification = {
  id: string;
  type: NotificationType;
  created_at: string | null;
  updated_at: string | null;
  data: any;
  read_at: string | null;
};

export type ClaimGroupType = 'seller' | 'produce' | 'reason';

export type IssueEvent = {
  id: string;
  issue_id: string;
  type: string;
  data: any;
  created_at: string;
  updated_at: string;
  comment?: Comment;
  user?: User;
}

export type Issue = ModelIssue & {
  title: string;
}

export interface ClaimGroup {
  type: ClaimGroupType;
  name: string;
  id: string;
  countClaims: number;
  total: number;
  totalIssues: number;
  subGroups?: ClaimGroup[];
  orders?: Order[];
}

export interface ClaimPeriodSummary {
  startDate: string;
  endDate: string;
  countClaims: number;
  total: number;
  totalIssues: number;
  topGroups: ClaimGroup[];
}

export interface NewIssuesUpdateNotification extends Notification {
  type: 'NewIssuesUpdate';
  data: {
    org_id: string;
    redirect_to: string;
    from_date: string;
    to_date: string;
    order_count: number;
    total_issues_count: number;
    spec_discrepancy_issue_count: number;
    transport_delay_issue_count: number;
    transport_temp_threshold_issue_count: number;
  };
}

export interface NewOrdersFoundNotification extends Notification {
  type: 'NewOrdersFound';
  data: {
    org_id: string;
    redirect_to: string;
    order_count: number;
  };
}

export interface NewAdminDailyRollupNotification extends Notification {
  type: 'NewAdminDailyRollup';
  data: {
    org_id: string;
    redirect_to: string;
    from_date: string;
    to_date: string;
    order_count: number;
    total_issues_count: number;
    spec_discrepancy_issue_count: number;
    transport_delay_issue_count: number;
    transport_temp_threshold_issue_count: number;
  };
}

export interface User extends App.Models.User {
  unread_notifications?: Notification[];
  notifications?: Notification[];
}

export interface Organization extends App.Models.Organization {
  sell_order_count: number;
  accuracy?: number;
  color?: string;
  latestSellerSummary?: SellerSummary | null;
  forecastAccuracySummary?: {
    accuracy?: number;
    color: string;
  };
}

export type RAGScoreType = 'red' | 'amber' | 'green' | 'n_a';

export interface UnitData {
  unit_id: string;
  unit_type: UnitType;
  produce_name: string;
  varieties: string;
  varieties_count: number;
  ship_date: string;
  delivery_date: string;
  current_date: string;
  ggn: string;
  best_before_date: string | null;
  harvest_date: string | null;
  transport_spark_chart?: {
    sparkCharts?: SparkChartData[]
  };
}

export interface SparkChartData {
  type: string;
  color: string;
  values: number[];
}

export interface OrderData {
  order?: Order;
  orderId?: string;
  shipDate?: string;
  deliveryDate?: string;
  unitSummaries?: UnitData[];
  claimAmount?: number;
  inspectionsData?: {
    inspections: {
      type: string;
      qualityItems: {
        fieldId: string;
        fieldName: string;
        fieldType: FieldType;
        baseType: string | null;
        defectLevel: string | null;
        outOfSpec: boolean | null;
        unit: string | null;
        averageValue: number | null;
        specMax: number | null;
        specMin: number | null;
        issueId: string | null;
        inspectionDate: string | null;
      }[]
    }[];
  };
}

export interface MetaCache {
  id: string;
  modelable_id: string;
  modelable_type: string;
  type: 'order_ml';
  version: number;
  data: OrderData | null;
}

export interface Order extends App.Models.Order {
  requiresAttention?: boolean;
  has_buyer_intake?: boolean;
  has_seller_intake?: boolean;
  requires_seller_input?: boolean;
  latestEvent?: Event;
  activeDataStreams?: App.Models.OrderDataStream[];
  total?: number;
  ml_data?: MetaCache;
  predicted_score?: RAGScoreType;
}

export type OrderStep = App.Models.OrderStep;
export type Integration = App.Models.Integration;
export type Inspection = App.Models.Inspection;

export interface OrderFieldSummary {
  field: Field;
  fieldSummaries: FieldSummary[];
  relevantConstraint?: FieldConstraint;
}

export interface GenericDataStream {
  status: 'pending' | 'active' | 'inactive';
  expected_at: string;
}

export interface OrderDataStream<T> extends App.Models.OrderDataStream {
  data: T;
}

export enum OrderDataStreamType {
  TRANSPORT_TEMPERATURE = 'TRANSPORT_TEMPERATURE',
  LOCATION = 'LOCATION',
  WEATHER = 'WEATHER',
}

export type CommentableType =
  | 'Order'
  | 'FieldSummary'
  | 'Attachment'
  | 'OrderDataStream'
  | 'QualityEntry';

export interface AttachmentComment extends Comment {
  commentable?: Attachment;
}

export interface InspectionComment extends Comment {
  commentable?: FieldSummary;
}

export interface OrderDataStreamComment extends Comment {
  commentable?: OrderDataStream<any>;
}

export type AppMode = 'buyer' | 'seller';

export interface PageProps extends BasePageProps {
  errors: Record<string, any>;
  organization: Organization & {
    maps_to: {
      id: string;
      toable_type: string;
      toable_id: string;
      fromable_type: string;
      fromable_id: string;
      toable: any;
    }[]
  };
  user: User;
  isBuyer: boolean;
  order?: Order;
  show_onboard_modal?: boolean;
  app: {
    appMode: AppMode;
    path: string;
  };
}

export interface PickerItem {
  icon: JSX.Element;
  labelKey: string;
  shortcut: string[];
}

export const sellerAnalyticsTypes = [
  'Quality Issue Frequency',
  'Quality costs',
  'Forecast Accuracy',
] as const;
export type SellerAnalyticsType = (typeof sellerAnalyticsTypes)[number];

export type SectionKey =
  | 'order'
  | 'inspection'
  | 'attachments'
  | 'temperature'
  | 'transportation';

export const defaultSteps = [
  'qc',
  'qc_review',
  'in_transit',
  'qa_intake',
  'finalized',
] as const;

export type OrderStepType = (typeof defaultSteps)[number];

export type FieldType = 'measurement' | 'boolean' | 'percentage';

export enum DataFieldType {
  TEXT = 'text',
  NUMBER = 'number',
  CHOICE = 'choice',
}

export interface Counter {
  counter_value: number;
  delta: number;
  delta_indicator: 'green' | 'orange' | 'red';
}

export enum ProduceCategory {
  FRUIT = 'fruit',
  VEGETABLE = 'vegetable',
}

export interface IntegrationDetails {
  id: string;
  type: string;
  status: 'active' | 'inactive';
  last_synced_at: string;
}

export enum Summarizable {
  BooleanFieldSummary = 'BooleanFieldSummary',
  PercentageFieldSummary = 'PercentageFieldSummary',
  MeasurementFieldSummary = 'MeasurementFieldSummary',
  RatioFieldSummary = 'RatioFieldSummary',
}

export interface PaginatedItems<T> {
  data: T[];
  current_page: number;
  first_page_url: string;
  from: number;
  last_page: number;
  last_page_url: string;
  prev_page_url: string;
  next_page_url: string;
  per_page: number;
  to: number;
  total: number;
}

export type InspectionStatus = 'upload_first' | 'upload_append';

export interface WeatherDataDayPayload {
  date: Date;
  minCelcius?: number;
  maxCelcius?: number;
  avgCelcius?: number;
  /** Rainfall, or precipitation, in millimeters**/
  rainfall?: number;
}

export interface WeatherDataPayload {
  ggn: string;
  startDate: Date;
  endDate: Date;
  baselines?: {
    celcius?: number;
    rainfall?: number;
  };
  issues: any[];
  granularity: 'day' | 'week' | 'month';
  events: WeatherDataDayPayload[];
}

export interface LocationEvent {
  date?: Date;
  type?: string;
  lat?: number;
  lon?: number;
  label?: string;
  countryCode?: string;
  location?: Location;
  transportType?: string;
  transportID?: string;
  issue?: {
    delayInSec?: number;
  };
  source?: string;
}

export interface LogisticsRouteIssue {
  type: string;
  duration: number;
  label?: string;
  date: Date;
}

export interface Location {
  lat: number;
  lon: number;
  label?: string;
  countryCode?: string;
}

export interface TemperatureIssue {
  type: string;
  duration: number;
  label?: string;
  date: Date;
}

export interface LocationDataPayload {
  meta: {
    origin?: Location;
    destination?: Location;
    eta?: Date;
  };
  summary: {
    totalDelayInSec?: number;
    issues?: LogisticsRouteIssue[];
  };
  events: LocationEvent[];
}

export interface TemperatureEvent {
  date: Date;
  value: number;
  isAnomaly?: boolean;
  metric?: 'celcius';
}

export interface TemperaturePayload {
  meta: {
    startDate: Date;
    endDate: Date;
    source: string;
  };
  summary: {
    minValue?: number;
    count?: number;
    issueCount?: number;
    avgValue?: number;
    maxValue?: number;
    issues?: TemperatureIssue[];
  };
  baselines: {
    low: number;
    high: number;
    general: number;
  };
  eventGroups: {
    type: string;
    metric: string | null;
    summary: {
      min: number;
      max: number;
      avg: number;
      count: number;
      issues: TemperatureIssue[];
    };
    events: TemperatureEvent[];
  }[];
}

export type DataStreamGroup = 'HARVEST' | 'TRANSPORT' | 'INSPECTION';

export enum OrganizationStatus {
  READY = 'READY',
  GENERATING = 'GENERATING',
}

export enum OrderStatus {
  READY = 'READY',
  GENERATING = 'GENERATING',
}

type SpecTestResultType = 'PASS' | 'FAIL' | 'IGNORE';

interface SpecTestResult {
  field: Field | null;
  fieldSummary: FieldSummary | null;
  status: SpecTestResultType;
  comment: string | null;
}

export interface VerifySpecFileOutput {
  specFile: any;
  results: SpecTestResult[];
  errors: Record<string, string[]>;
}

export enum IssueStatus {
  Resolved = 'resolved',
  Ignored = 'ignored',
  Mixed = 'mixed',
  Open = 'open',
  Read = 'read',
  Accepted = 'accepted',
  Escalated = 'escalated',
}

export interface SavedReport extends App.Models.SavedReport {
  date_range_type: DatePeriod;
}

export type SaveSpecInput = SpecInput;
export type TimeSeries = App.Domain.Analytics.TimeSeries.TimeSeries;
export type EntityScopeType = App.Domain.Common.EntityScopeType;
export type Sort = App.Domain.Common.Sort;
export type SortOrder = App.Domain.Common.SortOrder;
export type DateFilter = App.Domain.Dates.DateFilter;
export type RelativeDifference = App.Domain.Analytics.RelativeDifference;
export type EntityBreakdowns = App.Domain.Analytics.Breakdown.EntityBreakdowns;
export type EntityBreakdownItem = App.Domain.Analytics.Breakdown.EntityBreakdownItem;
export type EntityType = 'retailer' | 'grower' | 'supplier';

export interface Prediction {
  id: string;
  type: PredictionType;
  raw_payload: any;
  explanation: string;
  confidence: number;
  targetable: Order | QualityEntry;
  manifest?: NotificationManifest;
}

export interface SupportedField {
  base_type: SupportedFieldType;
  label: string;
  name: string;
  unit: string;
  defect_type: string;
  assigned?: boolean;
  category: string;
  type: FieldType;
  description: string;
}

export interface BasicField {
  id?: string;
  name: string;
  type: FieldType;
  base_type?: SupportedFieldType;
}

export interface NotificationManifest {
  preview: boolean;
  type: NotificationType;
  organization: Organization;
  recipientsCount: number;
  recipients: User[] | null;
  payload: any | null;
  nextDelivery: string | null; // ISO date string for Carbon date
  willSend: boolean;
}

export interface SimpleOrderData {
  id: string;
  extRef: string;
  image: string | null;
  produceName: string | null;
  sellerName: string | null;
  score: RAGScoreType | null;
  issuesCount: number | null;
  order?: Order; // Optional property equivalent to nullable in PHP
}
