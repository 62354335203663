import { PendingIcon } from '@/Icon';
import { StatusMap } from '@/Labels/Status';

export type EngagementStatus = 'requires_seller_input';

/**
 * Status-map for spec versions, displaying how to render the various states of a spec version.
 */
export const orderEngagementStatusMap: StatusMap<EngagementStatus> = {
  'requires_seller_input': {
    icon: PendingIcon,
    label: 'QA Input required',
    textKey: 'input_required',
    descriptionKey: 'input_required_description',
    theme: 'blue',
  },
};
