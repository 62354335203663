import * as Collapsible from '@radix-ui/react-collapsible';
import { AnimatePresence, motion } from 'framer-motion';
import React, { useCallback } from 'react';

import Styles from '@/ExpandableCard.module.css';
import { LazyFetcher, Path, PathValue } from '@/Pickers/LazyFetcher';
import { Spinner } from '@/Spinner';
import { PageProps } from '~/types/types';

interface LazyExpandableCardProps<TPageProps, TInputProps = void> {
  renderPreview: (props: {
    open: boolean | undefined;
    result: PathValue<TPageProps, Path<TPageProps>> | null;
  }) => React.ReactNode;
  renderContent: (result: any) => React.ReactNode;
  size?: 'sm' | 'md' | 'lg';
  manualOpen?: boolean;
  onManualOpen?: () => void;
  resultKey: Path<TPageProps>;
  reloadKey: keyof TPageProps;
  open?: boolean; // Controlled open state
  onOpenChange?: (open: boolean) => void; // Callback for open state changes
  data: Partial<TInputProps>;
  enableCache?: boolean;
  className?: string;
}

/**
 * LazyExpandableCard
 *
 * A collapsible card component that combines expandable functionality with lazy loading.
 * It uses LazyFetcher internally to manage data loading based on URL parameters.
 *
 * Key features:
 * 1. Expands/collapses based on both local state and URL parameters
 * 2. Loads data only when expanded
 * 3. Provides loading states and animations
 * 4. Syncs with URL parameters through LazyFetcher
 *
 * The open state logic:
 * - Initially matches URL parameters (isActive)
 * - After user interaction, combines local state with isActive
 * - Clears URL parameters when closing
 *
 * @example
 * <LazyExpandableCard<OrderPageProps, OrderParameters>
 *   resultKey="fieldSummary"
 *   reloadKey="fieldSummary"
 *   data={{ activeFieldId: field.id }}
 *   renderPreview={({ open, result }) => (
 *     <PreviewContent open={open} result={result} />
 *   )}
 *   renderContent={(result) => (
 *     <ExpandedContent result={result} />
 *   )}
 * />
 *
 * @param renderPreview - Function to render the preview (always visible)
 * @param renderContent - Function to render the expanded content
 * @param resultKey - Path to the result in pageProps
 * @param reloadKey - Key to reload when fetching
 * @param data - Data object that should match URL parameters
 * @param enableCache
 * @param className - Optional className for styling
 * @returns A collapsible card that loads data when expanded
 */
export function LazyExpandableCard<TPageProps extends PageProps, TInputProps = void>({
  renderPreview,
  renderContent,
  resultKey,
  reloadKey,
  data,
  enableCache = false,
  className,
}: LazyExpandableCardProps<TPageProps, TInputProps>) {
  const [open, setOpen] = React.useState<boolean | undefined>(undefined);

  const handleOpenChange = useCallback((newOpen: boolean, onFetch: (data?: Partial<TInputProps>) => Promise<void>) => {
    setOpen(newOpen);

    if (newOpen) {
      onFetch?.(data);
    } else {
      onFetch?.({} as Partial<TInputProps>);
    }
  }, [data]);

  return (
    <LazyFetcher<TPageProps, TInputProps>
      resultKey={resultKey}
      reloadKey={reloadKey}
      enableCache={enableCache}
      data={data}
    >
      {({ result, loading, onFetch, isActive }) => (
        <div className={className}>
          <Collapsible.Root
            open={open === undefined ? isActive : open && isActive}
            onOpenChange={(newOpen) => handleOpenChange(newOpen, onFetch)}
          >
            <Collapsible.Trigger asChild className="cursor-pointer">
              <div>
                {renderPreview({ open, result })}
              </div>
            </Collapsible.Trigger>
            <Collapsible.Content className={Styles.ExpandableCard}>
              <div className="border-t border-gray-100">
                <div className="relative min-h-[100px]">
                  <AnimatePresence mode="wait">
                    {loading ? (
                      <motion.div
                        key="loading"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        className="absolute inset-0 flex items-center justify-center"
                      >
                        <Spinner/>
                      </motion.div>
                    ) : result ? (
                      <motion.div
                        key="content"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        className="w-full"
                      >
                        {renderContent(result)}
                      </motion.div>
                    ) : null}
                  </AnimatePresence>
                </div>
              </div>
            </Collapsible.Content>
          </Collapsible.Root>
        </div>
      )}
    </LazyFetcher>
  );
}
