/**
 * Common API:
 *
 * <FieldPicker />
 *
 * or
 *
 * <Picker>
 *   <PickerTrigger>{...}</PickerTrigger>
 *
 *   <FieldPickerBody />
 * </Picker>
 */

import { Slot } from '@radix-ui/react-slot';
import React, { ReactNode, useMemo, useState } from 'react';

import { GenericPicker } from '@/Pickers/GenericPicker';
import { BasicField, Field, SupportedField } from '~/types/types';
import { delimit } from '~/utils/delimitList';
import { formatPicker, toPickerItem } from '~/utils/formatPicker';
import { makeRandomID } from '~/utils/random';

interface FieldPickerProps {
  children?: (value: BasicField | null, changed: boolean, originalValue: BasicField | null) => ReactNode;
  onChange?: (value: BasicField | null) => void;
  value?: BasicField;
  specFields?: Field[];
  supportedFields?: SupportedField[];
}

/**
 * A picker abstraction meant to handle the editing of fields.
 *
 * How to use it:
 * - Similar to GenericPicker, this picker can be used standalone or as part of a composition API (using a Trigger as child).
 * - The value it receives is that of a `BasicField` type. This is a simple representation of a field, with only the necessary properties.
 * - It will receive two sources of options: `specFields` and `supportedFields`.
 *    - The former are fields that are known from the spec (optional) and have concrete IDs, while the latter are fields that are known by the system (optional).
 * - The onChange handler will receive a `BasicField` object, which can be null.
 *
 * @param onChange
 * @param value
 * @param specFields
 * @param supportedFields
 * @param children
 * @constructor
 */
export const FieldPicker = ({
  onChange,
  value,
  specFields,
  supportedFields,
  children,
}: FieldPickerProps) => {
  const [originalValue] = useState(() => value);

  /**
   * The field options we can support are:
   * - The local field being edited.
   * - All spec fields: these are fields we already know about from the spec.
   * - All supported fields: these are fields (regardless of the spec) our system knows about.
   */
  const supportedFieldOptions =
    formatPicker(
      [
        // The local field that is being edited
        toPickerItem(value, 'id', 'name'),
        // The spec fields
        ...(specFields || []).map((specField) => ({
          ...specField,
          subLabel: delimit([specField.unit, specField.defect_type, specField.type]),
          metaLabel: specField.assigned ? 'Assigned' : undefined,
        })),
        ...(supportedFields || []).map((supportedField) => ({
          ...supportedField,
          subLabel: delimit([supportedField.unit, supportedField.defect_type, supportedField.type]),
          metaLabel: supportedField.assigned ? 'Assigned' : undefined,
        })),
      ],
      'name', 'name', 'category',
    );

  const handleCreate = (name: string) => {
    const newField: BasicField = {
      id: makeRandomID(),
      name,
      base_type: 'unknown',
      type: 'measurement',
    };

    onChange?.(newField);
  };

  const handleChange = (value: string | null | undefined | unknown) => {
    if (value === null || value === undefined) {
      onChange?.(null);
      return;
    }

    const specField = specFields.find((field: any) => field.name === value);

    /**
     * In the case we find a spec field, we can just return it.
     */
    if (specField?.id) {
      return onChange?.(specField);
    }

    /**
     * In the case that we instead find a "supported field", we need to return the supported field.
     */
    const supportedField = supportedFields?.find((field: any) => field.name === value);

    if (supportedField) {
      return onChange?.(supportedField);
    }

    /**
     * Ideally, this doesnt happen, but we require to create a new field.
     */
    return handleCreate(value as string);
  };

  const changed = useMemo(() => {
    return JSON.stringify(originalValue) !== JSON.stringify(value);
  }, [originalValue, value]);

  return (
    <GenericPicker
      isMulti={false}
      options={supportedFieldOptions}
      value={toPickerItem(value, 'id', 'name').name}
      onCreate={handleCreate}
      onChange={handleChange}
      showClear={false}
    />
  );
};
