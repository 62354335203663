import { ArrowRight } from 'lucide-react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ButtonGutter } from '@/Button';
import { Card, CardBody, CardFooter, CardPanel, CardPanels, CardSidePanel, ContextArea, PanelOverlayIcon } from '@/Card';
import { NumberInput } from '@/Form/NumberInput';
import { WithHoverCard } from '@/HoverCard';
import { HelpIcon, LabelIcon } from '@/Icon';
import { Input } from '@/Input';
import { Label } from '@/Label';
import { GenericPicker } from '@/Pickers/GenericPicker';
import { PredictionIndicator } from '@/Pickers/PredictionLabel';
import { CardSubtitle, CardTitle, MutedStrong } from '@/Text';
import { AcceptPrediction } from '~/Actions/Predictions/AcceptPrediction';
import { IgnorePrediction } from '~/Actions/Predictions/IgnorePrediction';
import { RerunPrediction } from '~/Actions/Predictions/RerunPrediction';
import { CommandActionButton } from '~/Components/Common/Command/CommandActionButton';
import { FieldPicker } from '~/Components/Fields/FieldPicker';
import { Prediction, SupportedField } from '~/types/types';

import { QualityEntryCard } from '../../Quality/QualityEntries/QualityEntryCard';

interface GenericPredictionCardProps {
  prediction: Prediction;
  supportedFields: SupportedField[];
}

/**
 * The ParseQualityEntryPrediction component is in charge of rendering the frontend to parse the quality entry prediction.
 *
 * @preconditions - Ensure that the prediction has type `parse_quality_comment` and targetable_type is `QualityEntry`
 *
 * @systems Used
 *
 * @param prediction
 * @param supportedFields
 * @constructor
 */
export const ParseQualityEntryPrediction = ({ prediction, supportedFields }: GenericPredictionCardProps) => {
  const { t } = useTranslation();

  const [payload, setPayload] = useState(prediction.raw_payload);

  const hasChanges = JSON.stringify(payload) !== JSON.stringify(prediction.raw_payload);

  return (
    <div>
      <CardPanels columns="1_2_2">
        <CardSidePanel>
          <MutedStrong>
            {t(prediction.type)}
          </MutedStrong>
        </CardSidePanel>
        <CardPanel>
          <div>
            <CardTitle className="text-sm">
              Data
            </CardTitle>
            <CardSubtitle className="text-xs">
              Based on the incoming comment from the following PO.
            </CardSubtitle>
          </div>
          <div className="mt-4">
            <QualityEntryCard qualityEntry={prediction.targetable}/>
          </div>
        </CardPanel>
        <CardPanel>
          <PanelOverlayIcon icon={ArrowRight}/>

          <div className="mb-4">
            <div>
              <CardTitle className="text-sm">
                Result
              </CardTitle>
              <CardSubtitle className="text-xs">
                Based on the incoming comment, we can convert it to the following quality entry.
              </CardSubtitle>
            </div>

            <div className="mt-2">
              <WithHoverCard flat renderContent={(
                <div className="max-w-lg">
                  <ContextArea>
                    {prediction.explanation}
                  </ContextArea>
                </div>
              )}>
                <Label>
                  <LabelIcon icon={HelpIcon}/>
                  Explained
                </Label>
              </WithHoverCard>
            </div>
          </div>

          <Card>
            <div className="grid grid-cols-[120px_3fr] rounded-tl border-b border-gray-100">
              <CardBody variant="muted" bordered="right" className="rounded-tl flex items-center !py-2">
                <MutedStrong>
                  Field
                </MutedStrong>

              </CardBody>
              <CardBody className="!py-2">
                <div className="inline-block relative z-10">
                  <FieldPicker
                    specFields={prediction?.order_produce?.spec?.linked_fields}
                    supportedFields={supportedFields}
                    value={{ name: payload.fieldName, type: 'measurement', base_type: 'unknown' }}
                    onChange={(field) => setPayload({ ...payload, fieldName: field?.name })}
                  />
                  <PredictionIndicator size="sm" className="z-200" confidence={payload.confidenceFieldName}/>
                </div>
              </CardBody>
            </div>
            <div className="grid grid-cols-[120px_1fr] rounded-tl border-b border-gray-100">
              <CardBody variant="muted" bordered="right" className="flex items-center !py-2">
                <MutedStrong>
                  Has defects
                </MutedStrong>
              </CardBody>
              <CardBody className="!py-2">
                <div className="inline-block relative">
                  <GenericPicker
                    options={[
                      { label: 'Has defects', value: 'risk' },
                      { label: 'No defect', value: 'N/A' },
                    ]}
                    title="No defect info"
                    value={payload.defectLevel}
                    onChange={(value) => setPayload({ ...payload, defectLevel: value })}
                  />
                  <PredictionIndicator size="sm" className="z-200" confidence={payload.confidenceDefectLevel}/>
                </div>
              </CardBody>
            </div>
            <div className="grid grid-cols-[120px_1fr] rounded-tl border-b border-gray-100">
              <CardBody variant="muted" bordered="right" className="flex items-center !py-2">
                <MutedStrong>
                  Score
                </MutedStrong>
              </CardBody>
              <CardBody className="!py-2">
                <div className="inline-block relative">
                  <GenericPicker
                    options={[
                      { label: 'Red', value: 'Red' },
                      { label: 'Amber', value: 'amber' },
                      { label: 'Green', value: 'green' },
                    ]}
                    title="No RAG score"
                    value={payload.score}
                    onChange={(value) => setPayload({ ...payload, score: value })}
                  />
                  <PredictionIndicator size="sm" className="z-200" confidence={payload.confidenceScore}/>
                </div>
              </CardBody>
            </div>

            {payload.confidenceValue > 0.6 && (
              <div className="grid grid-cols-[120px_1fr] rounded-tl border-b border-gray-100">
                <CardBody variant="muted" bordered="right" className="flex items-center !py-2">
                  <MutedStrong>
                    Number
                  </MutedStrong>
                </CardBody>
                <CardBody className="!py-2">
                  <div className="gap-2 grid grid-cols-[2fr,1fr]">
                    <div className="relative">
                      <NumberInput value={payload.numberValue}
                        onChange={(value) => setPayload({ ...payload, numberValue: value })}/>
                      <PredictionIndicator size="sm" className="z-200" confidence={payload.confidenceValue}/>
                    </div>
                    <div className="flex items-center relative">
                      <Input
                        type="text"
                        value={payload.unit}
                        onChange={(value) => setPayload({ ...payload, unit: value })}
                      />
                      <PredictionIndicator
                        size="sm"
                        className="z-200"
                        confidence={payload.confidenceUnit}
                      />
                    </div>
                  </div>
                </CardBody>
              </div>
            )}
          </Card>
        </CardPanel>
      </CardPanels>
      <CardFooter variant="darker">
        <div>
          <ButtonGutter variant="transparent">
            <div>
              <CommandActionButton
                action={AcceptPrediction}
                params={hasChanges ? payload : undefined}
                target={prediction}
                confirmation={{
                  onConfirm: () => hasChanges,
                  title: t('Accept prediction'),
                  description: t('Are you sure you want to accept this prediction?'),
                }}
              />
            </div>

            <div>
              <CommandActionButton
                action={RerunPrediction}
                target={prediction}
              />
            </div>
            <div>
              <CommandActionButton
                action={IgnorePrediction}
                target={prediction}
              />
            </div>
          </ButtonGutter>
        </div>
      </CardFooter>
    </div>
  );
};
