import { BoxIcon, WarehouseIcon } from 'lucide-react';
import { ComponentType } from 'react';

import { TransportLocationIcon } from '@/Icon';
import { parse, parseOrNull } from '~/hooks/useDate';
import { Order } from '~/types/types';

export interface TimeEdge {
  from: string;
  to: string;
  status: 'active' | 'pending' | 'completed' | 'problem';
  type?: 'solid' | 'optional';
}

export interface TimeNode {
  id: string;
  date?: Date | null;
  icon: ComponentType;
  label?: string;
}

export interface TimeGraph {
  nodes: TimeNode[];
  edges: TimeEdge[];
}

/**
 * We build a graph of the various dates an order could have associated with it.
 * @param order
 */
export const buildOrderGraph = (order: Order): TimeGraph => {
  const shipDate = parseOrNull(order.ship_date);
  const deliveryDate = parseOrNull(order.delivery_date);
  const delayDate = parseOrNull(order.delayed_delivery_date);

  console.log(delayDate);

  const nodes: TimeNode[] = [
    {
      id: 'shipped',
      date: shipDate,
      icon: TransportLocationIcon,
      label: 'Shipped',
    },
    {
      id: 'delivered',
      date: deliveryDate,
      icon: WarehouseIcon,
      label: 'Delivered',
    },
  ];

  const edges: TimeEdge[] = [];

  // Check if shipDate is not an invalid date and deliveryDate is not an invalid date

  if (shipDate && deliveryDate && !delayDate) {
    edges.push({ from: 'shipped', to: 'delivered', status: 'completed' });
  }

  if (shipDate && delayDate) {
    edges.push({ from: 'shipped', to: 'delivered', status: 'problem' });
  }

  return { nodes, edges };
};
