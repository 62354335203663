import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { PlaceholderBox } from '@/Fallback';
import { AIIcon } from '@/Icon';
import { Layout, LayoutBody, LayoutHeader } from '@/Layout';
import { buildAdminSubLinks } from '@/Nav/nav-items';
import { PageTitle } from '@/Text';
import Section from '~/Components/Common/Section';
import { PredictionCard } from '~/Components/ML/Predictions/PredictionCard';
import { PageProps, Prediction } from '~/types/types';

import { DashboardLayout } from '../DashboardLayout';

interface PredictionsPageOutput {
  predictions: Prediction[];
  supportedFields: string[];
}

export type NotificationsPageProps = PageProps & PredictionsPageOutput;

const PredictionsPage = ({ predictions, supportedFields }: NotificationsPageProps) => {
  const { t } = useTranslation();

  return (
    <Layout>
      <LayoutHeader>
        <PageTitle>
          {t('predictions')}
        </PageTitle>
      </LayoutHeader>
      <LayoutBody>
        {predictions.length === 0 && (
          <PlaceholderBox
            title={t('no_predictions')}
            description={t('no_predictions_description')}
            icon={<AIIcon/>}
          />
        )}

        {predictions.length > 0 && (
          <Section
            title="Purchase Order Predictions"
            description="Below are the predictions related to your purchase orders."
          >
            <div className="space-y-4">
              {predictions.length > 0 && (
                <div className="space-y-12">
                  {predictions.map((prediction, index) => (
                    <PredictionCard key={index} prediction={prediction} supportedFields={supportedFields}/>
                  ))}
                </div>
              )}
            </div>
          </Section>
        )}
      </LayoutBody>
    </Layout>
  );
};

PredictionsPage.layout = (page: ReactNode) => (
  <DashboardLayout subLinksBuilder={buildAdminSubLinks}>{page}</DashboardLayout>
);

export default PredictionsPage;

