import RAGScoreType = App.Domain.Specs.RAGScoreType;
import { Label } from '@/Label';

interface RAGScoreLabelProps {
  score: RAGScoreType;
}

export const RAGScoreLabel = ({ score }: RAGScoreLabelProps) => {
  if (score === 'red') {
    return (
      <Label theme="red" size="xs">
        Red
      </Label>
    );
  }

  if (score === 'amber') {
    return (
      <Label theme="orange" size="xs">
        Amber
      </Label>
    );
  }

  return (
    <Label theme="emerald" size="xs">
      Green
    </Label>
  );
};
