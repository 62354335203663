import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@/Button';
import { SpecFileIcon } from '@/Icon';
import { LabeledField } from '@/LabeledField';
import { ConstraintsLabel } from '@/Labels/ConstraintsLabel';
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalDescription,
  ModalFooter,
  ModalHeader,
  ModalIcon,
  ModalTitle,
} from '@/Modal';
import { SideLabeledField } from '@/SideLabeledField';
import { MutedText } from '@/Text';
import {
  Field,
  FieldConstraint,
  Spec,
} from '~/types/types';
import { preprocessConstraint } from '~/utils/constraints';

import { DateLabel } from '../Dates/DateLabel';
import { FieldIcon } from '../Fields/FieldIcon';

export interface ViewSpecModalProps {
  spec: Spec;
  isOpen: boolean;
  onClose: () => void;
  children?: ReactNode;
}

/**
 * ViewSpecModal
 */
export const ViewSpecModal = ({
  spec,
  isOpen,
  onClose,
}: ViewSpecModalProps) => {
  const { t } = useTranslation();

  const fields = spec.linked_fields as Field[] ?? [];

  return (
    <Modal open={isOpen} onOpenChange={() => onClose()}>
      <ModalContent className="max-w-3xl">
        <ModalHeader>
          <div className="flex items-center space-x-3">
            <ModalIcon icon={<SpecFileIcon className="h-7 w-7"/>}/>
            <div>
              <ModalTitle>{t('spec')}</ModalTitle>
              <ModalDescription>{spec.name}</ModalDescription>
            </div>
          </div>
        </ModalHeader>
        <ModalBody>
          <div className="space-y-4">
            <SideLabeledField
              label={t('details')}
              renderValue={
                <div className="space-y-2">
                  <LabeledField label={t('name')} value={spec.name}/>

                  {spec.temp_spec && (
                    <LabeledField
                      label={t('temp_spec')}
                      renderValue={
                        <DateLabel from={spec.date_from} to={spec.date_to}/>
                      }
                    />
                  )}
                </div>
              }
            />

            <hr/>

            <SideLabeledField
              label={t('fields')}
              renderValue={
                <div className="divide-y divide-gray-100">
                  {fields.map((field) => (
                    <div
                      key={field.id}
                      className="flex items-center justify-between py-2"
                    >
                      <div className="flex items-center">
                        <FieldIcon field={field}/>
                        <MutedText>
                          {t(field.translation_key_ref as string, {
                            defaultValue: field.name,
                          })}
                        </MutedText>
                      </div>

                      {field.constraints && (
                        <div>
                          {field.constraints.map((constraint) => (
                            <ConstraintsLabel fractionToPercentage key={constraint.id}
                              constraint={preprocessConstraint(constraint, field.type)} field={field}/>
                          ))}
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              }
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <Button disabled={true as any} variant="white" size="sm">
            {t('upload_temp_spec')}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
