import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { DebugJSON } from '@/JSON';
import { Layout, LayoutBody, LayoutHeader } from '@/Layout';
import { buildAdminSubLinks } from '@/Nav/nav-items';
import { PageTitle } from '@/Text';
import Section from '~/Components/Common/Section';
import { NotificationManifestCard } from '~/Components/Notifications/NotificationManifestCard';
import { NotificationManifest, PageProps } from '~/types/types';

import { DashboardLayout } from '../DashboardLayout';

interface NotifcationPageOutput {
  manifests: NotificationManifest[];
}

export type NotificationsPageProps = PageProps & NotifcationPageOutput;

const NotificationsPage = ({ manifests }: NotificationsPageProps) => {
  const { t } = useTranslation();

  return (
    <Layout>
      <LayoutHeader>
        <PageTitle>
          {t('notifications')}
        </PageTitle>
      </LayoutHeader>
      <LayoutBody>
        <Section
          title="Scheduled notifications"
          description="Below are the notifications that are scheduled to be sent out in the future."
        >
          <div className="space-y-4">
            {manifests.map(manifest => (
              <div key={manifest.type}>
                <NotificationManifestCard manifest={manifest}/>
              </div>
            ))}
          </div>
        </Section>
      </LayoutBody>
    </Layout>
  );
};

NotificationsPage.layout = (page: ReactNode) => (
  <DashboardLayout subLinksBuilder={buildAdminSubLinks}>{page}</DashboardLayout>
);

export default NotificationsPage;

