import { cloneElement, ReactElement, ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, CompoundButton, CompoundButtonItem, CompoundButtonSibling } from '@/Button';
import { FormGrid } from '@/Form';
import { SimpleIcon } from '@/Icon';
import { LoadingLabel } from '@/Label';
import { LabeledField } from '@/LabeledField';
import {
  Modal, ModalBody,
  ModalContent,
  ModalDescription,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  ModalTrigger,
} from '@/Modal';
import { BaseAction } from '~/Actions/BaseAction';
import { useConfirmationModal } from '~/Components/Common/Modals/useConfirmationModal';
import { useCommandAction } from '~/hooks/useCommandAction';

export interface AttributeConfig {
  component: ReactElement;
  fieldKey: string;
  label?: string | null;
  description?: string | null;
  group?: string;
  // Function to extract the value from the onChange event; optional if the onChange directly returns the value
  valueAccessor?: (eventOrValue: any) => any;
  disabled?: (values: Record<string, any>) => boolean;

}

interface CommandActionModalProps {
  action: typeof BaseAction;
  target: any;
  defaultValues: Record<string, any>;
  title?: string;
  attributes: AttributeConfig[];
  children?: ReactNode;
  onSuccess?: () => void;
  container?: HTMLElement;
  confirmation?: {
    onConfirm: (values: Record<string, any>) => boolean;
    title: string;
    description: string;
    confirmText: string;
    cancelText: string;
    renderConfirmation?: (values: Record<string, any>) => ReactNode;
  }
}

/**
 * CommandActionModal
 *
 * This component constructs a Modal which renders a number of attributes as fields, and executes the given action.
 *
 * Works with any type of BaseAction.
 * - Works with button: If no `children` are provided, a CompoundButton is rendered with the action name, an associated icon and a disabled state based on the action's `disabled` method (and whether the action is loading).
 *
 * When to use it:
 * - ✅ When we are dealing with general modals / forms that just need to edit some attributes and execute a backend action.
 * - 🔴 So not: when very complex multistep forms are needed.
 *
 * @param action
 * @param target
 * @param title
 * @param attributes
 * @param children
 * @param onSuccess
 * @param defaultValues
 * @param container
 * @param confirmation
 * @constructor
 */
export const CommandActionModal = ({
  action,
  target,
  title,
  attributes,
  children,
  onSuccess,
  defaultValues,
  container,
  confirmation,
}: CommandActionModalProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();
  const [commandInstance, { loading }] = useCommandAction(action, target);
  const [values, setValues] = useState<Record<string, any>>(defaultValues);

  const { confirm, ConfirmationModal } = useConfirmationModal({
    title: confirmation?.title || 'Are you sure?',
    description: confirmation?.description || 'This action cannot be undone.',
    confirmText: confirmation?.confirmText || 'Confirm',
    cancelText: confirmation?.cancelText || 'Cancel',
  });

  const handleAttributeChange = (
    fieldKey: string,
    value: any,
    valueAccessor?: (eventOrValue: any) => any
  ) => {
    const actualValue = valueAccessor ? valueAccessor(value) : value;

    setValues((prev) => ({
      ...prev,
      [fieldKey]: actualValue,
    }));
  };

  const onSubmit = async () => {
    if (confirmation?.onConfirm && confirmation.onConfirm(values)) {
      confirm(async () => {
        await commandInstance.execute(values);
        setIsOpen(false);
        onSuccess?.();
      });
    } else {
      await commandInstance.execute(values);
      setIsOpen(false);
      onSuccess?.();
    }
  };

  const groupedAttributes = attributes.reduce((acc, attribute) => {
    const group = attribute.group || 'default';
    if (!acc[group]) {
      acc[group] = [];
    }
    acc[group].push(attribute);
    return acc;
  }, {} as Record<string, AttributeConfig[]>);

  return (
    <Modal open={isOpen} onOpenChange={setIsOpen}>
      <ModalTrigger asChild>
        {!children ? (
          <CompoundButton
            aria-disabled={commandInstance.disabled() || loading}
            aria-haspopup="dialog"
            onClick={(e) => {
              if (commandInstance.disabled() || loading) {
                e.preventDefault();
                return;
              }
            }}
          >
            {action.icon && (
              <CompoundButtonSibling variant="muted">
                <SimpleIcon icon={action.icon}/>
                {/*{cloneElement(action.icon(), { size: 20 })}*/}
              </CompoundButtonSibling>
            )}
            <CompoundButtonItem disabled={commandInstance.disabled() || loading}>
              {t(commandInstance.nameKey())}
            </CompoundButtonItem>
          </CompoundButton>
        ) : (
          <>
            {children}
          </>
        )}
      </ModalTrigger>
      <ModalContent className="flex flex-col max-w-2xl" container={container}>
        <ConfirmationModal/>
        <ModalHeader>
          <div className="flex items-center space-x-3">
            <div>
              <ModalTitle>
                {title || t(commandInstance.nameKey())}
              </ModalTitle>
              <ModalDescription>
                {t(`${commandInstance.nameKey()}_description`)}
              </ModalDescription>
            </div>
          </div>
        </ModalHeader>

        <ModalBody>
          <FormGrid>
            {Object.keys(groupedAttributes).map((group, groupIndex) => (
              <div key={groupIndex} className="flex flex-col space-y-4">
                {groupedAttributes[group].map((attribute, index) => (
                  <div key={index} className="flex flex-col">
                    <LabeledField
                      label={attribute.label ?? ''}
                      subLabel={attribute.description ?? undefined}
                      renderValue={
                        <>
                          {cloneElement(attribute.component, {
                            value: values[attribute.fieldKey],
                            onChange: (value: any) =>
                              handleAttributeChange(
                                attribute.fieldKey,
                                value,
                                attribute.valueAccessor
                              ),
                            disabled:
                              (attribute.disabled?.(values) ?? false) ||
                              commandInstance.disabled(),

                          })}

                        </>
                      }
                    />
                  </div>
                ))}
                {groupIndex < Object.keys(groupedAttributes).length - 1 && <hr/>}
              </div>
            ))}
          </FormGrid>
        </ModalBody>

        <ModalFooter>
          <Button
            variant="ghost"
            onClick={() => setIsOpen(false)}
          >
            {t('cancel')}
          </Button>
          <div className="flex items-center space-x-2">
            <Button
              onClick={onSubmit}
              disabled={(commandInstance.disabled() || loading) as any}
            >
              {t('save')}
            </Button>
            <LoadingLabel isLoading={loading}>
              {t('loading')}
            </LoadingLabel>
          </div>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
