import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@/Button';
import { LayoutBody, LayoutHeader } from '@/Layout';
import { PageDescription, PageTitle } from '@/Text';
import { InviteSellerUser } from '~/Actions/Sellers/Settings/InviteSellerUser';
import { EditUser } from '~/Actions/Users/EditUser';
import { PageProps } from '~/types/types';

import { InviteUserModal } from '../../Dashboard/Settings/Organization/Components/InviteUserModal';
import { UsersTable } from '../../Dashboard/Settings/Organization/Components/UsersTable';
import { SellerSettingsLayout } from './SellerSettingsLayout';
import SellerUsersSettingsPageOutput = App.Http.Controllers.Seller.Settings.SellerUsersSettingsPageOutput;
import { MappingIcon } from '@/Icon';
import { CreateResourceMap } from '~/Actions/Common/CreateResourceMap';
import { CommandAttributePicker } from '~/Components/Common/Command/CommandAttributePicker';
import Section from '~/Components/Common/Section';

export type SellerUsersSettingsPageProps = PageProps & SellerUsersSettingsPageOutput;

const SellerUsersSettingsPage = ({ organization, user, ...props }: SellerUsersSettingsPageProps) => {
  const { t } = useTranslation();

  const mapsTo = organization?.maps_to?.[0] ?? null;

  return (
    <div>
      <LayoutHeader isSticky>
        <div className="flex justify-between items-center gap-4">
          <div>
            <PageTitle>
              {t('users')}
            </PageTitle>
            <PageDescription>
              {t('manage_your_users')}
            </PageDescription>
          </div>

          <div>
            <InviteUserModal organization={organization} action={InviteSellerUser}>
              <Button>
                {t('invite_user')}
              </Button>
            </InviteUserModal>
          </div>
        </div>
      </LayoutHeader>
      <LayoutBody>
        <div>
          <UsersTable
            actions={{ editUser: EditUser, inviteUser: InviteSellerUser }}
            organization={organization}
            usersConnection={props.users}
            availableGroups={props.userGroups}
          />
        </div>

        <hr/>

        <div className="mt-12">
          {user.superadmin && (
            <Section title="Mapping"
              description="Map this organization to another organization. That means that any PO's with this seller marked will be sent to this mapped organization."
              icon={MappingIcon}>
              <div>
                <CommandAttributePicker
                  action={CreateResourceMap}
                  target={mapsTo}
                  attribute="toable_id"
                  additionalData={{ fromId: organization.id, type: 'organization' }}
                  title="Map to organization"
                  only="resources"
                  optionsKey="resources.organizations"
                  confirmation={{
                    onConfirm: () => true,
                    title: 'Mapping organization',
                    description: 'This action will map the organization to the selected resource',
                  }}
                />
              </div>
            </Section>
          )}
        </div>
      </LayoutBody>
    </div>
  );
};

SellerUsersSettingsPage.layout = (page: ReactNode) => (
  <SellerSettingsLayout>{page}</SellerSettingsLayout>
);

export default SellerUsersSettingsPage;
