import { cloneElement, ReactElement, ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@/Button';
import { LoadingLabel } from '@/Label';
import { LabeledField } from '@/LabeledField';
import { Pane, PaneContent, PaneFooter, PaneHeader, PanePortal, PaneTitle, PaneTrigger } from '@/Pane';

export interface FilterConfig {
  component: ReactElement;
  fieldKey: string;
  label: string;
  description?: string;
  group?: string;
}

interface GenericFilterPaneProps {
  defaultValues?: Record<string, any>;
  title?: string;
  onChange?: (values: Record<string, any>) => void;
  onClear?: () => void;
  isDefaultOpen?: boolean;
  filters: FilterConfig[];
  container?: HTMLElement;
  children?: ReactNode;
  usesStoreFilter?: boolean;
  isLoading?: boolean;
  allowStoreFilter?: boolean;
}

/**
 * The GenericFilterPane is a Pane which renders a list of input/description/filters,
 * sets them on a generic state object, and on save applies an action (and closes the Pane).
 *
 * How to define a filter-config:
 * - Each filter is defined by a `FilterConfig` object. This contains a `label` and `description` for actionability
 * and `component`, which is the actual input component. This is currently relatively controlled, as it is expected
 * to receive a `value` and `onChange`. The `value` is the stored value of the filter (which is stored in the "GenericFilterPane")
 * and the `onChange` is a handler which is supposed to return the output of the independent inputs. These will be
 * stored under `value[fieldKey]`.
 *
 * How to use the GenericFilterPane:
 * - The GenericFilterPane can receive `defaultValues` which is the initial state of the filters (should correspond to each individual filter).
 * - The GenericFilterPane can receive a `title` which is the title of the Pane.
 * - Once the filters are set, the `onChange` handler is called with the values of the filters. It will also close the Pane.
 */
export const GenericFilterPane = ({
  defaultValues = {},
  title,
  children,
  filters,
  isDefaultOpen,
  container,
  onChange,
  onClear,
  allowStoreFilter = true,
  usesStoreFilter = false,
  isLoading = false,
}: GenericFilterPaneProps) => {
  const [isOpen, setIsOpen] = useState(isDefaultOpen);

  const { t } = useTranslation();

  const [filterValues, setFilterValues] = useState<Record<string, any>>({
    storeFilter: usesStoreFilter ? defaultValues['storeFilter'] : 0,
    ...defaultValues,
  });

  const onSubmit = () => {
    onChange?.({
      ...filterValues,
    });
    setIsOpen(false);
  };

  const handleFilterChange = (fieldKey: string, value: any) => {
    setFilterValues((prevValues) => ({
      ...prevValues,
      [fieldKey]: value,
    }));
  };

  const handleClear = () => {
    onClear?.();
    setFilterValues({});
    setIsOpen(false);
  };

  const groupedFilters = filters.reduce((acc, filter) => {
    const group = filter.group || 'default';
    if (!acc[group]) {
      acc[group] = [];
    }
    acc[group].push(filter);
    return acc;
  }, {} as Record<string, FilterConfig[]>);

  return (
    <div>
      <Pane open={isOpen} onOpenChange={setIsOpen}>
        {!!children && (
          <PaneTrigger asChild>{children}</PaneTrigger>
        )}
        <PanePortal container={container}>
          <PaneContent aria-label={`${title} side pane`} className="flex flex-col gap-4 p-4 overflow-scroll">
            <PaneHeader className="flex flex-row items-center space-y-0">
              <PaneTitle className="py-4">
                {title ? (
                  title
                ) : (
                  t('filters')
                )}
              </PaneTitle>
            </PaneHeader>
            <div className="flex flex-col space-y-4">
              {Object.keys(groupedFilters).map((group, groupIndex) => (
                <div key={groupIndex} className="flex flex-col space-y-4">
                  {groupedFilters[group].map((filter, index) => (
                    <div key={index} className="flex flex-col">
                      <LabeledField
                        label={filter.label}
                        subLabel={filter.description}
                        renderValue={
                          <>
                            {cloneElement(filter.component, {
                              value: filterValues[filter.fieldKey],
                              onChange: (value: any) => handleFilterChange(filter.fieldKey, value),
                            })}
                          </>
                        }
                      />
                    </div>
                  ))}
                  {groupIndex < Object.keys(groupedFilters).length - 1 && <hr/>}
                </div>
              ))}
            </div>
            <PaneFooter>
              <div className="flex flex-col space-y-2">
                {allowStoreFilter && (
                  <label className="flex items-center space-x-2">
                    <div className="relative flex items-start">
                      <div className="flex h-6 items-center">
                        <input
                          id="comments"
                          name="comments"
                          type="checkbox"
                          checked={filterValues['storeFilter'] || false}
                          onChange={() => handleFilterChange('storeFilter', !filterValues['storeFilter'])}
                          aria-describedby="comments-description"
                          className="peer h-4 w-4 shrink-0 rounded-sm border border-primary ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 data-[state=checked]:bg-primary data-[state=checked]:text-primary-foreground"
                        />
                      </div>
                      <div className="ml-3 text-sm leading-6">
                        <label htmlFor="comments" className="font-medium text-gray-900">
                          {t('save_settings')}
                        </label>{' '}
                        <span id="comments-description" className="text-gray-500">
                        <span className="sr-only">{t('save_settings')}</span>{t('save_settings_description')}
                      </span>
                      </div>
                    </div>
                  </label>
                )}
                <div className="flex space-x-2 items-center">
                  <Button onClick={onSubmit}>
                    {t('apply')}
                  </Button>
                  <Button variant="outline" onClick={handleClear}>
                    {t('clear_filters')}
                  </Button>
                  <div className="ml-8">
                    <LoadingLabel isLoading={isLoading}>
                      {t('loading')}
                    </LoadingLabel>
                  </div>
                </div>
              </div>
            </PaneFooter>
          </PaneContent>
        </PanePortal>
      </Pane>
    </div>
  );
};

