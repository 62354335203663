import { router } from '@inertiajs/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Cell, Scatter, ScatterChart, XAxis, YAxis, ZAxis } from 'recharts';

import { ChartConfig, ChartContainer, ChartTooltip, ChartTooltipContent, TooltipDetail } from '@/Chart';
import { IssueIcon, NavigateToIcon, QualityIcon, TooltipFooterIcon } from '@/Icon';
import { Strong } from '@/Text';
import { Databars } from '~/Components/Analytics/Databars';
import { useRoutes } from '~/hooks/useRoutes';
import { cn } from '~/utils/cn';
import { fillThemeClasses, Theme } from '~/utils/colors';

interface ScatterCorrelationsData {
  xType: string;
  yType: string;
  items: Array<{
    x: number;
    y: number;
    label: string;
    z?: number;
    theme?: Theme;
    color?: string;
    extra: {
      ref: string;
      red: number;
      amber: number;
      green: number
    }
  }>;
}

interface ScatterCorrelationsProps {
  data: ScatterCorrelationsData;
  formatValue?: (value: number) => string;
  color?: string;
}

/**
 * The ScatterCorrelations component visualizes correlations between two metrics
 *
 * @abstraction: L2 Component (using underlying L1 Chart components)
 *
 * @param data - ScatterCorrelationsData containing xType, yType and correlation points
 * @param formatValue - Function to format numeric values
 * @param color - Default color for points (can be overridden by individual point colors)
 */
export const ScatterCorrelations = ({
  data,
  formatValue,
  color,
}: ScatterCorrelationsProps) => {
  const { order } = useRoutes();
  const chartConfig = {
    value: {
      label: data.xType,
      color,
      formatValue,
    },
  } satisfies ChartConfig;
  const { t } = useTranslation();

  const handleCircleClick = (data: any) => {
    if (data.label) {
      router.visit(order(data.label));
    }
  };

  return (
    <div className="min-h-[200px]">
      <ChartContainer config={chartConfig}>
        <ScatterChart
          margin={{ top: 20, right: 20, bottom: 50, left: 60 }} // Support margins for labels
        >
          <XAxis
            type="number"
            dataKey="x"
            name={data.xType}
            label={{
              value: data.xType,
              position: 'bottom',
              offset: 20, // Distance from axis
            }}
            tickFormatter={(value) => formatValue ? formatValue(value) : value}
          />
          <YAxis
            type="number"
            dataKey="y"
            name={data.yType}
            label={{
              value: data.yType,
              angle: -90,
              position: 'left',
              offset: 40, // Distance from axis
            }}
          />
          {/* Only render ZAxis if z values are present */}
          {data.items.some(item => item.z !== undefined) && (
            <ZAxis
              type="number"
              dataKey="z"
              range={[50, 400]}
              name="Score"
            />
          )}
          <ChartTooltip
            content={
              <ChartTooltipContent
                renderContent={(data) => (
                  <div>
                    <div>
                      <Strong className="text-xs">
                        {data[0].payload.extra.ref}
                      </Strong>
                    </div>

                    <div className="py-1">
                      <TooltipDetail icon={IssueIcon} label={t('issues')} value={data[0].payload.x}/>
                      <TooltipDetail
                        icon={QualityIcon}
                        label={t('rag')}
                        renderValue={(
                          <div className="w-full">
                            <Databars
                              data={[
                                {
                                  value: data[0].payload.extra.red,
                                  label: 'red',
                                  theme: 'red',
                                },
                                {
                                  value: data[0].payload.extra.amber,
                                  label: 'amber',
                                  theme: 'orange',
                                },
                                {
                                  value: data[0].payload.extra.green,
                                  label: 'green',
                                  theme: 'emerald',
                                },
                              ]}/>
                            {/*{data[0].payload.extra.red}*/}
                          </div>
                        )}
                      />
                    </div>
                  </div>
                )}
                renderFooter={(
                  <>
                    <TooltipFooterIcon icon={NavigateToIcon}/>
                    {t('analytics.click_to_go_to')}
                  </>
                )}
              />
            }
          />
          <Scatter
            data={data.items}
            name={data.xType} // Add this to show in tooltip
          >
            {data.items.map((item, index) => (
              <Cell
                key={index}
                aria-label={`scatterplot point for order ${item.extra.ref} with overall score ${item.z} (Red: ${item.extra.red}, Amber: ${item.extra.amber}, Green: ${item.extra.green})`}
                className={cn(item.theme ? fillThemeClasses[item.theme] : (item.color || color), 'hover:stroke-[3] cursor-pointer')}
                onClick={() => handleCircleClick(item)}
                name={item.label} // Add this to make label available to tooltip
              />
            ))}
          </Scatter>
        </ScatterChart>
      </ChartContainer>
    </div>
  );
};
