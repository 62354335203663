import { motion } from 'framer-motion';
import { useMemo } from 'react';

import { SparkChartData } from '~/types/types';

interface SparkChartProps {
  data: SparkChartData;
  height?: number;
  width?: number;
  variant?: 'line' | 'bar';
  showDots?: boolean;
  className?: string;
}

export const SparkChart = ({
  data,
  height = 50,
  width = 200,
  variant = 'line',
  showDots = true,
  className,
}: SparkChartProps) => {
  const normalizedValues = useMemo(() => {
    const values = data.values;
    const max = Math.max(...values);
    const min = Math.min(...values);
    const range = max - min;

    return values.map((value) =>
      range === 0 ? 0.5 : (value - min) / range
    );
  }, [data.values]);

  const renderLineChart = () => {
    const points = normalizedValues.map((value, i) =>
      `${(i / (normalizedValues.length - 1)) * width},${height - (value * height)}`
    ).join(' ');

    // Create area path by adding bottom corners
    const areaPath = `${points} ${width},${height} 0,${height}`;

    // Create unique gradient ID
    const gradientId = `gradient-${data.type}-${data.color}`;

    console.log(gradientId);

    return (
      <g>
        <defs>
          <linearGradient
            id={gradientId}
            x1="0"
            y1="0"
            x2="0"
            y2="1"
          >
            <stop
              offset="0%"
              stopColor={data.color}
              stopOpacity="0.2"
            />
            <stop
              offset="100%"
              stopColor={data.color}
              stopOpacity="0"
            />
          </linearGradient>
        </defs>

        {/* Gradient area */}
        <motion.path
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
          d={`M ${areaPath} Z`}
          fill={`url(#${gradientId})`}
        />

        {/* Line */}
        <motion.polyline
          initial={{ pathLength: 0 }}
          animate={{ pathLength: 1 }}
          transition={{
            duration: 1,
            ease: 'easeInOut',
          }}
          points={points}
          fill="none"
          stroke={data.color}
          strokeWidth={2}
        />

        {/* Dots */}
        {showDots && normalizedValues.map((value, i) => (
          <motion.circle
            key={`dot-${i}`}
            initial={{ scale: 0 }}
            animate={{ scale: 1 }}
            transition={{
              delay: i * 0.1,
              type: 'spring',
              stiffness: 300,
              damping: 20,
            }}
            cx={(i / (normalizedValues.length - 1)) * width}
            cy={height - (value * height)}
            r={3}
            fill={data.color}
          />
        ))}
      </g>
    );
  };

  const renderBarChart = () => {
    const barWidth = width / normalizedValues.length - 2;
    const gradientId = `gradient-${data.type}-${data.color}`;

    return (
      <g>
        <defs>
          <linearGradient
            id={gradientId}
            x1="0"
            y1="0"
            x2="0"
            y2="1"
          >
            <stop
              offset="0%"
              stopColor={data.color}
              stopOpacity="1"
            />
            <stop
              offset="100%"
              stopColor={data.color}
              stopOpacity="0.4"
            />
          </linearGradient>
        </defs>
        {normalizedValues.map((value, i) => (
          <motion.rect
            key={`bar-${i}`}
            initial={{ scaleY: 0 }}
            animate={{ scaleY: 1 }}
            transition={{
              delay: i * 0.05,
              type: 'spring',
              stiffness: 300,
              damping: 20,
            }}
            x={i * (width / normalizedValues.length)}
            y={height - (value * height)}
            width={barWidth}
            height={value * height}
            fill={`url(#${gradientId})`}
            rx={2}
            style={{ transformOrigin: 'bottom' }}
          />
        ))}
      </g>
    );
  };

  return (
    <div className={className}>
      <svg
        width={width}
        height={height}
        viewBox={`0 0 ${width} ${height}`}
        preserveAspectRatio="none"
        className="overflow-visible"
      >
        {variant === 'line' ? renderLineChart() : renderBarChart()}
      </svg>
    </div>
  );
};

export default SparkChart;
