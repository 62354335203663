import {Area, ComposedChart, Line, ReferenceLine, ResponsiveContainer, Tooltip, XAxis, YAxis} from 'recharts';

import { Icon, ThermometerIcon, UmbrellaIcon } from '@/Icon';
import { Helper } from '@/Text';
import { DateFormat, useDate } from '~/hooks/useDate';
import { WeatherDataPayload } from '~/types/types';

import { RainTooltip } from './RainChart';
import { WeatherTemperatureTooltip } from './WeatherTemperatureChart';

export interface WeatherChartProps {
  data: WeatherDataPayload;
  lowThresholdY?: number | null;
  upperThresholdY?: number | null;
  issues: any[];
}

interface AnomalyDotProps {
  cx: number;
  cy: number;
  r: number;
  stroke: string;
  payload: any;
  value: number;
  thresholdY?: number;
  lowerThresholdY?: number;
}

export const WeatherChart = ({ issues, data, lowThresholdY, upperThresholdY }: WeatherChartProps) => {
  const { format, parse } = useDate();

  const openIssues = issues ?? [];

  const tempData = data.events.map((dataPoint: any) => {
    const issue = openIssues.find((issue: any) => {
      const issueStartDate = new Date(issue.issueable.data.startDate).getTime();
      const issueEndDate = issue.issueable.data.endDate ? new Date(issue.issueable.data.endDate).getTime() : null;
      // TODO: https://linear.app/agstacked/issue/AGS-709/add-seconds-timezone-when-pushing-data
      const eventDate = new Date(`${dataPoint.date}:00+00:00`).getTime();

      if (issueEndDate) {
        return eventDate >= issueStartDate && eventDate <= issueEndDate;
      } else {
        return eventDate === issueStartDate;
      }
    });

    return {
      ...dataPoint,
      anomalyValue: dataPoint.avgCelcius,
      status: issue ? issue.status : '',
    };
  });

  return (
    <div className="flex flex-col gap-4">
      <div className="px-6 py-4 border-t border-b border-gray-200 bg-gray-50">
        <div className="flex items-center justify-between">
          <div className="flex items-end space-x-2">
            <Icon>
              <ThermometerIcon/>
            </Icon>
            <Helper>
              Temperature
            </Helper>
          </div>
        </div>
      </div>
      <div className="h-[350px]">
        <div
          style={{ position: 'relative', width: '100%', paddingBottom: '350px' }}
        >
          <div
            style={{
              position: 'absolute',
              left: 0,
              right: 0,
              bottom: 0,
              top: 0,
            }}
          >
            <ResponsiveContainer width="100%" height="100%">
              <ComposedChart
                className="text-sm"
                width={500}
                height={300}

                syncId="test-weather-chart"
                data={tempData}
                margin={{
                  top: 20,
                  right: 50,
                  left: 20,
                  bottom: 0,
                }}
              >
                <XAxis
                  tickFormatter={val => format(
                    parse(val, DateFormat.ISO),
                    DateFormat.HumanMonthDay
                  )}
                  dataKey="date"
                  className="fill-gray-700"
                  label={{ fill: 'blue' }}
                />
                <YAxis
                  tickFormatter={val => `${val.toFixed(2)}°C`}
                  className="fill-gray-700"
                  domain={[lowThresholdY ? lowThresholdY - 5 : 0, upperThresholdY ? upperThresholdY + 10 : 30]}
                />
                <Tooltip content={(props) => <WeatherTemperatureTooltip {...props as any} />}/>
                <Area type="monotone" dataKey="minCelcius" fill="#8884d8" stroke="#8884d8"/>
                {lowThresholdY && (
                  <ReferenceLine
                  type="monotone"
                  y={lowThresholdY}
                  label="Min"
                  className="stroke-rose-400"
                  strokeWidth={2}
                  strokeDasharray="3 3"
                />
                )}

                {upperThresholdY && (
                  <ReferenceLine
                  type="monotone"
                  y={upperThresholdY}
                  label="Max"
                  className="stroke-rose-400"
                  strokeWidth={2}
                  strokeDasharray="3 3"
                />
                )}

                {/*<Line type="monotone" dataKey="avgCelcius" className="stroke-2 stroke-blue-500" strokeWidth={2}/>*/}

                {upperThresholdY !== null && upperThresholdY !== undefined && (
                  <Line
                    type="monotone"
                    dataKey="anomalyValue"
                    strokeWidth={2}
                    dot={(props) => <CustomizedDot {...props} />}
                  />
                )}
              </ComposedChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div>
      <div>
        <div className="px-6 py-4 border-t border-b border-gray-200 bg-gray-50">
          <div className="flex items-center justify-between">
            <div className="flex items-end space-x-2">
              <Icon>
                <UmbrellaIcon/>
              </Icon>
              <Helper>
                Rainfall
              </Helper>
            </div>
          </div>
        </div>
        <div className="h-[200px]">
          <div
            style={{ position: 'relative', width: '100%', paddingBottom: '200px' }}
          >
            <div
              style={{
                position: 'absolute',
                left: 0,
                right: 0,
                bottom: 0,
                top: 0,
              }}
            >
              <ResponsiveContainer width="100%" height="100%">
                <ComposedChart
                  className="text-sm"
                  width={500}
                  height={300}
                  syncId="test-weather-chart"
                  data={data.events}
                  margin={{
                    top: 20,
                    right: 50,
                    left: 20,
                    bottom: 24,
                  }}
                >
                  <XAxis
                    tickFormatter={val => format(
                      parse(val, DateFormat.ISO),
                      DateFormat.HumanMonthDay
                    )}
                    dataKey="date"
                    className="fill-gray-700"
                  />
                  <YAxis
                    tickFormatter={val => `${val.toFixed(2)}mm`}
                    className="fill-gray-700"
                    // domain={[0, 9]}
                  />
                  {/*<ReferenceLine*/}
                  {/*  y={1.5}*/}
                  {/*  label="Min"*/}
                  {/*  className="stroke-rose-400"*/}
                  {/*  strokeWidth={2}*/}
                  {/*  strokeDasharray="3 3"*/}
                  {/*/>*/}
                  <defs>
                    <linearGradient id="redGradient" x1="0" y1="0" x2="0" y2="1">
                      <stop offset="50%" stopColor="rgba(255, 99, 71, 0.8)"/>
                      <stop offset="100%" stopColor="rgba(255, 99, 71, 0)"/>
                    </linearGradient>
                    <linearGradient id="greenGradient" x1="0" y1="0" x2="0" y2="1">
                      <stop offset="0%" stopColor="rgba(130, 202, 157, 0.8)"/>
                      <stop offset="100%" stopColor="rgba(130, 202, 157, 0)"/>
                    </linearGradient>
                  </defs>
                  <Tooltip content={(props) => <RainTooltip {...props as any} />}/>
                  <Area
                    type="monotone"
                    dataKey="rainfall"
                    stroke={(_) => '#82ca9d'}
                    strokeWidth={2}
                    fillOpacity={1}
                    fill="url(#redGradient)"
                  />
                  {/*<Area*/}
                  {/*  type="monotone"*/}
                  {/*  dataKey="rainfall"*/}
                  {/*  stroke="none"*/}
                  {/*  fillOpacity={1}*/}
                  {/*  fill="url(#greenGradient)"*/}
                  {/*  baseValue={1.5}*/}
                  {/*  isAnimationActive={false}*/}
                  {/*/>*/}
                </ComposedChart>
              </ResponsiveContainer>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const CustomizedDot = ({ cx, cy, payload }: AnomalyDotProps) => {

  if (payload.status === 'open') {
    // Check that the point is related to an open issue...
    return (
      <svg x={cx - 10} y={cy - 10} width={20} height={30} fill="white"
           viewBox="0 0 1024 1024"
           className="text-rose-500 fill-current drop-shadow-md"
      >
        <circle cx="512" cy="256" r="192" className="fill-yellow-500 stroke-white stroke-2"/>
      </svg>
    );
  }

  if (payload.status === 'accepted') {
    // Check that the point is related to an open issue...
    return (
      <svg x={cx - 10} y={cy - 10} width={20} height={30} fill="white"
           viewBox="0 0 1024 1024"
           className="text-rose-500 fill-current drop-shadow-md"
      >
        {/* Red dot */}
        <circle cx="512" cy="256" r="192" className="fill-orange-500 stroke-white stroke-2"/>
      </svg>
    );
  }

  if (payload.status === 'escalated') {
    // Check that the point is related to an open issue...
    return (
      <svg x={cx - 10} y={cy - 10} width={20} height={30} fill="white"
           viewBox="0 0 1024 1024"
           className="text-rose-500 fill-current drop-shadow-md"
      >
        {/* Red dot */}
        <circle cx="512" cy="256" r="192" className="fill-rose-500 stroke-white stroke-2"/>
      </svg>
    );
  }

  if (payload.status === 'read') {
    // Check that the point is related to an open issue...
    return (
      <svg x={cx - 10} y={cy - 10} width={20} height={30} fill="white"
           viewBox="0 0 1024 1024"
           className="text-rose-500 fill-current drop-shadow-md"
      >
        {/* Red dot */}
        <circle cx="512" cy="256" r="192" className="fill-emerald-500 stroke-white stroke-2"/>
      </svg>
    );
  }

  return null;
};
