import { MailOpen } from 'lucide-react';
import { useTranslation } from 'react-i18next';

import { Card, CardBody, CardFooter } from '@/Card';
import { PlaceholderBox } from '@/Fallback';
import { SimpleIcon, UserIcon } from '@/Icon';
import { DescriptionText, Helper, MutedText, Strong } from '@/Text';
import { useDate } from '~/hooks/useDate';
import { NotificationManifest } from '~/types/types';
import { FormatStringList } from '~/utils/FormatStringList';

interface PreviewNotificationManifestProps {
  manifest: NotificationManifest;
}

export const PreviewNotificationManifest = ({ manifest }: PreviewNotificationManifestProps) => {
  const { t } = useTranslation();
  const { parse, differenceToNow } = useDate();
  const parsedDate = parse(manifest.nextDelivery);

  const when = parsedDate ? differenceToNow(parsedDate) : null;

  if (!manifest.recipientsCount) {
    return (
      <PlaceholderBox
        title="No recipients available"
        description="No users are available to receive this notification."
        icon={<UserIcon/>}
      />
    );
  }

  if (!manifest.recipients) {
    return (
      <Card>
        <CardBody>
          <Strong>
            {t('n_recipients_receive_mail', { count: manifest.recipientsCount })}
          </Strong>
          <br/>

          <Helper>
            {when}
          </Helper>
        </CardBody>
      </Card>
    );
  }

  const recipients = manifest.recipients.map(recipient => recipient.name);

  return (
    <Card>
      <CardBody>
        <Strong>
          <FormatStringList strings={recipients}/>
        </Strong>
        <DescriptionText>
          will receive this notification.
        </DescriptionText>
      </CardBody>
      <CardFooter>
        <div className="mr-1 inline-block">
          <SimpleIcon icon={MailOpen}/>
        </div>
        <MutedText>
          {when}
        </MutedText>
      </CardFooter>
    </Card>
  );
};
