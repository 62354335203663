import { ArrowPathIcon } from '@heroicons/react/20/solid';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@/Button';
import { Card, CardBody, CardFooter, CardGutter, CardHeader } from '@/Card';
import { WithGenericFallback } from '@/Fallback';
import { Icon, InspectionsIcon } from '@/Icon';
import { CardSubtitle, CardTitle, Helper, Text } from '@/Text';
import { Databars } from '~/Components/Analytics/Databars';
import { useAction } from '~/hooks/useAction';
import { DateFormat, useDate } from '~/hooks/useDate';
import {
  Order,
  OrderFieldSummary as OrderFieldSummaryType,
  OrderProduce,
  OrderStatus,
  OrderStepType,
  Spec,
} from '~/types/types';

import { useOrder } from '../../../pages/Dashboard/Order/OrderProvider';
import { OrderFieldSummary } from '../Quality/FieldSummaries/OrderFieldSummary';
import { SpecLabel } from '../Spec/SpecLabel';
import { AddQualityInputModal } from './QualityInput/AddQualityInputModal';

interface InspectionsCardProps {
  order: Order;
  orderProduce: OrderProduce;
  orderFieldSummaries: OrderFieldSummaryType[];
  filteredStepTypes?: OrderStepType[];
  activeSpec?: Spec | null;
  isSeller?: boolean;
  activeFieldId?: string;
}

interface AddQualityInputButtonProps {
  order: Order;
  activeSpec: Spec;
  disabledCTA: boolean;
}

const AddQualityInputButton = ({
  activeSpec,
  order,
  disabledCTA,
}: AddQualityInputButtonProps) => {
  const { t } = useTranslation();
  const { format } = useDate();
  const { reload, loading } = useAction('', {
    withParams: true,
    preserveState: true,
  });

  const isGenerating = order.status === OrderStatus.GENERATING;

  return (
    <div className="flex items-center gap-2">
      <AddQualityInputModal spec={activeSpec}>
        <Button
          // @ts-ignore
          disabled={disabledCTA || isGenerating}
          variant="white"
          size="sm"
        >
          {order.status === OrderStatus.GENERATING
            ? t('order_generating')
            : // TODO: Unclear what the flow is
            disabledCTA
              ? t('waiting_for_supplier_input')
              : t('add_manual_input')}
        </Button>
      </AddQualityInputModal>
      {order.status === OrderStatus.GENERATING && (
        <>
          <Icon className="cursor-pointer" onClick={() => reload({})}>
            <ArrowPathIcon className={`${loading && 'animate-spin'}`}/>
          </Icon>
          {order.updated_at && (
            <Text className="text-xs">
              {t('order_last_updated')}{' '}
              {format(new Date(order.updated_at), DateFormat.HumanDateTime)}
            </Text>
          )}
        </>
      )}
    </div>
  );
};

/**
 * The InspectionCard summarizes all the inspections for a given order.
 */
export const InspectionsCard = ({
  order,
  activeFieldId,
  orderProduce,
  orderFieldSummaries = [],
  filteredStepTypes = [],
}: InspectionsCardProps) => {
  const { t } = useTranslation();
  const { reload, resetCurrentPage } = useAction('', {
    withParams: true,
    preserveState: true,
    only: ['active'],
  });

  // TODO: This is a bit of a hack, but it works for now. (Should not be in a Shared component)
  const { specColorMap, findSpec, inspectionIssues } = useOrder();

  const activeSpec = findSpec(orderProduce?.active_spec?.id);

  const hasSpecs = !!specColorMap && Object.keys(specColorMap).length > 0;
  const allSpecs = Object.entries(specColorMap);

  /**
   * Filter the orderFieldSummaries by the filteredStepTypes.
   */
  const orderFieldSummariesFiltered: OrderFieldSummaryType[] =
    orderFieldSummaries
      .map((orderFieldSummary) => {
        if (filteredStepTypes.length === 0) {
          return orderFieldSummary;
        }

        let fieldSummaries = orderFieldSummary.fieldSummaries;

        fieldSummaries = fieldSummaries.filter((fieldSummary) => {
          if (!fieldSummary.inspection?.order_step?.type) {
            return false;
          }

          return filteredStepTypes.includes(
            fieldSummary.inspection.order_step.type as OrderStepType
          );
        });

        return {
          ...orderFieldSummary,
          fieldSummaries,
        };
      })
      .filter((orderFieldSummary) => {
        return orderFieldSummary.fieldSummaries.length > 0;
      }) || [];

  const openActiveSpec = (specId: string) => reload({ activeSpecId: specId });

  return (
    <div id="inspection">
      <Card>
        <CardHeader
          renderIcon={<InspectionsIcon className="w-6 h-6"/>}
          title={t('quality_inspection')}
          description={t('quality_inspection_description')}
        >
          {activeSpec && orderFieldSummariesFiltered.length > 0 && (
            <div className="flex justify-between mt-4">
              <AddQualityInputButton
                disabledCTA={false}
                order={order}
                activeSpec={activeSpec}
              />

              {hasSpecs && (
                <div>
                  <div>
                    <Helper>{t('spec')}</Helper>
                  </div>
                  <div className="flex space-x-2">
                    {allSpecs.map(([specId, specColor], index) => (
                      <>
                        <SpecLabel
                          onOpen={openActiveSpec}
                          key={index}
                          spec={findSpec(specId) as Spec}
                          theme={specColor}
                        />
                      </>
                    ))}
                  </div>
                </div>
              )}
            </div>
          )}
        </CardHeader>
        {orderFieldSummariesFiltered.length === 0 && (
          <CardBody className="!py-12">
            <div className="space-y-2">
              <div className="flex justify-center">
                <div className="text-center">
                  <CardTitle>{t('placeholders.no_fields')}</CardTitle>
                  <CardSubtitle>
                    {t('placeholders.no_fields_description')}
                  </CardSubtitle>
                </div>
              </div>
              <div className="flex justify-center">
                {activeSpec && (
                  <AddQualityInputButton
                    disabledCTA={false}
                    order={order}
                    activeSpec={activeSpec}
                  />
                )}
              </div>
            </div>
          </CardBody>
        )}
        {orderFieldSummariesFiltered.length > 0 && (
          <>
            <CardGutter align="start" variant="darker">
              <div className="flex w-full justify-between">
                <div className="w-full grid grid-cols-[1fr_2fr]">
                  <div>
                    <Helper>
                      Field
                    </Helper>
                  </div>

                  <div className="w-full flex justify-between items-center">
                    <Helper>
                      Organization
                    </Helper>

                    <Helper>
                      Value
                    </Helper>
                  </div>
                </div>

              </div>
            </CardGutter>

            {orderFieldSummariesFiltered.map((orderFieldSummary, index) => (
              <div
                key={index}
                data-testid={`fields.${index}`}
                role="row"
                aria-label={`FieldSummary for ${orderFieldSummary.field.name}`}
                id={`field::${orderFieldSummary.field.id}`}
              >
                <OrderFieldSummary
                  qcDisabled={false}
                  orderFieldSummary={orderFieldSummary}
                  isOpen={orderFieldSummary.field.id === activeFieldId}
                  activeSpec={activeSpec}
                />
              </div>
            ))}
          </>
        )}
        <CardFooter>
          <WithGenericFallback>
            {order.rag_breakdown && order.rag_breakdown.isActive && (
              <div className="flex gap-2 items-center">
                <Helper>
                  RAG Score
                </Helper>
                <div className="w-[100px]">
                  <Databars
                    data={[
                      {
                        value: order.rag_breakdown.red,
                        label: `${order.rag_breakdown.red} red`,
                        theme: 'red',
                      },
                      {
                        value: order.rag_breakdown.amber,
                        label: `${order.rag_breakdown.amber} amber`,
                        theme: 'orange',
                      },
                      {
                        value: order.rag_breakdown.green,
                        label: `${order.rag_breakdown.green} green`,
                        theme: 'emerald',
                      },
                    ]}
                  />
                </div>
              </div>
            )}
          </WithGenericFallback>
        </CardFooter>
      </Card>
    </div>
  );
};
