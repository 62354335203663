import { AnimatePresence, motion } from 'framer-motion';
import { ReactNode, useState } from 'react';

import { ChevronDownIcon, CollapsedIcon, SectionIcon } from '@/Icon';
import { SectionDescription, SectionTitle, Strong } from '@/Text';
import { Tooltipped } from '@/Tooltip';
import { cn } from '~/utils/cn';

interface SectionProps {
  title: string;
  description?: string | ReactNode;
  children: ReactNode;
  enableToggle?: boolean;
  defaultOpen?: boolean;
  showCollapsed?: boolean;
  renderPreview?: ReactNode;
  className?: string;
  nCollapsedItems?: number;
  icon?: any;
}

const Section = ({
  title,
  description,
  children,
  enableToggle = false,
  defaultOpen = true,
  showCollapsed = false,
  renderPreview,
  className,
  nCollapsedItems = 1,
  icon,
}: SectionProps) => {
  const [isOpen, setIsOpen] = useState(defaultOpen);

  const toggleSection = () => {
    if (enableToggle) {
      setIsOpen(!isOpen);
    }
  };

  return (
    <div className={cn('mb-4 relative', className)}>
      {icon && (
        <div className="absolute -left-8 top-0 hidden xl:block">
          <SectionIcon icon={icon}/>
        </div>
      )}
      <div
        className={cn(
          'flex items-start justify-between',
          enableToggle && 'cursor-pointer'
        )}
        onClick={toggleSection}
      >
        <div className="flex-grow">
          <div className="flex items-center justify-between">
            <SectionTitle>
              {title}
            </SectionTitle>
            {renderPreview && (
              <div className="ml-4">{renderPreview}</div>
            )}
          </div>
          {description && (
            <SectionDescription>
              {description}
            </SectionDescription>
          )}
        </div>
        {enableToggle && (
          <motion.div
            animate={{ rotate: isOpen ? 180 : 0 }}
            transition={{ duration: 0.3 }}
            className="ml-4 flex-shrink-0"
          >
            <ChevronDownIcon className="h-5 w-5 text-zinc-500 dark:text-zinc-400"/>
          </motion.div>
        )}
      </div>
      <AnimatePresence initial={false}>
        {(!enableToggle || isOpen) ? (
          <motion.div
            initial={{ height: 0, opacity: 0 }}
            animate={{ height: 'auto', opacity: 1 }}
            exit={{ height: 0, opacity: 0 }}
            transition={{ type: 'spring', bounce: 0.1, duration: 0.3 }}
          >
            <div className="mt-4">{children}</div>
          </motion.div>
        ) : showCollapsed && (
          <motion.div
            initial={{ height: 0, opacity: 0 }}
            animate={{ height: 'auto', opacity: 1 }}
            exit={{ height: 0, opacity: 0 }}
            transition={{ type: 'spring', bounce: 0.1, duration: 0.3 }}
          >
            <div
              className="flex border border-zinc-200 bg-zinc-50 dark:border-zinc-700 dark:bg-zinc-800 mt-4 rounded-lg"
            >
              <Tooltipped label="Undo collapse">
                <button
                  aria-label={`${title} toggle button`}
                  type="button"
                  onClick={toggleSection}
                  className="border-r bg-zinc-100 rounded-l-lg p-4 text-zinc-500 hover:bg-zinc-200 transition-colors"
                >
                  <CollapsedIcon/>
                </button>
              </Tooltipped>
              <div className="p-4">
                <Strong className="text-zinc-500">
                  +{nCollapsedItems} collapsed items
                </Strong>
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

type LayoutVariant = '1' | '2_3' | '1_3' | '3_1';

export const SectionLayout = ({
  variant = '1',
  children,
}: { variant: LayoutVariant, children: ReactNode }) => {
  return (
    <div className={cn('grid grid-cols-1 gap-4 [&>*]:min-w-0',
      variant === '1' && 'lg:grid-cols-1 gap-4',
      variant === '2_3' && 'lg:grid-cols-[2fr_3fr] gap-8 relative',
      variant === '1_3' && 'lg:grid-cols-[1fr_3fr] gap-14 relative',
      variant === '3_1' && 'lg:grid-cols-[3fr_1fr] gap-8 relative',
    )}>
      {children}
    </div>
  );
};

export const WithSectionIcon = ({
  icon,
  children,
}: { icon: any, children: ReactNode }) => {
  return (
    <div className="relative">
      <div className="absolute -left-8 top-0 hidden xl:block">
        <SectionIcon icon={icon}/>
      </div>

      {children}
    </div>
  );
};

export default Section;

export const Sections = ({ children }: { children: ReactNode }) => {
  return (
    <div className="space-y-12">
      {children}
    </div>
  );
};
