import { WithAwaitingFallback } from '@/Fallback';
import { OrderDataStream, OrderDataStreamType } from '~/types/types';

import { HarvestWeatherCard } from './Harvest/HarvestWeatherCard';
import { LogisticsTemperatureSummaryCard } from './Logistics/LogisticsTemperatureSummaryCard';
import { TransportRouteCard } from './Logistics/TransportRouteCard';

const mapDataStreamType = {
  [OrderDataStreamType.WEATHER]: HarvestWeatherCard,
  [OrderDataStreamType.TRANSPORT_TEMPERATURE]: LogisticsTemperatureSummaryCard,
  [OrderDataStreamType.LOCATION]: TransportRouteCard,
};

export const MapDataStream = ({ dataStream }: { dataStream: OrderDataStream<any> }) => {
  const Component = mapDataStreamType[dataStream.type as OrderDataStreamType];

  return (
    <WithAwaitingFallback>
      <Component dataStream={dataStream}/>
    </WithAwaitingFallback>
  );
};
