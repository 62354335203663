import * as z from 'zod';

import { Produce } from '~/types/types';
import ProduceConfig = App.Domain.Produce.ProduceConfig;

export const saveTempSchema = z.object({
  temp: z.object({
    low: z.number(),
    high: z.number(),
  }),
  minLengthInHours: z.number().int(),
});
export type FormSaveTempValues = z.infer<typeof saveTempSchema>;

export const Formatters = {
  modelToForm: (produce: Produce): FormSaveTempValues => {
    return {
      temp: {
        low: produce.weather_temp_config?.low || 0,
        high: produce.weather_temp_config?.high || 10,
      },
      minLengthInHours: Number(
        produce.weather_temp_config?.minLengthInHours || 0
      ),
    };
  },
  formToApi: (values: FormSaveTempValues): ProduceConfig => {
    return {
      weatherTempConfig: {
        low: values.temp.low,
        high: values.temp.high,
        minLengthInHours: values.minLengthInHours,
        granularity: "hour",
      },
      logisticsTempConfig: null,
    };
  },
};
