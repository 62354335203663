import { CalendarDaysIcon, ExclamationCircleIcon, HandThumbUpIcon } from '@heroicons/react/20/solid';
import { ArrowRightIcon } from '@heroicons/react/24/solid';
import { Link } from '@inertiajs/react';
import { Cross2Icon } from '@radix-ui/react-icons';
import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@/Button';
import { Card, CardBody, PromptCard } from '@/Card';
import { PlaceholderBox, WithGenericFallback } from '@/Fallback';
import StatisticsBanner from '@/Statistics/StatisticsBanner';
import {
  CardSubtitle,
  CardTitle,
  Helper,
  MutedText,
  SectionDescription,
  SectionTitle,
  Strong,
  Text,
} from '@/Text';
import { AccuracyIndicator } from '~/Components/Indicators/AccuracyIndicator';
import { OrderImage } from '~/Components/Orders/Representations/OrderImage';
import { useAction } from '~/hooks/useAction';
import { DateFormat, useDate } from '~/hooks/useDate';
import { useFormat } from '~/hooks/useFormat';
import { usePageProps } from '~/hooks/usePageProps';
import { useRoutes } from '~/hooks/useRoutes';
import { fuseTimeSeries } from '~/utils/charts';

import { FieldForecastComparer } from './components/FieldForecastComparer';
import { ForecastAccuracyChart } from './ForecastAccuracyChart';
import { ForecastAccuracyLayout } from './ForecastAccuracyLayout';
import { ForecastAccuracyPageProps, formatSummary } from './helpers';
import { OrderFieldAccuracyBox } from './OrderFieldAccuracyBox';

const ForecastAccuracyPage = () => {
  const { parseOrFormat } = useDate();
  const { formatPercentage } = useFormat();
  const { t } = useTranslation();
  const { reload, loading } = useAction('');
  const routes = useRoutes();
  const {
    data: { deepDive, timeSeries, summary, report },
    options,
    organization,
  } = usePageProps<ForecastAccuracyPageProps>();

  const handleCloseDeepDive = () => {
    reload({
      deepDive: {
        startDate: null,
        endDate: null,
      },
    });
  };

  const handleChartClick = (startDate: Date, endDate: Date) => {
    reload({

      deepDive: {
        startDate: parseOrFormat(startDate, DateFormat.DateOnly),
        endDate: parseOrFormat(endDate, DateFormat.DateOnly),
      },
    });
  };

  const formattedTimeSeries = fuseTimeSeries(timeSeries);

  const statistics = formatSummary(summary);

  const anomalies = formattedTimeSeries.filter((item) => item.anomaly);

  return (
    <div className="space-y-4">
      <div className="space-y-2">
        <SectionTitle>
          {t('overview')}
        </SectionTitle>
        <StatisticsBanner statistics={statistics}/>
      </div>

      <div className="space-y-4">
        <div className="space-y-2">
          <SectionTitle>
            {t('forecast_accuracy')}
          </SectionTitle>
          <SectionDescription>
            {t('forecast_accuracy_description')}
          </SectionDescription>
        </div>

        {anomalies.length > 0 && (
          <PromptCard variant="red" renderIcon={<ExclamationCircleIcon className="fill-red-500 w-5"/>}>
            <div>
              <CardTitle>
                {t('trend_detected')}
              </CardTitle>
              <CardSubtitle>
                {t('trend_detected_description', { count: anomalies.length })}
              </CardSubtitle>
            </div>
          </PromptCard>
        )}

        <WithGenericFallback>
          <Card>
            <CardBody>
              <ForecastAccuracyChart
                report={report}
                timeSeries={formattedTimeSeries}
                onClick={handleChartClick}
                sellers={options.sellers}
              />
            </CardBody>

            {(!loading && (deepDive.length > 0)) && (
              <div>
                <div className={'border-t border-dashed border-gray-300 h-1'}/>

                <CardBody>
                  <div className="mb-4">
                    <CardTitle>
                    </CardTitle>
                    <CardSubtitle>
                      {t('reports.forecast_accuracy_analysis_description')}
                    </CardSubtitle>

                    <div className="mt-2">
                      <Button onClick={() => handleCloseDeepDive()} variant="outline" size="xs">
                        <Cross2Icon className="w-3 h-3 mr-1"/>
                        {t('close_analysis')}
                      </Button>
                    </div>
                  </div>

                  <div className="space-y-4">
                    {deepDive.map(sellerDeepDive => (
                      <div key={sellerDeepDive.id}>
                        <Card>
                          <CardBody>
                            <div className="grid grid-cols-[1fr_3fr]">
                              <div className="bg-gray-50 border-r rounded-l -my-4 -ml-8 p-4 flex flex-col gap-4">
                                {/* Top */}
                                <div className="space-y-1">
                                  <div>
                                    <Strong>{sellerDeepDive.title}</Strong>
                                    <div className="mt-1">
                                      <MutedText className="flex gap-2">
                                        <div className="w-4 h-4">
                                          <AccuracyIndicator score={sellerDeepDive.accuracy}/>
                                        </div>
                                        {formatPercentage(sellerDeepDive.accuracy)}
                                      </MutedText>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div>
                                <div
                                  className="px-4 py-2 -mt-4 rounded-tr -mr-8 hidden sm:grid gap-3 sm:grid-cols-[1fr_100px] md:grid-cols-[1fr_100px] bg-gray-50">
                                  <div>
                                    <Helper>{t('order_with_inaccuracies')}</Helper>
                                  </div>
                                </div>

                                {!sellerDeepDive.orderAccuracies.length && (
                                  <PlaceholderBox title="All good"
                                    description="No orders with significant inaccuracies" isFlat
                                    icon={<HandThumbUpIcon className="w-12 h-12 fill-gray-300"/>}
                                  />
                                )}
                                <div className="px-4 py-2">
                                  <div className="space-y-12 w-full">
                                    {sellerDeepDive.orderAccuracies.map((orderAccuracy, index) => (
                                      <div
                                        className=""
                                        key={index}>
                                        {/* Left side: Show the reason and all related orders */}
                                        <div>
                                          <div className="flex gap-2">
                                            <div className="w-full">
                                              <div className="w-full flex items-center justify-between">
                                                <div className="flex justify-between gap-1 items-center py-2">
                                                  <div className="inline-block">
                                                    <OrderImage order={orderAccuracy.order}/>
                                                  </div>
                                                  <div>
                                                    <Text
                                                      aria-label={`Order-Accuracy for PO: ${orderAccuracy.order.public_ref}`}>
                                                      {orderAccuracy.order.public_ref}
                                                    </Text>
                                                  </div>

                                                  <div>

                                                  </div>
                                                </div>

                                                <div>
                                                  <Link className="text-gray-500 flex gap-2 font-medium text-xs"
                                                    href={routes.order(orderAccuracy.order_id)}>
                                                    <ArrowRightIcon className="w-4 h-4"/>
                                                    View order
                                                  </Link>
                                                </div>
                                              </div>
                                              <hr/>
                                              <div className="mt-4">
                                                {!orderAccuracy.problems.length && (
                                                  <PlaceholderBox title="Everythin looks good"
                                                    description="This order does not contain any fields with significant inaccuracies"/>
                                                )}
                                                <div className="grid grid-cols-2 gap-2">
                                                  {orderAccuracy.problems.map((problem) => (
                                                    <OrderFieldAccuracyBox
                                                      key={problem.id}
                                                      problem={problem}
                                                      sellerInfo={sellerDeepDive}
                                                      orderAccuracyInfo={orderAccuracy}
                                                      buyer={organization}/>
                                                  ))}
                                                </div>
                                              </div>
                                            </div>
                                          </div>

                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                      </div>
                    ))}
                  </div>
                </CardBody>
              </div>
            )}

            {(loading || !(deepDive.length)) && (
              <div>
                <div className="border-t border-dashed border-gray-300 h-1"/>

                <PlaceholderBox
                  isDisabled
                  title="Select a date"
                  description="Click on the chart to explore detailed data for a specific date."
                  icon={<CalendarDaysIcon className="w-12 h-12 fill-gray-400"/>}
                  isFlat
                  loading={loading}
                />
              </div>
            )}
          </Card>
        </WithGenericFallback>

        <div className="space-y-4">
          <div>
            <CardTitle>
              Seller performances
            </CardTitle>
            <CardSubtitle>
              Understand the accuracy of your supplier forecast compared to the actual inspection results.
            </CardSubtitle>
          </div>

          <div>
            <FieldForecastComparer/>
          </div>
        </div>
      </div>
    </div>
  );
};

ForecastAccuracyPage.layout = (page: ReactNode) => <ForecastAccuracyLayout>{page}</ForecastAccuracyLayout>;

export default ForecastAccuracyPage;
