import { AnalyticsIcon } from '@/Icon';
import { ActionAppType, ActionContext, ActionPost, BaseAction } from '~/Actions/BaseAction';
import { BuyerRoutes } from '~/hooks/useRoutes';
import { Order } from '~/types/types';

export class AnalyzeOrder extends BaseAction {
  public static icon = AnalyticsIcon;
  public static analyticsName = 'Analyze order';
  public static type: ActionAppType = 'buyer';
  public static toast = {
    titleKey: 'analyzed_order',
  };

  constructor(public context: ActionContext<Order>, public post: ActionPost) {
    super(context, post);
  }

  public nameKey() {
    return 'analyze_order';
  }

  public descriptionKey() {
    return 'analyze_order_description';
  }

  public disabled() {
    return !this.context.user.superadmin;
  }

  public async execute(data: any) {
    const routes = this.context.routes as BuyerRoutes;
    const route = routes.analyzeOrder;
    //
    await this.post(route(this.context.target.id), { force: true, ...data });
  }
}
