import { BellIcon, NewspaperIcon } from 'lucide-react';
import { useTranslation } from 'react-i18next';

import { Gutter } from '@/Gutter';
import { IconBadge, IssueIcon, OrderIcon } from '@/Icon';
import { User } from '~/types/types';

interface UserNotificationBadgeProps {
  user: User;
}

export const UserNotificationBadge = ({ user }: UserNotificationBadgeProps) => {
  const { t } = useTranslation();

  return (
    <div>
      <Gutter gap="sm" width="sm" shadow>
        <IconBadge label={t('enable_email_notifications')}
          disabled={!user.active_mail_recipient || !user.active || !user.active_mail_recipient}
          icon={BellIcon}/>
        <IconBadge label={t('enable_hourly_pos_notifications')}
          disabled={!user.active_hourly_pos_recipient || !user.active || !user.active_mail_recipient}
          icon={OrderIcon}/>
        <IconBadge label={t('enable_issues_notifications_title')}
          disabled={!user.active_issues_mail_recipient || !user.active || !user.active_mail_recipient}
          icon={IssueIcon}/>
        <IconBadge label={t('enable_daily_digest_title')}
          disabled={!user.active_daily_digest_mail_recipient || !user.active || !user.active_mail_recipient}
          icon={NewspaperIcon}/>
      </Gutter>
    </div>
  );
};
