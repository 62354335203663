import {
  OrderFieldSummary as OrderFieldSummaryType,
  Spec,
} from '~/types/types';

import { GenericFieldSummary } from './GenericFieldSummary';

interface OrderFieldSummaryProps {
  orderFieldSummary: OrderFieldSummaryType;
  isOpen: boolean;
  qcDisabled?: boolean;
  readOnly?: boolean;
  activeSpec: Spec | null;
}

export const OrderFieldSummary = ({
  orderFieldSummary,
  activeSpec,
  isOpen,
}: OrderFieldSummaryProps) => {
  return (
    <GenericFieldSummary
      isOpen={isOpen}
      orderFieldSummary={orderFieldSummary}
      activeSpec={activeSpec}
    />
  );
};
