import { LightbulbIcon, ThermometerIcon } from 'lucide-react';
import { useMemo } from 'react';
import {
  ComposedChart,
  Line, ReferenceLine,
  XAxis,
  YAxis,
} from 'recharts';

import { ChartConfig, ChartContainer, ChartTooltip, ChartTooltipContent } from '@/Chart';
import { DateFormat, useDate } from '~/hooks/useDate';
import { OrderDataStream } from '~/types/types';

interface TransportChartProps {
  dataStream: OrderDataStream<any>;
  data: any;
  type: 'temperature' | 'light';
  lowThresholdY?: number | null;
  upperThresholdY?: number | null;
}

interface AnomalyDotProps {
  cx: number;
  cy: number;
  stroke: string;
  payload: any;
  value: number;
  thresholdY?: number;
  lowerThresholdY?: number;
}

const CustomizedDot = ({ cx, cy, payload }: AnomalyDotProps) => {
  if (payload.status === 'open') {
    // Check that the point is related to an open issue...
    return (
      <svg x={cx - 10} y={cy - 10} width={20} height={30} fill="white"
        viewBox="0 0 1024 1024"
        className="text-rose-500 fill-current drop-shadow-md"
      >
        <circle cx="512" cy="256" r="256" className="fill-yellow-500 stroke-white stroke-2"/>
      </svg>
    );
  }

  if (payload.status === 'accepted') {
    // Check that the point is related to an open issue...
    return (
      <svg x={cx - 10} y={cy - 10} width={20} height={30} fill="white"
        viewBox="0 0 1024 1024"
        className="text-rose-500 fill-current drop-shadow-md"
      >
        {/* Red dot */}
        <circle cx="512" cy="256" r="256" className="fill-orange-500 stroke-white stroke-2"/>
      </svg>
    );
  }

  if (payload.status === 'escalated') {
    // Check that the point is related to an open issue...
    return (
      <svg x={cx - 10} y={cy - 10} width={20} height={30} fill="white"
        viewBox="0 0 1024 1024"
        className="text-rose-500 fill-current drop-shadow-md"
      >
        {/* Red dot */}
        <circle cx="512" cy="256" r="256" className="fill-rose-500 stroke-white stroke-2"/>
      </svg>
    );
  }

  if (payload.status === 'read') {
    // Check that the point is related to an open issue...
    return (
      <svg x={cx - 10} y={cy - 10} width={20} height={30} fill="white"
        viewBox="0 0 1024 1024"
        className="text-rose-500 fill-current drop-shadow-md"
      >
        {/* Red dot */}
        <circle cx="512" cy="256" r="256" className="fill-emerald-500 stroke-white stroke-2"/>
      </svg>
    );
  }

  return null;
};

export const TransportChart = ({ dataStream, data, lowThresholdY, upperThresholdY, type }: TransportChartProps) => {
  const { parseOrFormat } = useDate();

  const ticks = useMemo(() => {
    const ticks = [0];

    if (lowThresholdY !== undefined && lowThresholdY !== null) {
      ticks.push(lowThresholdY);
    }

    if (upperThresholdY !== undefined && upperThresholdY !== null) {
      ticks.push(upperThresholdY);
    }

    const max = Math.max(...data.map((d: any) => d.value));
    if (max > 1) {
      ticks.push(5);
    }

    return ticks;
  }, [data, lowThresholdY, upperThresholdY]);

  const openIssues = dataStream.issues ?? [];

  const tempData = data.map((dataPoint: any) => {
    const issue = openIssues.find((issue: any) => {
      const issueStartDate = new Date(issue.issueable.data.startDate).getTime();
      const issueEndDate = issue.issueable.data.endDate ? new Date(issue.issueable.data.endDate).getTime() : null;
      const eventDate = new Date(dataPoint.date).getTime();

      if (issueEndDate) {
        return eventDate >= issueStartDate && eventDate <= issueEndDate;
      } else {
        return eventDate === issueStartDate;
      }
    });

    return {
      ...dataPoint,
      anomalyValue: dataPoint.value,
      status: issue ? issue.status : '',
    };
  });

  const chartConfig = {
    value: {
      icon: type === 'temperature' ? ThermometerIcon : LightbulbIcon,
      label: type === 'temperature' ? 'Temperature' : 'Light',
      color: 'var(red-500)',
    },
    anomalyValue: {
      hide: true,
    },
  } satisfies ChartConfig;

  return (
    <ChartContainer config={chartConfig} className="aspect-auto h-[300px] w-full">
      <ComposedChart
        className="text-sm"
        data={tempData}
      >
        <XAxis
          tickFormatter={(val) => parseOrFormat(val, DateFormat.HumanDateTimeNoYear)}
          className="fill-gray-700"
          stroke="#D1D5DB"
          dataKey="date"
          tick={{ fontFamily: 'Inter', fontSize: 13, fill: 'currentColor' }}
          tickMargin={14}
        />
        <YAxis
          className="fill-gray-700"
          stroke="#D1D5DB"
          tick={{ fontFamily: 'Inter', fontSize: 13, fill: 'currentColor' }}

          tickFormatter={(val) => `${val}${type === 'temperature' ? '°C' : '%'}`}
          domain={[Math.min(...ticks, 100) - 3, Math.max(...ticks, -100) + 3]}
          ticks={ticks}
        />

        <ChartTooltip
          cursor={false}
          content={
            <ChartTooltipContent
              labelFormatter={(val) => parseOrFormat(val, DateFormat.HumanDateTime)}
              indicator="line"
            />
          }
        />

        {lowThresholdY !== undefined && lowThresholdY !== null && (
          <ReferenceLine
            type="monotone"
            y={lowThresholdY}
            label="Min"
            stroke='red'
            strokeWidth={2}
            strokeDasharray="3 3"
          />
        )}

        {upperThresholdY !== undefined && upperThresholdY !== null && (
          <ReferenceLine
            type="monotone"
            y={upperThresholdY}
            label="Max"
            stroke='orange'
            strokeWidth={2}
            strokeDasharray="3 3"
          />
        )}

        <Line
          type="monotone"
          dataKey="value"
          className="stroke-2 stroke-blue-200"
          strokeWidth={1}
        />

        {/* Scatter-plot with red dots */}
        {upperThresholdY !== null && upperThresholdY !== undefined && (
          <Line
            type="monotone"
            dataKey="anomalyValue"
            strokeWidth={2}
            dot={(props) => <CustomizedDot {...props} />}
          />
        )}
      </ComposedChart>
    </ChartContainer>
  );
};
