import { MappingIcon } from '@/Icon';
import { ActionAppType, ActionContext, ActionPost, BaseAction } from '~/Actions/BaseAction';
import { BuyerRoutes } from '~/hooks/useRoutes';

interface MapResourceInput {
  fromId: string;
  toable_id: string;
  type: string;
}

export class CreateResourceMap extends BaseAction {
  public static icon = MappingIcon;
  public static analyticsName = 'Create map resource';
  public static type: ActionAppType = 'buyer';
  public static toast = {
    titleKey: 'created_map_resource',
  };

  constructor(public context: ActionContext<any>, public post: ActionPost) {
    super(context, post);
  }

  public nameKey() {
    return 'create_map_resource';
  }

  public disabled() {
    return !this.context.user.superadmin;
  }

  public async execute(data: MapResourceInput) {
    const routes = this.context.routes as BuyerRoutes;
    const route = routes.createMapResource;

    await this.post(route(data.type, data.fromId, data.toable_id), {});
  }
}
