import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { SimpleIcon } from '@/Icon';
import { cn } from '~/utils/cn';

type StatusTheme = 'gray' | 'red' | 'yellow' | 'green' | 'blue' | 'orange';

interface StatusConfig<T = any> {
  label?: string | null;
  textKey?: string;
  descriptionKey?: string;
  theme: StatusTheme;
  icon?: any;
  metaData?: T;
}

/**
 * StatusMap is a map of various states, how to represent them and what to display.
 *
 * - Used in the StatusBadge component to display a status badge.
 * - Used in other downstream tasks occasionally.
 */
export type StatusMap<K extends string = string, T = any> = Record<K, StatusConfig<T>>;

interface StatusBadgeProps {
  config?: StatusConfig | null;
  children?: ReactNode;
}

/**
 * StatusBadge is a component that displays a Label-like component, with an icon, and a label. It is mostly used in components
 * like ListDetails. Think of it as how Notion would render a status badge in their Databases.
 */
export const StatusBadge = ({ config, children }: StatusBadgeProps) => {
  const { t } = useTranslation();
  if (!config) {
    return null;
  }

  return (
    <span className={cn(
      'inline-flex items-center px-3 py-0.5 rounded-xl text-xs font-medium border',
      {
        'bg-gray-200 text-gray-700 border-gray-300': config.theme === 'gray',
        'bg-rose-50 border-rose-300 text-red-700': config.theme === 'red',
        'bg-yellow-100 text-yellow-700': config.theme === 'yellow',
        'bg-emerald-50 border-emerald-300 text-green-700': config.theme === 'green',
        'text-blue-600 border-blue-100 bg-blue-50 ring-blue-500/10': config.theme === 'blue',
        'bg-orange-100 text-orange-700': config.theme === 'orange',
        'bg-blue-100 text-blue-700': config.theme === 'blue',
      }
    )}>
      {config.icon && (
        <SimpleIcon icon={config.icon} className="w-4 h-4 mr-2 !text-inherit"/>
      )}

      {children ? (
        <>
          {children}
        </>
      ) : (
        <>
          {config.textKey ? t(config.textKey) : config.label}
        </>
      )}
    </span>
  );
};

export const getStatusFromMap = (status: string | null | undefined, map: StatusMap): StatusConfig | null => {
  return status && status in map ? map[status] : null;
};
